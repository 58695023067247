<template>
  <v-container style="height: 100vh; max-height: 100vh;" class="pa-0 private-background" fluid
               id="sexperimenter">
    <v-card style="top:5vh;" class="ml-2 ml-sm-5" flat color="transparent">
      <v-card-title>
        <h1 class="text-uppercase">Sexpérimenter sans sexposer</h1>
      </v-card-title>
      <v-card-text>
        <div class="column">
          <p>Pour nous, le couple n’est pas une somme d’individualités, mais plutôt une entité, une expérience sociale
            de
            la vraie vie, qui mérite d’être nourrie chaque jour, et dont chaque partie peut faire en sorte de la faire
            grandir.</p>
          <p>Ce sont ces millions de couples, tous différents, qui ont inspiré Private-Games, une application dédiée à
            la
            « sexpérimentation » partagée en duo.</p>
          <p>Via différentes fonctionnalités, vous allez être en mesure de découvrir et d’explorer votre moitié,
            toujours
            avec légèreté, sans jamais vous y forcer.
          </p>
          <p>Des défis qui correspondent à chacun, pour pouvoir sexpérimenter à son rythme…</p>
          <p>Parce que nous pensons que chaque couple peut en apprendre des autres, nous avons à cœur de vous permettre
            de
            créer et de partager vos propres contenus, toujours dans le respect de votre vie privée.</p>
          <p>Nous sommes convaincus que tous les couples sont beaux, c’est pourquoi Private-Games n’a pas pour vocation
            à
            vous dicter ce que vous devez faire pour - soi-disant - « améliorer » votre couple. Notre application est
            plutôt
            un outil qui vous offre la possibilité de vous découvrir sous toutes vos formes.</p>
          <p>Alors bienvenue sur Private Games, l’app qui permet de sexpérimenter, sans sexposer ❤</p>
          <p>L’équipe Private-Games
          </p>
        </div>
        <sign-up class="text-right"/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SignUp from "./SignUp";

export default {
  name: "SafeSexperimentation",
  components: {SignUp}
}
</script>

<style scoped lang="scss">
h1 {
  font-family: "Nunito";
  font-weight: bolder;
  @media (max-width: 758px) {
    font-size: 1.5em;
  }
}

.column {
  column-width: 45vw;
  column-gap: 5vw;
}

.private-background {
  background-image: url("~@/assets/background/background-pg-xl-white.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f5f1e7;
  height: calc(100vh - 128px);
  width: 100vw;
  min-width: 100vw;
  max-height: 100vh;
  overflow-y: auto;
  @media (max-width: 768px) {
    background-image: url("~@/assets/background/background-pg-portrait-xl-white.jpg");
  }
}
</style>