<template>
  <div>
    <safe-sexperimentation id="home"/>
  </div>
</template>

<script>
import SafeSexperimentation from "../components/our-difference/SafeSexperimentation";
import NavigationMixin from "../mixins/NavigationMixin";

export default {
  name: "OurDifference",
  components: {SafeSexperimentation},
  mixins: [NavigationMixin],
  mounted() {
    this.setAvaibleNavs(['#home']);
  }
}
</script>

<style scoped>

</style>