<template>
<div>
  <f-a-q-main id="home"/>
</div>
</template>

<script>
import FAQMain from "../components/faq/FAQMain";
export default {
  name: "FAQ",
  components: {FAQMain}
}
</script>

<style scoped>

</style>