<template>
  <v-container class="pa-0 private-background" fluid id="home">
    <img src="~@/assets/background/background-pg-xl-white.png" class="bg">
    <v-alert
        color="#cc9933"
        dark
        border="right"
        class="m-auto"
    >
      <div class="text-center">Foire aux questions : vous avez des questions ? Voici nos réponses !</div>
      <template v-slot:prepend>
        <jumping-pimentos x-x-l/>
      </template>
    </v-alert>
    <v-row style="" class="ml-5 mr-5 mb-5" no-gutters v-for="(faqElement,j) in faqElements" :key="j">
      <v-col cols="11" offset="1"><h1>{{ faqElement.categoryName }}</h1></v-col>
      <v-col cols="12" sm="10" offset-sm="1">
        <v-expansion-panels inset focusable>
          <v-expansion-panel
              v-for="(element,index) in faqElement.elements"
              :key="index"
          >
            <v-expansion-panel-header ripple color="rgba(204, 153, 153, 0.40)" v-on:click="onClickHeader">
              <b>{{ element.question }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="block" v-html="element.answer"/>
            </v-expansion-panel-content>

          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import JumpingPimentos from "../JumpingPimentos";

export default {
  name: "FAQMain",
  components: {JumpingPimentos},
  data() {
    return {
      faqElements: [{
        categoryName: "Créer son compte",
        elements: [{
          question: "Comment créer un compte ?",
          answer: "<p>Créer un compte de jeu Private-Games est rapide, et sécurisé.</p>" +
              "<p>Lors de l’inscription, nous vous demandons simplement votre pseudo, votre adresse mail et un mot de passe (après avoir confirmé votre majorité bien sûr, puisque nous sommes sur un espace de jeux érotiques pour couple(s)...).</p>"
        },
          {
            question: "Encore une appli de jeux érotiques pleine de pub ?",
            answer: "<p>Vous en avez marre des appli pour adulte pleines de pubs ? Nous aussi !!!</p>" +
                "<p>Pas de publicités envahissantes ! </p>" +
                "<p>Pas de fenêtres qui s’ouvrent à chaque clic !</p>" +
                "<p>Vous ne serez pas dérangés dans vos jeux et vos folles soirées torrides…</p>"
          },
          {
            question: "L'inscription est-elle gratuite ? Et l’utilisation ?",
            answer: "<p>Une appli entièrement gratuite, pensée pour les utilisateurs !</p>" +
                "<p>Utilisez vos points accumulés, vos pimentos, au cours de vos parties .</p>" +
                "<p>Dépensez-les pour débloquer de nouvelles fonctionnalités...</p>"
          },
          {
            question: "Les pimentos ? Qu’est-ce que c’est ?",
            answer: "<p>C’est la monnaie de Private-Games, les points que vous accumulez au cours de vos parties.</p>" +
                "<p>Ces points, les pimentos, vous permettent de débloquer de nouvelles fonctionnalités pour encore plus de possibilités coquines…</p>"
          },
          {
            question: "Je n’ai pas assez de pimentos, mais je veux une nouvelle fonctionnalité, comment faire ?",
            answer: "<p>Pressés de jouer, petits coquins ? Rassurez-vous, une boutique est également présente ...</p>" +
                "<p>L’occasion d’aider votre espace de jeux coquins Private-Games à continuer à fonctionner tel qu’il est…</p>"
          },
          {
            question: "Les jetons ? Qu’est-ce que c’est ?",
            answer: "<p>Vous pouvez lancer jusqu’à 3 parties par jour. Les jetons donnent accès à des parties supplémentaires.</p>" +
                "<p>Vous avez la possibilité d’utiliser vos propres points, les pimentos, pour acheter vos jetons. Pas assez de pimentos ? Faites un tour sur la boutique, de nombreux packs sont disponibles, à différents tarifs.</p>"
          },
          {
            question: "Comment s’inscrire en couple? Faut-il 2 adresses mail différentes ?",
            answer: "<p>Chaque joueur à besoin d’une adresse mail pour créer son propre compte. </p>" +
                "<p>Vous pourrez retrouver et gérer votre couple depuis votre profil personnel.</p>"
          },
          {
            question: "Puis-je jouer seul ?",
            answer: "<p> Vous êtes totalement libre de vous inscrire seul(e) et de venir découvrir votre nouvel espace coquin.</p>" +
                "<p>Vous aurez toujours la possibilité, quand vous le souhaiterez, d’inviter votre partenaire à vous rejoindre pour une partie torride, via votre profil personnel, en inscrivant son adresse mail, qui recevra ensuite une invitation...</p>"
          },
          {
            question: "Puis-je jouer à plusieurs ou avec des amis ?",
            answer: "<p>Une version prochaine est en cours de réflexion, qui vous permettra de jouer à distance avec d’autres utilisateurs, des amis, d’autres couples...</p>" +
                "<p>Mais shhhhhh… c’est un secret… On vous en reparlera bientôt… ;)</p>" +
                "<p>Et si vraiment, vous voulez jouer avec des amis, nous ne voudrions pas vous empêcher de parler de Private-Games autour de vous...</p>"
          },
          {
            question: "Comment supprimer mon compte et mes données personnelles ?",
            answer: "<p>Nous avons voulu des choses très accessibles facilement. Pas de boutons cachés ou de liens impossibles à trouver. Il suffit d’aller dans votre compte personnel, en bas de page. La totalité de vos données personnelles sera supprimée, mais attention, l’action est irréversible."
          }
        ]
      },
        {
          categoryName: "Les jeux coquins",
          elements: [{
            question: "À quel type de jeu s’attendre ?",
            answer: "<p>De nombreux défis et scénarios + ou - coquins, en fonction de vos envies. Des jeux qui correspondent à chacun, pour pouvoir sexpérimenter en duo et à son rythme…"
          },
            {
              question: "Combien de temps dure une partie ?",
              answer: "<p>Plusieurs durées sont possibles, à vous de choisir… </p>" +
                  "Un défi rapide qui va droit au but ou plutôt sur la journée pour faire durer le plaisir ? Sur plusieurs jours… ? Ok, mais c’est de la gourmandise !</p>" +
                  "<p>À vous de choisir...</p>"
            },
            {
              question: "Nous sommes en couple, mais à distance, peut-on jouer quand même ?",
              answer: "<p>En attendant de vous retrouver pour vous sauter dessus fougueusement, Private-Games peut vous aider à ajouter du piment et jouer ensemble malgré la distance. </p>" +
                  "<p>Plusieurs catégories sont disponibles, à distance, mais aussi à domicile ou à l’extérieur pour le moment où vous vous retrouverez…</p>"
            },
            {
              question: "Si les jeux proposés ne me plaisent pas vraiment ?",
              answer: "<p>Vous ne trouvez pas votre bonheur parmi tous nos jeux proposés ? </p>" +
                  "<p>Pas de problème… Vous pouvez également écrire vos propres défis et vous les envoyer, et/ou les partager avec la communauté… Préparez votre plus belle plume…</p>" +
                  "<p>Votre imagination est la seule limite !</p>"
            },
            {
              question: "La communauté, qu’est-ce que c’est ?",
              answer: "<p>Private-Games est un espace communautaire de jeux érotiques dans lequel vous avez la possibilité d’écrire vos propres jeux et de les partager avec la communauté, si vous le souhaitez.</p>" +
                  "<p>Votre jeu plaît à d’autres membres ?  Ils auront la possibilité de vous l'acheter avec leurs propres pimentos et, ainsi, de vous les attribuer.</p>" +
                  "<p>C'est l’esprit communautaire Private-Games.</p>"
            },
            {
              question: "Est-on libre de participer ou non avec la communauté ?",
              answer: "<p>La communauté est un espace libre, de partages et d’échanges de jeux personnels. Vous n’avez, bien sûr, pas l’obligation d’y participer.</p>" +
                  "<p>Lorsque vous créez un jeu, vous avez le choix de l’envoyer uniquement à votre partenaire, auquel cas vous serez les seuls à le voir… ce qui restera donc votre petit secret coquin...</p>" +
                  "<p>Vous avez également la possibilité de partager et de faire découvrir vos écrits coquins à la communauté et, ainsi, de gagner des pimentos supplémentaires.</p>"
            },
            {
              question: "Mon jeu partagé sera-t-il publié immédiatement ?",
              answer: "<p>Par respect pour tous nos utilisateurs, une équipe de modération vérifie d’abord le contenu avant de le publier. C’est pourquoi un jeu partagé sur la communauté n’est jamais publié systématiquement ni immédiatement."
            }
          ]
        }]
    }
  },
  methods: {
    onClickHeader(event) {
      console.log(event)
      this.$nextTick(() => {
        this.$vuetify.goTo(event.pageY);
      })

    }
  }
}
</script>

<style scoped lang="scss">
img.bg {
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
  background: url("~@/assets/background/background-pg-xl-white.png") no-repeat center center fixed;
  @media (max-width: 768px) {
    background-image: url("~@/assets/background/background-pg-portrait-xl-white.jpg") no-repeat center center fixed;
  }
}

@media screen and (max-width: 1024px) { /* Specific to this particular image */
  img.bg {
    left: 50%;
    margin-left: -512px; /* 50% */
  }
}

.block {
  display: block !important;
  margin-top: 5px;
}

h1 {
  font-family: "Nunito";
  font-weight: bolder;
}

.private-background {
  background-color: #f9e6df;
  //width: 100vw;
  //min-width: 100vw;
  //min-height: 100vh;
}
</style>