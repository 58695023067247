<template>
  <div id="wrapper">
    <svg id="signature" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         width="300px" height="150px" viewBox="0 0 300 150" enable-background="new 0 0 300 150" xml:space="preserve">
<path class="line1" d="M61.896,64.101c-0.552,0.772-1.067,1.453-1.544,2.041c-0.478,0.588-0.772,0.882-0.883,0.882
c-0.257,0.552-0.626,1.012-1.103,1.379c-0.257,0.22-0.46,0.423-0.607,0.606c-0.074,0.075-0.129,0.157-0.166,0.249
c-0.037,0.092-0.055,0.193-0.055,0.303c0,0.147-0.027,0.285-0.083,0.414c-0.055,0.13-0.175,0.193-0.359,0.193
c0.184-0.221,0.276-0.367,0.276-0.441h-0.055c-0.074,0-0.174,0.047-0.303,0.138c-0.129,0.092-0.285,0.23-0.469,0.414
c-0.331,0.257-0.644,0.496-0.938,0.717c-0.221,0.147-0.405,0.221-0.552,0.221h-0.11c0.882-0.882,1.324-1.36,1.324-1.434
c-0.037,0-0.423,0.331-1.159,0.993c-0.847,0.699-1.839,1.517-2.979,2.455c-1.14,0.938-2.207,1.737-3.2,2.399
c-0.957,0.626-1.636,0.938-2.041,0.938h-0.11l-0.166,0.056v0.055h0.055c0.074,0.037,0.166,0.074,0.276,0.11
c0.11,0,0.211,0.009,0.304,0.027c0.091,0.02,0.156,0.047,0.193,0.083c0.036,0,0.055,0.02,0.055,0.055
c0,0.037-0.027,0.083-0.083,0.139c-0.055,0.055-0.193,0.137-0.414,0.248c-0.185,0.037-0.323,0.074-0.414,0.109
c-0.092,0.037-0.157,0.056-0.193,0.056h-0.11v-0.056l0.11-0.109v-0.056H46.34c-0.037,0-0.055-0.019-0.055-0.056
c0-0.035,0.036-0.09,0.11-0.165c0.073-0.036,0.118-0.073,0.138-0.11c0.019-0.035,0.008-0.055-0.027-0.055c-0.037,0-0.075,0-0.11,0
c-0.037,0-0.083,0.009-0.138,0.027c-0.055,0.02-0.083,0.047-0.083,0.082c-0.075,0-0.193,0.028-0.359,0.084
c-0.166,0.055-0.34,0.119-0.524,0.192c-0.185,0.075-0.313,0.13-0.386,0.165c-0.037,0.037-0.055,0.075-0.055,0.111l0.055,0.055
c0.074-0.035,0.184-0.073,0.331-0.11c0.146-0.036,0.284-0.073,0.414-0.11c0.129-0.035,0.229-0.055,0.304-0.055
c0.073,0.037,0.11,0.074,0.11,0.109l-0.055,0.056c-0.037,0.075-0.166,0.185-0.386,0.331c-0.147,0.074-0.34,0.13-0.579,0.166
c-0.24,0.036-0.506,0.055-0.8,0.055c-0.295,0.037-0.561,0.064-0.8,0.082c-0.24,0.02-0.395,0.084-0.469,0.193
c0.146,0.147,0.331,0.221,0.552,0.221c0.11,0,0.276-0.027,0.497-0.082c0.221-0.056,0.551-0.156,0.993-0.304
c0.625-0.221,1.194-0.331,1.71-0.331h0.221c0.036,0.037,0.055,0.074,0.055,0.11s-0.027,0.083-0.083,0.138s-0.157,0.102-0.304,0.139
c-0.147,0.036-0.313,0.074-0.496,0.109c-0.147,0.037-0.268,0.074-0.359,0.111c-0.092,0.036-0.157,0.055-0.193,0.055
c0,0.037-0.02,0.055-0.055,0.055c-0.037,0-0.092,0-0.166,0c-1.03,0.258-2.005,0.387-2.924,0.387c-3.346,0-5.958-1.655-7.833-4.965
l-0.11,0.11c-0.074,0.11-0.185,0.257-0.331,0.441c-0.147,0.185-0.276,0.313-0.386,0.386l-0.11,0.055
c-0.075,0-0.13-0.055-0.166-0.165c0.073-0.074,0.11-0.129,0.11-0.166c0-0.036-0.028-0.074-0.083-0.11
c-0.055-0.036-0.157-0.055-0.303-0.055c-0.185,0-0.313,0.037-0.386,0.11c-0.075,0.037-0.11,0.11-0.11,0.221v0.055
c-0.147,0-0.285,0.102-0.414,0.303c-0.13,0.202-0.23,0.433-0.304,0.689v0.22c0,0.147,0.019,0.258,0.055,0.332
c0.221-0.111,0.422-0.295,0.607-0.552c0.11-0.146,0.221-0.276,0.331-0.386s0.221-0.184,0.331-0.221
c-0.368,0.406-0.717,0.883-1.048,1.435c-0.516,0.883-1.123,1.6-1.82,2.151l-0.276,0.276v-0.111c0-0.035,0.019-0.082,0.055-0.138
c0.036-0.056,0.074-0.11,0.11-0.165c0.036-0.056,0.074-0.083,0.11-0.083v-0.056c0-0.073,0.074-0.201,0.221-0.386
c0.146-0.185,0.35-0.405,0.607-0.662c0.146-0.184,0.284-0.358,0.414-0.524c0.129-0.165,0.211-0.283,0.248-0.357
c0.036-0.037,0.055-0.074,0.055-0.111c0-0.035-0.037-0.055-0.11-0.055c-0.037-0.036-0.074-0.055-0.11-0.055
c-0.074,0-0.157,0.055-0.248,0.165c-0.092,0.11-0.193,0.257-0.303,0.44c-0.11,0.186-0.202,0.296-0.276,0.332l-0.11,0.109h0.11
c0.036,0,0.063-0.008,0.083-0.027c0.019-0.018,0.046-0.027,0.083-0.027c0.036,0,0.046,0.02,0.027,0.055
c-0.019,0.037-0.047,0.093-0.083,0.166c-0.075,0.11-0.13,0.186-0.166,0.221l-0.11,0.11l-0.055-0.11c0-0.035,0-0.063,0-0.082
c0-0.02,0-0.037,0-0.056s-0.02-0.009-0.055,0.027c-0.037,0.036-0.092,0.092-0.166,0.165c-0.221,0.075-0.378,0.147-0.469,0.221
c-0.092,0.074-0.157,0.147-0.193,0.221c-0.037,0.074-0.055,0.166-0.055,0.276c-0.037,0.11-0.102,0.257-0.193,0.441
c-0.092,0.185-0.285,0.44-0.579,0.772c-0.295,0.441-0.441,0.736-0.441,0.883v0.055c0.036,0.037,0.055,0.074,0.055,0.11
c-0.037,0-0.075-0.019-0.11-0.056c-0.037,0-0.074-0.008-0.11-0.027c-0.037-0.018-0.074-0.027-0.11-0.027
c-0.037,0-0.074,0.037-0.11,0.11c-0.037,0.036-0.083,0.074-0.138,0.11c-0.055,0.037-0.102,0.074-0.138,0.11
c-0.074,0.036-0.129,0.074-0.166,0.11l-0.055,0.055c0,0.037,0.036,0.074,0.11,0.11c0.184,0.075,0.331,0.11,0.441,0.11
c0.074,0,0.146-0.046,0.221-0.138c0.074-0.091,0.146-0.267,0.221-0.524c0.036-0.221,0.101-0.422,0.193-0.606
c0.091-0.185,0.229-0.35,0.414-0.497c0.294-0.366,0.496-0.605,0.606-0.717l0.055-0.055l0.055,0.055l-0.221,0.221
c-0.037,0.11-0.055,0.24-0.055,0.387c-0.147,0.441-0.313,0.874-0.496,1.296c-0.185,0.423-0.497,0.819-0.938,1.187
c-0.185,0.036-0.34,0.146-0.469,0.33c-0.13,0.186-0.249,0.387-0.358,0.607c-0.147,0.295-0.295,0.533-0.441,0.717
c-0.772,1.509-1.582,2.813-2.427,3.916c-0.552,0.773-1.159,1.675-1.82,2.703c-0.699,1.104-1.361,2.373-1.986,3.807
c-1.03,2.391-2.226,4.266-3.586,5.627h-0.11c-0.11,0-0.229-0.037-0.358-0.11c-0.129-0.075-0.248-0.165-0.358-0.276
c-0.11-0.109-0.221-0.201-0.331-0.275c-0.075-0.037-0.13-0.055-0.166-0.055c-0.074,0-0.129,0.018-0.166,0.055
c-0.295,0.184-0.571,0.275-0.827,0.275c-0.331,0-0.571-0.165-0.717-0.496c-0.221-0.441-0.331-0.957-0.331-1.545
c0-0.185,0.008-0.523,0.027-1.02c0.019-0.497,0.157-0.985,0.414-1.463v0.166c0,0.074-0.009,0.166-0.028,0.275
c-0.019,0.111-0.027,0.221-0.027,0.332v0.165c0.477-1.435,1.269-3.457,2.372-6.068c-0.699,1.911-1.233,3.807-1.6,5.683
c0.11-0.111,0.174-0.24,0.193-0.387c0.019-0.146,0.027-0.295,0.027-0.441s0.019-0.295,0.055-0.441
c0.036-0.146,0.146-0.275,0.331-0.386c0.036,0,0.074,0,0.11,0c0.036,0,0.046,0,0.028,0c-0.02,0-0.037,0.008-0.055,0.027
c-0.02,0.019-0.028,0.046-0.028,0.083h-0.055l-0.055,0.055c0,0.036,0.008,0.055,0.028,0.055c0.018,0,0.046,0,0.083,0
c0.146-0.074,0.276-0.229,0.386-0.469c0.11-0.239,0.201-0.488,0.276-0.744c0.146-0.405,0.276-0.699,0.386-0.883
c0.184-0.588,0.367-1.084,0.551-1.489c0.184-0.404,0.386-0.808,0.607-1.214c0.331-0.551,0.772-1.434,1.324-2.647
c0.036-0.11,0.091-0.239,0.166-0.386c0.074-0.146,0.146-0.285,0.221-0.414c0.074-0.129,0.146-0.193,0.221-0.193h0.055
c0.074,0,0.146,0.055,0.221,0.166c0.036,0.109,0.055,0.185,0.055,0.221h-0.166c-0.147,0.037-0.295,0.185-0.441,0.441
c-0.147,0.257-0.276,0.533-0.386,0.827c-0.074,0.221-0.147,0.405-0.22,0.552c-0.919,2.022-1.747,3.952-2.482,5.792
c-0.11,0.184-0.166,0.331-0.166,0.44v0.111c0,0.035,0.008,0.073,0.027,0.109c0.019,0.037,0.046,0.056,0.083,0.056
c0.036,0.036,0.073,0.083,0.11,0.138c0.036,0.056,0.046,0.166,0.027,0.332c-0.019,0.165-0.102,0.413-0.248,0.744
c0.625-0.883,1.269-1.84,1.931-2.869c0.883-1.47,1.839-2.868,2.869-4.191v-0.111c0-0.035,0.008-0.082,0.027-0.137
c0.019-0.056,0.055-0.146,0.11-0.276c0.055-0.129,0.119-0.249,0.193-0.358l0.11-0.11c-0.074,0.405-0.221,0.827-0.441,1.269
c-0.221,0.441-0.479,0.791-0.772,1.048v0.11l0.055-0.055c0.11,0,0.166,0.02,0.166,0.055l-0.055,0.055l-0.221,0.111
c-0.075,0.037-0.13,0.074-0.166,0.109l-0.11,0.056c-0.185,0.552-0.441,1.14-0.772,1.765c-0.331,0.588-0.662,1.232-0.993,1.932
v0.221c0,0.146,0.036,0.221,0.11,0.221c0.036,0,0.146-0.103,0.331-0.304c0.184-0.202,0.542-0.698,1.076-1.489
c0.532-0.792,1.111-1.701,1.737-2.73c1.104-1.729,2.17-3.338,3.2-4.827c1.029-1.489,1.82-2.38,2.372-2.675h0.166v0.055
c-0.037,0.037-0.083,0.11-0.138,0.221s-0.138,0.221-0.248,0.331c-0.075,0.11-0.138,0.221-0.193,0.331
c-0.055,0.109-0.102,0.202-0.138,0.275h0.166c0.11-0.035,0.201-0.11,0.276-0.221c0.073-0.109,0.128-0.221,0.166-0.33
c0.036-0.146,0.091-0.24,0.166-0.276c0.221-0.11,0.349-0.239,0.386-0.386l0.055-0.166l-0.055-0.221
c-0.22,0.146-0.368,0.221-0.441,0.221c-0.075,0-0.11-0.019-0.11-0.055c0-0.074,0.101-0.313,0.303-0.718
c0.201-0.404,0.717-1.177,1.544-2.316c0.828-1.14,1.718-2.29,2.676-3.448c0.956-1.159,1.783-2.104,2.482-2.841
c0.588-0.552,0.974-0.828,1.159-0.828c0.036,0,0.055,0.019,0.055,0.055c0,0.074-0.055,0.138-0.166,0.193s-0.24,0.102-0.386,0.138
c-0.11,0.037-0.185,0.074-0.221,0.11c0,0.037,0.055,0.074,0.166,0.11v0.055c0.036,0,0.074-0.008,0.11-0.028
c0.036-0.019,0.074-0.046,0.11-0.083c0.036-0.036,0.074-0.063,0.11-0.083c0.036-0.018,0.074-0.036,0.11-0.055
c0.036-0.018,0.055-0.008,0.055,0.028c0,0.074-0.037,0.147-0.11,0.221c-0.075,0.074-0.166,0.147-0.276,0.22h-0.166l-0.055,0.11
l-0.055,0.055c-0.037,0-0.074-0.018-0.11-0.055h-0.11c-0.037,0-0.092,0.02-0.166,0.055c-0.074,0.037-0.185,0.147-0.331,0.331
c-0.258,0.295-0.386,0.516-0.386,0.662v0.11c0.036,0.074,0.128,0.11,0.276,0.11c0.073,0,0.173-0.019,0.303-0.055
c0.129-0.036,0.303-0.166,0.524-0.386c0.441,0.626,1.021,1.067,1.738,1.324c0.717,0.258,1.48,0.441,2.289,0.552
c0.552,0.11,1.066,0.221,1.545,0.331v0.055c0,0.037,0.091,0.055,0.275,0.055c0.221,0,0.515-0.027,0.883-0.083
c0.367-0.055,0.753-0.138,1.158-0.248c0.552-0.074,1.029-0.146,1.435-0.221c0.294-0.036,0.532-0.055,0.717-0.055h0.166
c0.221,0,0.35,0.02,0.386,0.055c0.036,0,0.055,0.02,0.055,0.055l-0.055,0.055c-0.074,0.037-0.185,0.083-0.331,0.138
c-0.147,0.055-0.295,0.102-0.441,0.138l-0.497,0.165c-0.147,0.147-0.441,0.285-0.883,0.414c-0.441,0.13-0.882,0.248-1.324,0.359
c-0.295,0.074-0.533,0.146-0.717,0.22l-0.11,0.056h0.055c0.11,0,0.386-0.019,0.828-0.056c1.287-0.036,2.372-0.201,3.254-0.496
c0.883-0.294,1.71-0.643,2.482-1.048c0.625-0.294,1.287-0.587,1.986-0.882c0-0.036,0-0.074,0-0.11c0-0.036,0.019-0.055,0.055-0.055
c0.036,0,0.055-0.008,0.055-0.028c0-0.018,0-0.046,0-0.083c0-0.036,0-0.063,0-0.083c0-0.018,0.008-0.027,0.028-0.027
c0.018,0,0.046,0,0.083,0c0.036,0,0.083-0.019,0.138-0.055c0.055-0.036,0.083-0.091,0.083-0.166
c0.036-0.036,0.074-0.055,0.11-0.055l0.11-0.055l0.055,0.055c0.073,0,0.128-0.008,0.166-0.028c0.036-0.018,0.073-0.083,0.11-0.193
c0.256-0.221,0.532-0.459,0.827-0.717c0.552-0.551,1.104-0.993,1.655-1.324c0.367-0.367,0.753-0.735,1.159-1.103
c2.317-2.132,4.248-4.339,5.792-6.62c2.538-4.487,3.806-7.796,3.806-9.93c0-0.404-0.055-0.772-0.166-1.103
c-0.368-1.36-1.269-2.041-2.703-2.041c-0.699,0-1.978,0.313-3.834,0.938c-1.857,0.626-4.625,2.685-8.302,6.179
c0-0.036,0.083-0.138,0.249-0.304s0.386-0.395,0.662-0.689c0.275-0.294,0.56-0.587,0.855-0.883
c0.294-0.331,0.532-0.587,0.717-0.772c0.184-0.146,0.276-0.257,0.276-0.331c-0.221,0.221-0.386,0.368-0.497,0.441h-0.11
c0-0.036,0-0.074,0-0.11c0-0.036,0.046-0.11,0.138-0.221c0.091-0.11,0.201-0.211,0.331-0.303c0.128-0.091,0.267-0.157,0.414-0.193
c0.11-0.184,0.166-0.294,0.166-0.331c0-0.036-0.02-0.055-0.055-0.055l-0.166,0.055c-0.147,0.075-0.414,0.268-0.8,0.579
c-0.386,0.313-0.809,0.682-1.269,1.104c-0.461,0.423-0.892,0.836-1.296,1.241c-0.406,0.405-0.699,0.699-0.883,0.882
c-0.037,0.11-0.055,0.185-0.055,0.221l0.11-0.055c0.074-0.11,0.129-0.156,0.166-0.138c0.036,0.019,0.055,0.064,0.055,0.138
c0,0.075-0.028,0.138-0.083,0.193c-0.055,0.055-0.119,0.102-0.193,0.138h-0.055c-0.037,0-0.092-0.018-0.166-0.055
c0-0.036-0.019-0.055-0.055-0.055c-0.11,0-0.248,0.075-0.414,0.221c-0.166,0.147-0.34,0.351-0.524,0.607
c-0.185,0.257-0.34,0.497-0.469,0.717c-0.129,0.22-0.212,0.368-0.248,0.441c-0.147,0.147-0.257,0.221-0.331,0.221
c0-0.073,0.074-0.201,0.221-0.386l0.055-0.055h-0.055l-0.055,0.055c-0.074,0.075-0.147,0.13-0.221,0.166
c-0.11,0.075-0.202,0.138-0.276,0.193c-0.074,0.055-0.147,0.083-0.221,0.083H44.52c0.883-1.103,2.253-2.5,4.11-4.192
c1.856-1.69,3.806-3.254,5.847-4.689c2.041-1.434,3.742-2.335,5.103-2.703c-0.478-0.074-0.717-0.129-0.717-0.166
c0-0.036,0.018-0.055,0.055-0.055c0.146-0.036,0.386-0.055,0.717-0.055c0.146,0,0.432,0.019,0.855,0.055
c0.422,0.037,0.984,0.193,1.683,0.469c0.698,0.276,1.232,0.689,1.6,1.241c0.772,0.588,1.287,1.517,1.545,2.786
c0.256,1.269,0.386,2.474,0.386,3.613c-0.037,0.074-0.074,0.129-0.11,0.166l-0.055,0.055c-0.037,0.037-0.064,0.064-0.083,0.083
c-0.02,0.02-0.027,0.064-0.027,0.138l0.055,0.221c0,0.037-0.027,0.102-0.083,0.193c-0.055,0.092-0.12,0.193-0.193,0.303
c-0.11,0.11-0.185,0.24-0.221,0.386c-0.037,0.037-0.055,0.11-0.055,0.221c0,0.075,0.018,0.166,0.055,0.276
c0.036,0.037,0.073,0.055,0.11,0.055v-0.055c0-0.036,0.018-0.091,0.055-0.166c0-0.036,0-0.063,0-0.083
c0-0.019,0.018-0.028,0.055-0.028c0,0.037-0.009,0.12-0.028,0.248c-0.019,0.13-0.047,0.276-0.083,0.441
c-0.037,0.166-0.075,0.313-0.11,0.441c-0.037,0.13-0.075,0.212-0.11,0.248c0.036-0.294,0.055-0.515,0.055-0.662
c0-0.146-0.02-0.221-0.055-0.221c-0.037,0-0.13,0.276-0.276,0.827c-0.037,0.147-0.055,0.258-0.055,0.331v0.055l0.11-0.055
l0.166-0.055v0.055c0,0.037-0.02,0.11-0.055,0.221c-0.037,0.11-0.092,0.212-0.166,0.304c-0.075,0.092-0.13,0.157-0.166,0.193v-0.11
l0.055-0.166c0-0.036-0.009-0.063-0.028-0.083c-0.019-0.019-0.064-0.028-0.138-0.028c-0.037,0-0.055,0.02-0.055,0.055v0.276
l-0.055,0.055l-0.055-0.055c-0.074-0.036-0.129-0.11-0.166-0.221c-0.037-0.11-0.055-0.239-0.055-0.386
c0-0.11,0.055-0.184,0.166-0.221c0.11-0.073,0.202-0.275,0.276-0.606c0.074-0.331,0.11-0.643,0.11-0.938
c0-0.331-0.037-0.552-0.11-0.662c0-0.036-0.019-0.055-0.055-0.055c-0.074,0-0.166,0.147-0.276,0.441
c-0.074,0.331-0.24,0.809-0.497,1.435L63,61.508c-0.147,0.405-0.221,0.717-0.221,0.938v0.166c0,0.147,0.055,0.221,0.166,0.221
c0.073,0,0.166-0.036,0.276-0.11c0.036,0,0.055,0.037,0.055,0.11c0,0.11-0.037,0.23-0.11,0.359
c-0.075,0.129-0.147,0.229-0.221,0.303l-0.11,0.11h-0.055v-0.166c0.036-0.074,0.055-0.138,0.055-0.193c0-0.055,0-0.091,0-0.11
c0-0.019-0.037-0.008-0.11,0.027c-0.075,0.037-0.175,0.138-0.304,0.303C62.291,63.631,62.117,63.844,61.896,64.101z M14.346,94.385
c0.036,0,0.055,0.008,0.055,0.027c0,0.019,0.008,0.047,0.027,0.083c0.019,0.036,0.028,0.073,0.028,0.11l-0.055,0.055l-0.055-0.055
V94.385z M14.732,97.254c0.036,0,0.055-0.02,0.055-0.056s-0.02-0.092-0.055-0.165c-0.037-0.075-0.083-0.139-0.138-0.193
s-0.102-0.083-0.138-0.083c0,0.073-0.02,0.146-0.055,0.221c-0.037,0.073-0.055,0.146-0.055,0.221c0,0.036,0.036,0.056,0.11,0.056
H14.732z M14.842,93.172c-0.11,0.33-0.185,0.531-0.221,0.606v0.056c0-0.037,0-0.093,0-0.166c0-0.074,0.036-0.221,0.11-0.441
c0.073-0.221,0.138-0.441,0.193-0.662s0.11-0.414,0.166-0.579s0.101-0.268,0.138-0.304l0.055,0.166
c0,0.073-0.019,0.156-0.055,0.248c-0.037,0.091-0.092,0.229-0.166,0.414L14.842,93.172z M14.842,96.26l0.055-0.055h0.055
c0.036,0,0.063-0.009,0.083-0.027c0.019-0.02,0.019-0.037,0-0.055c-0.02-0.02-0.064-0.047-0.138-0.084v0.111L14.842,96.26z
 M15.504,91.296c-0.037,0.146-0.074,0.221-0.11,0.221h-0.055v-0.056c0-0.037,0.008-0.091,0.028-0.165
c0.018-0.073,0.046-0.146,0.083-0.221c0.036-0.073,0.074-0.11,0.11-0.11c0.074,0,0.11,0.02,0.11,0.055l-0.055,0.111
C15.578,91.167,15.54,91.223,15.504,91.296z M15.559,94.33c0.036,0,0.063-0.01,0.083-0.027c0.018-0.02,0.027-0.047,0.027-0.084
l-0.11-0.109c-0.074,0-0.11,0.018-0.11,0.055C15.449,94.2,15.485,94.256,15.559,94.33z M15.669,93.227
c0.146-0.11,0.239-0.166,0.276-0.166c0.036,0,0.055,0.02,0.055,0.056s-0.037,0.128-0.11,0.276c0,0.072-0.02,0.109-0.055,0.109
h-0.166c-0.074-0.037-0.11-0.074-0.11-0.109C15.559,93.317,15.595,93.262,15.669,93.227z M15.945,90.248
c-0.075,0.146-0.129,0.24-0.166,0.275v-0.11c0-0.036,0-0.083,0-0.138s0.019-0.139,0.055-0.248c0.036-0.111,0.074-0.201,0.11-0.276
c0.036-0.073,0.074-0.11,0.11-0.11c0.074,0,0.11,0.02,0.11,0.055v0.056C16.129,89.826,16.056,89.991,15.945,90.248z M16.056,92.178
c0-0.074,0.019-0.146,0.055-0.221l0.11-0.055l0.055,0.055c0.036,0,0.063,0.028,0.083,0.084c0.019,0.055-0.009,0.137-0.083,0.248
l-0.055,0.109h-0.055C16.091,92.398,16.056,92.324,16.056,92.178z M16.111,94.881l0.11,0.056l-0.055-0.11
c0-0.074-0.02-0.129-0.055-0.166h-0.055v0.111C16.056,94.807,16.074,94.845,16.111,94.881z M16.166,89.199l0.055-0.055v0.055
l0.055,0.056v0.165l-0.055,0.056l-0.055-0.056V89.199z M16.387,88.924c-0.075,0-0.11-0.019-0.11-0.055
c0-0.074,0.027-0.185,0.083-0.332c0.055-0.146,0.156-0.366,0.304-0.662c0.146-0.293,0.256-0.477,0.331-0.551l0.11-0.11v0.11
c-0.037,0.037-0.083,0.138-0.138,0.303c-0.055,0.166-0.12,0.341-0.193,0.524c-0.075,0.185-0.147,0.351-0.221,0.497
C16.478,88.795,16.422,88.888,16.387,88.924z M16.663,89.751c1.324-3.641,2.997-7.245,5.02-10.812
C19.696,82.911,18.022,86.516,16.663,89.751z M16.828,90.523c0.036,0.037,0.055,0.092,0.055,0.166s0,0.129,0,0.165h-0.11
c-0.037-0.036-0.064-0.091-0.083-0.165c-0.019-0.074-0.027-0.146-0.027-0.221v-0.056C16.698,90.413,16.753,90.449,16.828,90.523z
 M18.207,90.91c-0.221,0.074-0.35,0.184-0.386,0.33v0.497c0.036,0.073,0.055,0.128,0.055,0.165c0,0.074-0.019,0.129-0.055,0.166
c-0.037,0-0.074,0.008-0.11,0.027c-0.037,0.018-0.074,0.027-0.11,0.027c-0.037,0-0.064,0-0.083,0c-0.02,0-0.047,0.019-0.083,0.055
c-0.037,0.037-0.074,0.092-0.11,0.166c-0.037,0.146-0.055,0.257-0.055,0.331v0.056c0,0.035,0,0.063,0,0.082
c0,0.019,0.019,0.027,0.055,0.027c0.036,0,0.074,0,0.11,0c0.036-0.036,0.074-0.055,0.11-0.055c0,0.035-0.019,0.091-0.055,0.166
c-0.037,0.073-0.064,0.129-0.083,0.165c-0.02,0.036-0.028,0.073-0.028,0.11c0,0.073-0.019,0.138-0.055,0.193
c-0.037,0.055-0.092,0.118-0.166,0.193c-0.037,0.109-0.074,0.165-0.11,0.165v-0.056c0-0.037,0.008-0.092,0.028-0.165
c0.019-0.075,0.027-0.147,0.027-0.221c0.036-0.075,0.063-0.13,0.083-0.165c0.019-0.037,0.027-0.075,0.027-0.111v0.056l-0.166,0.165
c-0.11,0.184-0.185,0.276-0.22,0.276l-0.055-0.056c0-0.074,0.046-0.268,0.138-0.579c0.091-0.313,0.221-0.661,0.386-1.048
s0.322-0.735,0.469-1.049c0.146-0.311,0.276-0.523,0.386-0.634l0.11-0.11v0.331c0,0.036,0.008,0.075,0.027,0.11
c0.019,0.037,0.046,0.074,0.083,0.11l0.11,0.11h-0.055C18.391,90.781,18.317,90.836,18.207,90.91z M17.324,86.772
c-0.074,0.11-0.129,0.165-0.166,0.165v-0.109c0-0.074,0.019-0.146,0.055-0.221c0.036-0.074,0.063-0.146,0.083-0.221
c0.019-0.074,0.046-0.111,0.083-0.111h0.055l0.055,0.056c0,0.036-0.019,0.103-0.055,0.192
C17.398,86.616,17.36,86.699,17.324,86.772z M17.324,89.199c0-0.073,0.019-0.118,0.055-0.138c0.036-0.019,0.074-0.027,0.11-0.027
c0.074,0,0.11,0.036,0.11,0.11c0,0.037-0.019,0.082-0.055,0.138c-0.037,0.056-0.074,0.083-0.11,0.083
C17.398,89.365,17.36,89.311,17.324,89.199z M17.6,86.11c-0.037,0-0.055-0.036-0.055-0.11c0-0.035,0.008-0.091,0.028-0.166
c0.018-0.072,0.046-0.137,0.083-0.192c0.036-0.056,0.074-0.083,0.11-0.083h0.055v0.055c0,0.037-0.019,0.131-0.055,0.276
c-0.037,0.075-0.064,0.13-0.083,0.165C17.664,86.092,17.636,86.11,17.6,86.11z M18.152,87.379v-0.055
c0.036,0.037,0.055,0.082,0.055,0.138s0,0.103,0,0.138l-0.055,0.055L18.097,87.6c0-0.035,0-0.082,0-0.138
S18.115,87.379,18.152,87.379z M18.428,89.586l0.11-0.055c0,0.036-0.037,0.109-0.11,0.22l-0.055,0.11
c-0.037,0-0.055-0.018-0.055-0.055c0-0.074,0.008-0.129,0.027-0.166C18.363,89.605,18.391,89.586,18.428,89.586z M19.862,83.959
c0.074-0.367,0.229-0.791,0.469-1.269c0.239-0.478,0.478-0.91,0.717-1.296c0.239-0.387,0.432-0.653,0.579-0.801
c0.036-0.035,0.074-0.055,0.11-0.055l0.055,0.055c0,0.074-0.047,0.213-0.138,0.414c-0.092,0.202-0.378,0.781-0.855,1.738
c-0.331,0.661-0.607,1.195-0.828,1.6s-0.423,0.754-0.607,1.048c-0.295,0.44-0.588,0.957-0.882,1.544
c0.036-0.404,0.193-0.91,0.469-1.517S19.531,84.326,19.862,83.959z M19.31,89.034c-0.037-0.073-0.092-0.11-0.166-0.11
c-0.074,0-0.147,0.027-0.22,0.082c-0.075,0.056-0.147,0.103-0.221,0.139l-0.11,0.11v-0.11l0.11-0.275
c0.036-0.111,0.073-0.185,0.11-0.221h0.055c0.036,0,0.073,0.02,0.11,0.055c0.036,0.037,0.073,0.064,0.11,0.082
c0.036,0.02,0.091,0.028,0.165,0.028c0.146,0.036,0.239,0.074,0.276,0.11c0.036,0.037,0.046,0.082,0.028,0.138
c-0.02,0.056-0.047,0.083-0.083,0.083C19.401,89.145,19.346,89.108,19.31,89.034z M18.648,90.744c0,0.037,0.018,0.082,0.055,0.138
c0.036,0.056,0.073,0.103,0.11,0.138v0.111h-0.055c-0.037,0-0.075-0.028-0.11-0.084c-0.037-0.055-0.055-0.118-0.055-0.192
L18.648,90.744z M18.869,90.578l0.055,0.111l-0.11-0.056c-0.075-0.036-0.11-0.073-0.11-0.11c0-0.035,0.008-0.063,0.027-0.082
c0.019-0.02,0.036-0.02,0.055,0C18.805,90.46,18.832,90.505,18.869,90.578z M20.358,87.654c-0.037,0.332-0.185,0.572-0.441,0.718
c-0.147,0.075-0.276,0.11-0.386,0.11s-0.202-0.019-0.276-0.055c-0.11-0.036-0.165-0.146-0.165-0.332v-0.221
c0-0.184,0.073-0.293,0.22-0.33l0.221-0.055l0.221,0.055h0.331c0.11-0.036,0.166-0.074,0.166-0.11l-0.055-0.056
c0.036-0.035,0.083-0.082,0.138-0.138s0.102-0.083,0.138-0.083C20.469,87.27,20.432,87.435,20.358,87.654z M21.572,84.4
c0.074,0.037,0.129,0.074,0.166,0.11h0.055l-0.055,0.056c-0.11,0.036-0.295,0.055-0.551,0.055c-0.185,0-0.351,0.01-0.497,0.027
c-0.147,0.02-0.257,0.064-0.331,0.139c0,0.036,0.036,0.055,0.11,0.055h0.882c0.074,0.074,0.11,0.146,0.11,0.221
s-0.037,0.146-0.11,0.221c-0.037,0.037-0.092,0.055-0.165,0.055c-0.075,0-0.185-0.018-0.331-0.055
c-0.075-0.074-0.13-0.11-0.166-0.11c-0.037,0-0.075,0.019-0.11,0.056c-0.037,0.036-0.075,0.055-0.11,0.055l-0.055,0.055
l-0.055-0.055v-0.11l-0.11,0.056c-0.11,0.074-0.185,0.109-0.221,0.109c-0.074,0-0.11-0.035-0.11-0.109
c0-0.111,0.055-0.295,0.166-0.553c0.11-0.256,0.248-0.531,0.414-0.827c0.166-0.294,0.304-0.532,0.414-0.717
s0.184-0.276,0.221-0.276c0.11,0.037,0.165,0.092,0.165,0.166l-0.055,0.11c-0.037,0.074-0.11,0.185-0.22,0.331
c-0.075,0.109-0.13,0.24-0.166,0.386c-0.037,0.075-0.055,0.147-0.055,0.221v0.386h0.221c0.073-0.035,0.156-0.063,0.248-0.082
C21.359,84.354,21.461,84.365,21.572,84.4z M20.8,85.834l0.11-0.165c0.036,0,0.055,0.02,0.055,0.056
c0,0.037-0.009,0.082-0.028,0.138C20.918,85.918,20.873,85.965,20.8,86c-0.037,0.037-0.075,0.055-0.11,0.055h-0.055
c-0.037,0-0.075,0-0.11,0c-0.037,0-0.092,0.037-0.166,0.111c-0.037,0.074-0.055,0.129-0.055,0.165s0,0.064,0,0.083
c0,0.02,0.036,0.037,0.11,0.055c0.074,0.02,0.146,0.027,0.221,0.027c0.073,0.037,0.119,0.064,0.138,0.083
c0.019,0.02,0,0.037-0.055,0.056c-0.055,0.02-0.193,0.064-0.414,0.138c-0.11,0.074-0.202,0.11-0.276,0.11h-0.055
c-0.037-0.036-0.055-0.074-0.055-0.11s0.019-0.083,0.055-0.138c0.036-0.056,0.091-0.156,0.166-0.304
c0.036-0.11,0.083-0.229,0.138-0.358s0.083-0.229,0.083-0.304l-0.055-0.056c0-0.035,0.008-0.055,0.027-0.055
c0.019,0,0.046,0,0.083,0c0.036,0,0.074-0.008,0.11-0.027c0.036-0.019,0.073-0.046,0.11-0.082c0.036-0.074,0.055-0.102,0.055-0.084
c0,0.02,0,0.056,0,0.111c0,0.055,0,0.119,0,0.192c-0.037,0.075-0.055,0.147-0.055,0.221V86C20.67,86,20.725,85.945,20.8,85.834z
 M30.508,65.811h-0.166c-0.074,0-0.11-0.019-0.11-0.055c0-0.074,0.019-0.146,0.055-0.221c-3.016,4.119-5.902,8.587-8.661,13.405
c2.207-4.413,4.56-8.614,7.061-12.605c2.5-3.99,4.928-7.107,7.282-9.35c1.655-1.508,3.144-2.262,4.468-2.262
c0.588,0,1.139,0.147,1.655,0.441c0.11,0.11,0.166,0.24,0.166,0.386c0,0.295-0.249,0.726-0.745,1.296
c-0.496,0.571-1.167,1.223-2.013,1.958c-0.662,0.588-1.269,1.167-1.82,1.738c-0.552,0.571-0.902,1.076-1.048,1.517
c-2.685,2.647-4.984,5.646-6.896,8.991c-1.581,2.723-3.329,5.167-5.24,7.337c0.036,0,0.063,0,0.083,0
c0.019,0,0.063-0.019,0.138-0.056c0.036,0,0.063-0.008,0.083-0.027c0.019-0.018,0.036-0.018,0.055,0
c0.019,0.02,0.028,0.064,0.028,0.139s-0.02,0.129-0.055,0.165c-0.037,0.036-0.075,0.074-0.11,0.11l-0.055,0.055
c-0.295,0.662-0.607,1.324-0.938,1.986c-0.037,0.074-0.074,0.166-0.11,0.275c0,0.074-0.064,0.158-0.193,0.248
c-0.13,0.092-0.268,0.176-0.414,0.248c-0.185,0.111-0.313,0.203-0.386,0.276c-0.037,0.037-0.055,0.075-0.055,0.11
c0,0.037,0.036,0.074,0.11,0.11h0.166l0.11-0.11h0.11c0,0.037-0.02,0.074-0.055,0.11c-0.037,0.036-0.083,0.092-0.138,0.165
c-0.055,0.075-0.102,0.131-0.138,0.166c-0.037,0.037-0.075,0.055-0.11,0.055v-0.109l0.055-0.166c-0.037,0-0.11,0.037-0.221,0.11
c-0.11,0.185-0.202,0.276-0.276,0.276c-0.037,0-0.055-0.037-0.055-0.111c0-0.109,0.028-0.229,0.083-0.357s0.11-0.268,0.165-0.414
s0.102-0.257,0.138-0.332l0.166-0.164l0.055-0.111c0.036-0.073,0.073-0.138,0.11-0.193c0.036-0.055,0.073-0.082,0.11-0.082
c0.036,0,0.073,0.037,0.11,0.11c0.036,0.074,0.073,0.11,0.11,0.11c0.036,0,0.073-0.055,0.11-0.166
c0.036-0.035,0.055-0.073,0.055-0.109v-0.056c-0.037-0.036-0.102-0.056-0.193-0.056c-0.092,0-0.202,0.01-0.331,0.027
c-0.129,0.02-0.229,0.028-0.303,0.028l-0.166,0.056v0.055h0.055c0,0.185-0.055,0.434-0.166,0.744
c-0.11,0.313-0.248,0.6-0.414,0.855c-0.166,0.258-0.378,0.387-0.634,0.387c-0.037,0-0.055-0.02-0.055-0.056s0-0.083,0-0.138
c0-0.056,0.046-0.146,0.138-0.275c0.091-0.129,0.174-0.24,0.248-0.332c0.074-0.091,0.129-0.138,0.166-0.138
c0.036-0.073,0.074-0.184,0.11-0.331c0.036-0.221,0.101-0.432,0.193-0.635c0.091-0.201,0.211-0.357,0.358-0.469
c0.074-0.073,0.166-0.109,0.276-0.109c0.036,0,0.091,0.02,0.166,0.055l0.11,0.055v-0.055c0-0.035,0-0.083,0-0.139
c0-0.055,0.008-0.09,0.027-0.109c0.019-0.019,0.063-0.027,0.138-0.027l0.055,0.055c0.073,0,0.156-0.063,0.248-0.193
c0.091-0.129,0.211-0.321,0.358-0.579c0.11-0.184,0.211-0.358,0.304-0.524c0.091-0.165,0.174-0.248,0.248-0.248
c0.036,0,0.055,0.02,0.055,0.056l-0.11,0.221c-0.037,0.036-0.064,0.074-0.083,0.11c-0.02,0.037-0.028,0.074-0.028,0.11l0.055-0.056
c0.036,0,0.091-0.035,0.166-0.109c0.036-0.111,0.074-0.276,0.11-0.497c0.036-0.221,0.091-0.405,0.166-0.552
c0.074-0.074,0.146-0.11,0.221-0.11c0.036,0,0.091,0.02,0.166,0.056c-0.075-0.074-0.11-0.166-0.11-0.276c0-0.036,0-0.091,0-0.165
s0.036-0.146,0.11-0.221c0.073-0.111,0.11-0.184,0.11-0.221c0-0.074-0.055-0.11-0.166-0.11c-0.11,0.22-0.24,0.386-0.386,0.496
c-0.147,0.11-0.257,0.202-0.331,0.276l-0.166,0.055c0,0.037,0.036,0.074,0.11,0.11c0.036,0,0.046,0.02,0.028,0.056
c-0.02,0.036-0.064,0.092-0.138,0.165c-0.11,0.036-0.175,0.103-0.193,0.192c-0.02,0.093-0.028,0.176-0.028,0.249
c0.036,0.074,0.046,0.138,0.028,0.192c-0.02,0.056-0.047,0.103-0.083,0.139c-0.037,0.11-0.083,0.193-0.138,0.248
s-0.12,0.119-0.193,0.193c-0.147,0.074-0.275,0.202-0.386,0.386c-0.11,0.221-0.185,0.331-0.221,0.331h-0.055
c0-0.035,0-0.074,0-0.11s0.018-0.128,0.055-0.276c0.036-0.146,0.101-0.275,0.193-0.385c0.091-0.111,0.174-0.202,0.249-0.276
c0.11-0.073,0.193-0.185,0.248-0.331s0.119-0.295,0.193-0.441c0.036-0.221,0.11-0.432,0.221-0.635
c0.11-0.201,0.257-0.339,0.441-0.414c0.074,0,0.129-0.018,0.166-0.055c0.074-0.035,0.11-0.073,0.11-0.109
c0-0.037-0.009-0.074-0.028-0.111c-0.019-0.035-0.064-0.055-0.138-0.055c0.036-0.11,0.11-0.239,0.221-0.387
c0.221-0.366,0.469-0.772,0.745-1.213c0.276-0.441,0.56-0.808,0.855-1.103l0.166-0.166h0.055c0-0.036-0.009-0.055-0.028-0.055
c-0.019,0-0.047,0-0.083,0c-0.037,0-0.074-0.008-0.11-0.027c-0.037-0.019-0.055-0.046-0.055-0.083c0-0.036,0.036-0.074,0.11-0.11
c0.074-0.146,0.146-0.239,0.221-0.276c0.074,0,0.11,0.037,0.11,0.11l-0.055,0.331h0.11c0.036,0,0.091-0.008,0.166-0.027
c0.073-0.019,0.146-0.046,0.221-0.083c0.073-0.036,0.11-0.074,0.11-0.11c0-0.073-0.055-0.11-0.166-0.11h-0.11
c-0.037,0-0.075-0.018-0.11-0.055c-0.037-0.036-0.055-0.083-0.055-0.138s0-0.119,0-0.193l0.11-0.055l0.055,0.055
c0.146,0.075,0.256,0.11,0.331,0.11c0.073,0,0.128-0.055,0.166-0.166l0.055-0.055c0-0.036-0.009-0.063-0.028-0.083
c-0.02-0.019-0.055-0.027-0.11-0.027s-0.12,0-0.193,0c-0.075,0-0.13,0-0.166,0c-0.037,0-0.055-0.019-0.055-0.055l0.11-0.11
c0.073-0.146,0.146-0.312,0.221-0.497c0.073-0.184,0.156-0.339,0.248-0.469c0.091-0.129,0.229-0.193,0.414-0.193
c0.036,0,0.063-0.036,0.083-0.11c0.018-0.073,0.027-0.146,0.027-0.221c0.036-0.146,0.074-0.267,0.11-0.358
c0.036-0.091,0.11-0.138,0.221-0.138h0.11l0.055-0.055v-0.221c0-0.11,0.019-0.221,0.055-0.331c0.036-0.11,0.11-0.184,0.221-0.221
c0.146-0.036,0.229-0.091,0.248-0.166c0.019-0.074-0.027-0.11-0.138-0.11c0-0.11,0.018-0.174,0.055-0.193
c0.036-0.019,0.091-0.028,0.166-0.028c0.073,0,0.146,0.02,0.221,0.055h0.166c-0.037-0.036-0.075-0.091-0.11-0.166
c-0.037-0.036-0.055-0.091-0.055-0.166c0-0.073,0.036-0.174,0.11-0.303c0.073-0.129,0.184-0.303,0.331-0.524
c0.074-0.146,0.146-0.276,0.221-0.386c0.074-0.074,0.11-0.129,0.11-0.166l-0.055-0.055c-0.037,0-0.129,0.02-0.276,0.055
c-0.147,0.11-0.285,0.257-0.414,0.441c-0.129,0.185-0.23,0.386-0.303,0.606c-0.147,0.295-0.295,0.534-0.441,0.717
c-0.075,0.075-0.166,0.11-0.276,0.11s-0.257-0.055-0.441-0.166c0-0.036,0-0.074,0-0.11c0-0.036,0.019-0.055,0.055-0.055l0.11-0.11
l-0.055-0.055c0.11,0,0.211-0.046,0.304-0.138c0.091-0.091,0.173-0.193,0.248-0.303c0.074-0.221,0.221-0.405,0.441-0.552
c0.146-0.074,0.229-0.146,0.248-0.221c0.019-0.074-0.009-0.11-0.083-0.11c-0.11,0-0.24,0.074-0.386,0.221l-0.11,0.055l0.055-0.166
c0.074-0.074,0.146-0.166,0.221-0.276c0.11-0.11,0.201-0.221,0.276-0.331c0.073-0.11,0.128-0.184,0.166-0.221
c0.036-0.073,0.128-0.184,0.275-0.331c0.146-0.146,0.312-0.275,0.497-0.386c0.146-0.11,0.267-0.202,0.359-0.276
c0.091-0.074,0.138-0.129,0.138-0.166c0-0.036-0.02-0.055-0.055-0.055C30.637,65.755,30.582,65.774,30.508,65.811z M22.234,82.911
l0.11-0.056v0.166c-0.037,0.074-0.102,0.185-0.193,0.33c-0.092,0.148-0.193,0.296-0.304,0.441c-0.11,0.148-0.221,0.258-0.331,0.332
c0,0.036-0.019,0.055-0.055,0.055c-0.037,0-0.092-0.035-0.166-0.11v-0.056c0-0.035,0.019-0.101,0.055-0.192
c0.036-0.091,0.091-0.185,0.166-0.276c0.074-0.091,0.146-0.138,0.221-0.138c0.036,0,0.055,0.093,0.055,0.276
c0.036-0.036,0.074-0.102,0.11-0.193c0.036-0.091,0.074-0.174,0.11-0.248c0.036-0.074,0.074-0.139,0.11-0.193
C22.16,82.994,22.197,82.947,22.234,82.911z M21.792,82.801c0.074-0.036,0.129-0.046,0.166-0.027
c0.036,0.02,0.055,0.047,0.055,0.082c0,0.074-0.019,0.147-0.055,0.221l-0.055,0.056c-0.037,0-0.064,0-0.083,0
c-0.02,0-0.037-0.019-0.055-0.056c-0.02-0.035-0.028-0.073-0.028-0.109C21.737,82.893,21.756,82.838,21.792,82.801z M21.848,79.987
h0.055c0.036,0,0.091-0.036,0.166-0.11c0.074-0.074,0.166-0.184,0.276-0.331c0.074-0.11,0.138-0.201,0.193-0.276
c0.055-0.072,0.102-0.109,0.138-0.109c0,0.037-0.055,0.146-0.166,0.33c-0.11,0.186-0.221,0.352-0.331,0.497
c-0.11,0.147-0.202,0.24-0.276,0.276c-0.037,0-0.055-0.02-0.055-0.056V79.987z M22.675,78.719l0.055,0.055l-0.055,0.056
c-0.037,0.074-0.092,0.147-0.166,0.221c-0.075,0.074-0.147,0.138-0.221,0.192c-0.075,0.056-0.129,0.103-0.166,0.139l-0.11,0.055
c0-0.035,0.019-0.091,0.055-0.166c0.036-0.072,0.101-0.156,0.193-0.248c0.091-0.09,0.173-0.165,0.248-0.221
C22.583,78.746,22.639,78.719,22.675,78.719z M22.179,86.496v0.056l-0.11,0.11h-0.055c0-0.036,0.008-0.083,0.028-0.139
c0.018-0.055,0.036-0.101,0.055-0.137c0.018-0.036,0.036-0.047,0.055-0.027C22.169,86.378,22.179,86.423,22.179,86.496z
 M22.73,85.834h-0.055c-0.037,0-0.055-0.018-0.055-0.055c0-0.035,0.055-0.166,0.166-0.386c0.11-0.221,0.322-0.57,0.634-1.048
c0.312-0.478,0.653-0.975,1.021-1.49c0.367-0.515,0.68-0.938,0.938-1.269c0.256-0.331,0.404-0.496,0.441-0.496
c-0.516,0.81-1.048,1.618-1.6,2.427C23.483,84.621,22.987,85.394,22.73,85.834z M22.951,81.477l0.055-0.275v-0.055l-0.055,0.055
c-0.037,0-0.064,0.027-0.083,0.082c-0.019,0.056-0.027,0.103-0.027,0.139v0.109c0,0.037,0.018,0.056,0.055,0.056L22.951,81.477z
 M23.337,77.119c0.146-0.146,0.257-0.295,0.331-0.441c0.074-0.146,0.184-0.275,0.331-0.387v-0.055c0-0.074,0.019-0.146,0.055-0.221
c0.036-0.074,0.091-0.146,0.166-0.221c0.036-0.036,0.063-0.074,0.083-0.11c0.018-0.036,0.027-0.074,0.027-0.11
c0.036-0.036,0.055-0.11,0.055-0.221c0.036-0.11,0.083-0.211,0.138-0.303c0.055-0.091,0.138-0.138,0.249-0.138
c0.036-0.074,0.11-0.229,0.221-0.469c0.11-0.239,0.248-0.469,0.414-0.689s0.303-0.367,0.414-0.441v-0.055
c0-0.073,0.036-0.156,0.11-0.248c0.074-0.091,0.166-0.193,0.276-0.303c0.11-0.11,0.201-0.202,0.276-0.276h0.11v0.055
c0,0.037-0.075,0.185-0.221,0.441c-0.221,0.295-0.571,0.847-1.048,1.655c-0.441,0.699-0.855,1.371-1.241,2.013
c-0.386,0.644-0.671,1.058-0.855,1.241l-0.055,0.055l-0.055-0.055C23.117,77.726,23.19,77.486,23.337,77.119z M26.15,75.243
c0.146-0.256,0.239-0.422,0.276-0.496l0.055-0.055c0-0.074-0.055-0.11-0.166-0.11c-0.074,0-0.221,0.129-0.441,0.386
c-0.221,0.258-0.423,0.534-0.606,0.828c-0.185,0.295-0.276,0.496-0.276,0.607c0,0.146,0.036,0.221,0.11,0.221h0.055
c0.036,0,0.073-0.02,0.11-0.056c0.036-0.036,0.091-0.11,0.166-0.221c0.073-0.11,0.146-0.202,0.22-0.276
c0.036-0.035,0.074-0.055,0.11-0.055c0.036,0,0.074,0.02,0.11,0.055h0.055v-0.11c0-0.11,0.008-0.229,0.028-0.358
C25.976,75.473,26.04,75.354,26.15,75.243z M26.15,80.539l0.166-0.11c0,0.036-0.055,0.13-0.166,0.276l-0.166,0.165
c0-0.036,0-0.063,0-0.083c0-0.018,0.019-0.055,0.055-0.11C26.076,80.621,26.114,80.576,26.15,80.539z M26.868,70.94
c0.036-0.073,0.091-0.138,0.166-0.193c0.073-0.055,0.166-0.119,0.276-0.193c0.184-0.11,0.331-0.221,0.441-0.331
c0.11-0.074,0.166-0.166,0.166-0.276l-0.055-0.055c0.036-0.074,0.091-0.129,0.166-0.166c0.074-0.036,0.146-0.046,0.221-0.028
c0.074,0.02,0.11,0.047,0.11,0.083c0,0.074-0.092,0.185-0.276,0.331c-0.221,0.257-0.405,0.571-0.551,0.938
c-0.11,0.295-0.258,0.552-0.441,0.772c-0.147,0.185-0.351,0.276-0.607,0.276h-0.166c-0.147-0.036-0.221-0.091-0.221-0.166
c0-0.074,0.055-0.11,0.166-0.11c0.074-0.074,0.146-0.129,0.221-0.166c0.074-0.036,0.129-0.055,0.166-0.055
c0.036,0,0.074-0.008,0.11-0.027c0.036-0.019,0.063-0.074,0.083-0.166C26.858,71.318,26.868,71.161,26.868,70.94z M26.592,80.098
v-0.055c0-0.036,0.008-0.074,0.027-0.111c0.019-0.035,0.063-0.073,0.138-0.109c0.074-0.036,0.146-0.064,0.221-0.083
c0.073-0.019,0.128-0.028,0.166-0.028l0.055,0.056l-0.11,0.056c-0.037,0-0.092,0.02-0.166,0.055
c-0.075,0.037-0.13,0.074-0.166,0.11l-0.11,0.11H26.592z M26.978,83.959v-0.11l0.055-0.056c0,0.037,0,0.064,0,0.084
C27.033,83.896,27.014,83.924,26.978,83.959z M27.364,72.43v0.11c0,0.037,0.018,0.055,0.055,0.055h0.11l0.11-0.055
c0.036-0.036,0.083-0.083,0.138-0.138s0.083-0.101,0.083-0.138c0-0.036-0.074-0.055-0.221-0.055c-0.037,0-0.083,0.019-0.138,0.055
C27.447,72.301,27.4,72.356,27.364,72.43z M28.081,77.946c0.146-0.146,0.239-0.221,0.276-0.221h0.055
c0,0.036-0.019,0.083-0.055,0.138c-0.037,0.056-0.092,0.13-0.166,0.221c-0.074,0.092-0.166,0.175-0.276,0.248
c-0.147,0.147-0.24,0.221-0.276,0.221l0.055-0.055C27.695,78.424,27.824,78.241,28.081,77.946z M28.026,71.989
c0.036-0.036,0.063-0.074,0.083-0.11c0.018-0.036,0.027-0.074,0.027-0.11c0-0.036-0.019-0.055-0.055-0.055v-0.055
c-0.037,0.075-0.064,0.13-0.083,0.166c-0.02,0.037-0.028,0.074-0.028,0.11L28.026,71.989z M28.467,71.492
c0.11-0.146,0.166-0.256,0.166-0.331c0-0.036-0.02-0.055-0.055-0.055h-0.11c-0.037,0-0.064,0.028-0.083,0.083
c-0.02,0.055-0.047,0.12-0.083,0.193c-0.037,0.075-0.055,0.147-0.055,0.221c0,0.037,0.019,0.055,0.055,0.055
C28.338,71.658,28.393,71.603,28.467,71.492z M29.129,70.334c0.11-0.146,0.166-0.257,0.166-0.331c-0.037,0-0.11,0.037-0.221,0.11
c-0.075,0.037-0.13,0.092-0.166,0.166c-0.037,0.074-0.055,0.129-0.055,0.166l0.055,0.055h0.055L29.129,70.334z M28.964,81.587
c0.036-0.11,0.073-0.165,0.11-0.165h0.055v0.055c0,0.037-0.02,0.074-0.055,0.11l-0.11,0.11V81.587z M29.35,76.347
c0.073-0.11,0.146-0.221,0.221-0.331c0.073-0.11,0.138-0.193,0.193-0.248s0.101-0.083,0.138-0.083l-0.055,0.11
c-0.037,0.075-0.083,0.147-0.138,0.221c-0.055,0.074-0.12,0.146-0.193,0.221c-0.075,0.074-0.13,0.129-0.166,0.166V76.347z
 M30.232,68.899c0.074-0.146,0.11-0.239,0.11-0.276c-0.037,0-0.11,0.037-0.221,0.11c-0.074,0.075-0.11,0.13-0.11,0.166
c0,0.075,0.019,0.11,0.055,0.11C30.103,69.01,30.158,68.974,30.232,68.899z M30.619,67.189c0.074,0,0.119-0.008,0.138-0.027
c0.019-0.019,0.019-0.036,0-0.055c-0.019-0.019-0.047-0.028-0.083-0.028c-0.037,0-0.074,0.02-0.11,0.055
c-0.037,0-0.055,0.02-0.055,0.055H30.619z M30.895,65.314c-0.075,0.037-0.13,0.075-0.166,0.11v0.166
c0,0.037,0.019,0.055,0.055,0.055l0.055-0.055c0.036,0,0.074-0.028,0.11-0.083c0.036-0.055,0.055-0.101,0.055-0.138l0.055-0.055
h-0.055v-0.055L30.895,65.314z M31.501,65.976l-0.662,0.662v0.055c0,0.037,0,0.047,0,0.028c0-0.019,0.036-0.046,0.11-0.083
c0.074-0.036,0.146-0.091,0.221-0.166c0.073-0.073,0.138-0.146,0.193-0.22c0.055-0.074,0.101-0.129,0.138-0.166l0.055-0.11H31.501z
 M31.281,73.919c0-0.036,0.018-0.091,0.055-0.166l0.055-0.055l0.11-0.055h0.055v0.055c-0.037,0.037-0.075,0.083-0.11,0.138
c-0.037,0.055-0.075,0.083-0.11,0.083H31.281z M32.439,62.721c-0.037,0-0.102,0.047-0.193,0.138
c-0.092,0.092-0.193,0.202-0.304,0.331c-0.11,0.129-0.202,0.248-0.275,0.358c-0.075,0.11-0.11,0.185-0.11,0.221l0.166-0.11
c0.073-0.074,0.165-0.166,0.275-0.276s0.221-0.221,0.331-0.331s0.184-0.201,0.221-0.276v-0.055H32.439z M31.777,64.376
c-0.074,0.037-0.129,0.075-0.165,0.11l-0.055,0.055c0,0.037,0,0.064,0,0.083c0,0.019,0.018,0.027,0.055,0.027
c0.036,0,0.083-0.018,0.138-0.055c0.055-0.036,0.11-0.073,0.166-0.11c0.055-0.036,0.083-0.073,0.083-0.11l0.055-0.055h-0.055
C31.961,64.321,31.887,64.34,31.777,64.376z M32.163,65.921h0.11v-0.055c0-0.036-0.009-0.063-0.027-0.083
c-0.02-0.018-0.047-0.027-0.083-0.027c-0.074,0-0.11,0.019-0.11,0.055C32.053,65.847,32.089,65.885,32.163,65.921z M32.329,63.438
v0.166c0,0.037,0.019,0.055,0.055,0.055c0.074,0,0.129-0.019,0.166-0.055l0.055-0.055l-0.055-0.11c0-0.036-0.019-0.055-0.055-0.055
C32.458,63.383,32.402,63.403,32.329,63.438z M32.549,63.052v0.11c0,0.037,0.019,0.064,0.055,0.083
c0.036,0.019,0.083,0.009,0.138-0.028c0.055-0.036,0.11-0.074,0.165-0.11c0.055-0.036,0.074-0.063,0.055-0.083
c-0.019-0.019-0.083-0.028-0.193-0.028c-0.037,0.037-0.074,0.055-0.11,0.055H32.549z M32.825,62.225
c-0.11,0.11-0.166,0.185-0.166,0.22V62.5h0.055c0.074,0,0.166-0.036,0.276-0.11c0.146-0.073,0.221-0.128,0.221-0.165l0.055-0.055
h-0.055c-0.075-0.036-0.147-0.046-0.221-0.028C32.916,62.162,32.861,62.189,32.825,62.225z M34.48,73.533
c0.074-0.073,0.129-0.119,0.166-0.138c0.036-0.019,0.055,0.027,0.055,0.138c0,0.037-0.019,0.055-0.055,0.055l-0.055,0.055
c-0.037,0-0.064-0.008-0.083-0.027C34.488,73.598,34.48,73.57,34.48,73.533z M35.252,69.12l0.055,0.055
c0,0.037-0.028,0.083-0.083,0.138s-0.119,0.119-0.193,0.193c-0.075,0.075-0.147,0.147-0.221,0.221l-0.11,0.11l-0.11,0.055
c0-0.036,0.028-0.083,0.083-0.138s0.11-0.118,0.166-0.193c0.055-0.074,0.118-0.146,0.193-0.221
c0.074-0.074,0.129-0.128,0.166-0.166L35.252,69.12z M37.349,68.569c0.404-0.294,0.946-0.828,1.627-1.6
c0.68-0.772,1.406-1.6,2.179-2.482c1.213-1.47,2.151-2.518,2.813-3.144c-1.361,1.912-3.181,4.063-5.461,6.454
c-1.36,1.434-2.574,2.795-3.641,4.082c0.294-0.477,0.717-1.029,1.269-1.655C36.687,69.598,37.091,69.047,37.349,68.569z
 M35.032,72.816c0.11-0.073,0.184-0.11,0.221-0.11c0.036,0,0.055,0.019,0.055,0.055c0,0.037-0.02,0.083-0.055,0.138
c-0.037,0.055-0.092,0.102-0.166,0.138c-0.037,0.075-0.075,0.11-0.11,0.11c-0.037,0-0.055-0.018-0.055-0.055v-0.055
C34.921,73.001,34.957,72.926,35.032,72.816z M35.804,71.989c0-0.036,0.008-0.063,0.027-0.083c0.019-0.018,0.063-0.027,0.138-0.027
h0.055v0.055c0,0.037-0.019,0.074-0.055,0.11c-0.037,0.037-0.075,0.055-0.11,0.055h-0.055V71.989z M36.576,70.334
c0.036-0.036,0.074-0.063,0.11-0.083c0.036-0.018,0.055,0.009,0.055,0.083c0,0.037-0.009,0.064-0.027,0.083
c-0.02,0.02-0.047,0.028-0.083,0.028h-0.055V70.334z M36.797,70.885c0-0.036,0.091-0.184,0.276-0.441
c0.184-0.257,0.422-0.552,0.717-0.883c0.294-0.331,0.57-0.634,0.827-0.91c0.257-0.276,0.422-0.45,0.497-0.524h0.11
c0,0.037-0.055,0.119-0.166,0.248c-0.11,0.13-0.276,0.331-0.497,0.607s-0.46,0.552-0.717,0.827
c-0.258,0.276-0.488,0.524-0.69,0.745C36.953,70.775,36.833,70.885,36.797,70.885z M38.396,77.449
c0.074,0.037,0.129,0.056,0.166,0.056v-0.056c-0.037,0-0.055-0.018-0.055-0.055c-0.037-0.073-0.074-0.129-0.11-0.166
c-0.037-0.035-0.055-0.072-0.055-0.109c0-0.036,0.036-0.074,0.11-0.111l0.055-0.055h-0.055c-0.037-0.035-0.11-0.055-0.221-0.055
s-0.229,0.009-0.358,0.027c-0.129,0.02-0.23,0.027-0.303,0.027l-0.055,0.055c0.036,0.037,0.073,0.064,0.11,0.084
c0.036,0.02,0.091,0.027,0.166,0.027c0.073,0,0.128,0.02,0.166,0.055c0.073,0,0.11,0.02,0.11,0.055l-0.055,0.056
c0,0.037,0.008,0.064,0.027,0.083c0.019,0.02,0.063,0.027,0.138,0.027C38.25,77.395,38.322,77.414,38.396,77.449z M38.452,68.348
l-0.221,0.221c-0.074,0.037-0.11,0.074-0.11,0.11c0-0.036,0-0.063,0-0.083c0-0.019,0.019-0.055,0.055-0.11
c0.036-0.055,0.091-0.101,0.166-0.138l0.055-0.055C38.433,68.293,38.452,68.312,38.452,68.348z M38.672,76.402
c0.587,0.036,1.029,0.055,1.324,0.055h0.275c0.11,0,0.166-0.019,0.166-0.055c0-0.037-0.102-0.074-0.303-0.111
c-0.202-0.035-0.561-0.055-1.076-0.055c-0.074,0-0.147,0-0.221,0c-0.074,0-0.147,0.01-0.221,0.027
c-0.074,0.02-0.129,0.047-0.166,0.083l0.055,0.056H38.672z M38.838,65.093l-0.055,0.055c-0.074,0.037-0.129,0.075-0.166,0.11
l-0.11,0.11v-0.055l0.055-0.055c0.036-0.074,0.074-0.129,0.11-0.166c0.036-0.036,0.074-0.055,0.11-0.055h0.055V65.093z
 M39.445,67.3c-0.037,0.075-0.075,0.11-0.11,0.11h-0.11c-0.037,0-0.055-0.036-0.055-0.11c0-0.036,0.019-0.055,0.055-0.055
l0.055-0.055c0.036,0,0.063,0.009,0.083,0.028C39.38,67.236,39.408,67.264,39.445,67.3z M39.997,77.174l0.166-0.055
c-0.037-0.036-0.092-0.056-0.166-0.056c-0.075,0-0.157,0-0.249,0c-0.092,0-0.157,0-0.193,0c-0.075,0-0.11,0.02-0.11,0.056
c0,0.037,0.036,0.064,0.11,0.082c0.073,0.02,0.166,0.027,0.276,0.027C39.904,77.229,39.959,77.211,39.997,77.174z M39.776,67.355
c0-0.036,0.018-0.083,0.055-0.138c0.036-0.055,0.083-0.11,0.138-0.166s0.11-0.101,0.166-0.138c0.055-0.036,0.101-0.055,0.138-0.055
l-0.055,0.055l-0.165,0.331c-0.075,0.075-0.13,0.11-0.166,0.11H39.776z M40.548,65.866c-0.037,0.037-0.074,0.092-0.11,0.166
c-0.074,0.037-0.129,0.055-0.166,0.055c0.036-0.036,0.074-0.083,0.11-0.138c0.036-0.055,0.074-0.101,0.11-0.138
c0.036-0.036,0.074-0.055,0.11-0.055h0.055L40.548,65.866z M40.713,66.417c-0.11,0.074-0.193,0.138-0.248,0.193
s-0.102,0.083-0.138,0.083c0-0.036,0.028-0.091,0.083-0.166c0.055-0.073,0.119-0.146,0.193-0.22
c0.074-0.074,0.146-0.138,0.221-0.193c0.074-0.055,0.129-0.083,0.166-0.083l-0.055,0.055
C40.897,66.161,40.824,66.271,40.713,66.417z M42.258,78.608c0.146,0,0.267-0.009,0.358-0.028c0.091-0.018,0.156-0.045,0.193-0.082
l0.055-0.055c0-0.037-0.055-0.074-0.166-0.111c-0.551-0.035-0.993-0.055-1.324-0.055c-0.588,0-0.902,0.055-0.938,0.166
c0,0.146,0.276,0.221,0.828,0.221C41.521,78.664,41.853,78.645,42.258,78.608z M41.32,62.611c-0.037,0-0.083,0.009-0.138,0.028
c-0.055,0.019-0.12,0.047-0.193,0.083c-0.074,0.037-0.129,0.055-0.166,0.055h-0.055l0.055-0.055
c0.036-0.036,0.091-0.073,0.166-0.11c0.074-0.036,0.146-0.073,0.221-0.11h0.11l0.055,0.055L41.32,62.611z M41.21,77.505
c-0.185,0.037-0.295,0.056-0.331,0.056l-0.055,0.055h0.055c0.036,0,0.101,0,0.193,0c0.091,0,0.193,0,0.303,0
s0.221-0.018,0.331-0.055h0.166c0-0.036-0.047-0.056-0.138-0.056C41.642,77.505,41.466,77.505,41.21,77.505z M40.989,76.347
c-0.037,0-0.064,0-0.083,0c-0.02,0-0.028,0.019-0.028,0.056c0,0.036,0.019,0.074,0.055,0.109h0.055c0.074,0,0.11-0.018,0.11-0.055
C41.1,76.421,41.063,76.383,40.989,76.347z M41.32,65.59c0.074-0.11,0.166-0.229,0.276-0.358c0.11-0.129,0.221-0.249,0.331-0.359
c0.073-0.036,0.146-0.055,0.221-0.055l-0.055,0.055c-0.037,0.075-0.102,0.157-0.193,0.249c-0.092,0.092-0.185,0.185-0.276,0.276
c-0.092,0.092-0.174,0.157-0.248,0.193H41.32z M42.203,64.045c-0.037,0.037-0.075,0.064-0.11,0.083
c-0.037,0.02-0.075,0.028-0.11,0.028c0-0.074,0.008-0.129,0.027-0.166c0.019-0.036,0.046-0.074,0.083-0.11
c0.036-0.036,0.073-0.055,0.11-0.055c0.036,0,0.055,0.019,0.055,0.055C42.258,63.917,42.239,63.972,42.203,64.045z M42.313,77.34
c-0.185,0.074-0.295,0.129-0.331,0.165v0.056h0.166c0.036,0,0.083,0,0.138,0s0.129-0.009,0.221-0.027
c0.091-0.02,0.156-0.047,0.193-0.084l0.055-0.055h-0.055C42.625,77.359,42.497,77.34,42.313,77.34z M42.368,61.342
c-0.074,0.037-0.129,0.055-0.165,0.055v-0.055c0-0.036,0.008-0.083,0.027-0.138c0.019-0.055,0.055-0.11,0.11-0.165
s0.119-0.083,0.193-0.083c0.036,0,0.055,0.037,0.055,0.11c0,0.075-0.019,0.13-0.055,0.166
C42.497,61.269,42.442,61.306,42.368,61.342z M42.865,63.99c-0.295,0.331-0.478,0.533-0.551,0.606
c-0.037,0.037-0.075,0.055-0.11,0.055c0-0.036,0.008-0.073,0.027-0.11c0.019-0.036,0.091-0.128,0.221-0.275
c0.129-0.146,0.267-0.304,0.414-0.469c0.146-0.166,0.284-0.303,0.414-0.414c0.128-0.11,0.211-0.166,0.248-0.166l-0.166,0.221
C43.214,63.623,43.049,63.806,42.865,63.99z M42.865,60.68l-0.11,0.055h-0.055l0.055-0.055c0.036-0.036,0.074-0.073,0.11-0.11
c0.036-0.036,0.063-0.073,0.083-0.11c0.018-0.036,0.046-0.055,0.083-0.055h0.055C43.085,60.441,43.011,60.534,42.865,60.68z
 M42.865,63.107l0.331-0.276c0,0.037-0.037,0.11-0.11,0.221v0.055c0,0.075-0.027,0.13-0.083,0.166
c-0.055,0.037-0.12,0.055-0.193,0.055c-0.037,0-0.055-0.019-0.055-0.055C42.754,63.237,42.791,63.182,42.865,63.107z M44.409,62.39
c-0.037,0.037-0.064,0.047-0.083,0.028c-0.019-0.019-0.027-0.046-0.027-0.083c0-0.036,0.008-0.063,0.027-0.083
c0.019-0.019,0.046-0.027,0.083-0.027c0.074,0,0.11,0.019,0.11,0.055C44.52,62.317,44.483,62.354,44.409,62.39z M44.795,61.784
l0.552-0.552v0.055l-0.055,0.055c-0.037,0.074-0.083,0.138-0.138,0.193s-0.11,0.11-0.166,0.166S44.869,61.784,44.795,61.784z
 M45.513,76.844c0.074-0.037,0.11-0.074,0.11-0.111c0-0.035-0.02-0.055-0.055-0.055c-0.037,0-0.11,0.02-0.221,0.055
c-0.295,0.037-0.478,0.092-0.552,0.166c0,0.037,0.055,0.055,0.166,0.055S45.255,76.918,45.513,76.844z M45.016,58.474
c-0.074,0.075-0.129,0.11-0.166,0.11c0-0.036,0.019-0.083,0.055-0.138c0.036-0.055,0.074-0.101,0.11-0.138l0.11-0.11v0.055
C45.126,58.29,45.09,58.363,45.016,58.474z M45.182,75.078c0-0.036,0.028-0.055,0.083-0.055s0.101,0,0.138,0h0.055
c0.036,0,0.063,0,0.083,0c0.019,0,0.046-0.018,0.083-0.055c0-0.073,0.019-0.11,0.055-0.11c0.036,0,0.074,0.009,0.11,0.027
c0.036,0.02,0.055,0.064,0.055,0.138c0,0.037-0.037,0.055-0.11,0.055c-0.037,0-0.064,0.02-0.083,0.055
c-0.019,0.037-0.027,0.092-0.027,0.166l-0.055,0.055v0.055l-0.055-0.055c0-0.036-0.02-0.063-0.055-0.083
c-0.037-0.019-0.092-0.028-0.166-0.028c-0.037-0.036-0.074-0.055-0.11-0.055V75.078z M46.12,74.857l-0.055,0.055l-0.055-0.11
c0.036-0.074,0.083-0.129,0.138-0.166c0.055-0.036,0.101-0.055,0.138-0.055l0.055,0.055v0.055
C46.303,74.729,46.23,74.784,46.12,74.857z M46.175,59.632c0.073-0.146,0.166-0.267,0.276-0.359
c0.11-0.091,0.201-0.138,0.275-0.138c0,0.037-0.037,0.11-0.11,0.221c-0.037,0.037-0.074,0.083-0.11,0.138
c-0.037,0.055-0.092,0.102-0.166,0.138c-0.075,0.037-0.13,0.074-0.166,0.11l-0.055,0.055C46.12,59.762,46.138,59.707,46.175,59.632
z M46.451,74.913c0.036-0.036,0.063-0.063,0.083-0.083c0.019-0.019,0.046-0.027,0.083-0.027c0.074,0,0.138-0.019,0.193-0.055
c0.055-0.036,0.101-0.091,0.138-0.166c0.036-0.036,0.074-0.063,0.11-0.083c0.036-0.018,0.063-0.027,0.083-0.027
c0.018,0,0.027,0.019,0.027,0.055h0.055l0.055-0.055v-0.055l0.166-0.055v0.055l-0.11,0.11c-0.11,0.074-0.212,0.147-0.303,0.221
c-0.092,0.074-0.193,0.129-0.304,0.166H46.451z M46.781,76.291c0.11-0.035,0.284-0.109,0.524-0.221
c0.239-0.11,0.469-0.22,0.689-0.331s0.367-0.184,0.441-0.221c0-0.036-0.037-0.055-0.11-0.055c-0.075,0-0.185,0.02-0.331,0.055
c-0.037,0-0.092,0.028-0.166,0.083c-0.075,0.055-0.185,0.102-0.331,0.138c-0.185,0.11-0.378,0.212-0.579,0.303
c-0.202,0.092-0.34,0.175-0.414,0.248H46.781z M46.947,74.471c0,0.037-0.009,0.064-0.027,0.083c-0.02,0.02-0.047,0.02-0.083,0
c-0.037-0.018-0.074-0.046-0.11-0.083v-0.055c0-0.036,0.019-0.055,0.055-0.055c0.036,0,0.074,0.009,0.11,0.027
C46.928,74.408,46.947,74.435,46.947,74.471z M47.167,76.898c-0.037,0-0.064,0.009-0.083,0.027c-0.02,0.02-0.028,0.047-0.028,0.082
v0.056c0.036,0,0.063,0,0.083,0c0.018,0,0.046-0.008,0.083-0.027c0.036-0.019,0.055-0.046,0.055-0.083
C47.278,76.918,47.241,76.898,47.167,76.898z M47.609,77.449h0.055l-0.055,0.056c-0.037,0-0.075,0.02-0.11,0.056
c-0.147,0.037-0.24,0.055-0.276,0.055c0-0.035,0.028-0.073,0.083-0.11c0.055-0.036,0.119-0.056,0.193-0.056l0.055-0.055
L47.609,77.449z M47.388,56.212c-0.037-0.036-0.055-0.073-0.055-0.11c0-0.036,0.008-0.063,0.028-0.083
c0.018-0.019,0.036-0.036,0.055-0.055c0.018-0.019,0.036-0.019,0.055,0c0.018,0.02,0.027,0.064,0.027,0.138
c0,0.037-0.02,0.075-0.055,0.11H47.388z M47.499,74.14c0-0.036,0.074-0.119,0.221-0.249c0.146-0.128,0.331-0.267,0.552-0.414
c0.221-0.146,0.432-0.267,0.634-0.358c0.201-0.091,0.339-0.138,0.414-0.138h0.055c0,0.037-0.055,0.092-0.166,0.166
c-0.074,0.075-0.166,0.157-0.276,0.249c-0.11,0.092-0.202,0.174-0.276,0.248c-0.074,0.075-0.11,0.13-0.11,0.166
c-0.075,0-0.203,0.055-0.386,0.166l-0.331,0.11c-0.075,0.037-0.147,0.055-0.221,0.055H47.499z M48.271,58.363l-0.055,0.055
c-0.037,0.075-0.092,0.147-0.166,0.221c-0.075,0.075-0.147,0.138-0.221,0.193c-0.075,0.055-0.13,0.102-0.166,0.138h-0.055
c0-0.036,0.036-0.091,0.11-0.166c0.074-0.074,0.156-0.146,0.248-0.221c0.091-0.073,0.174-0.128,0.249-0.166L48.271,58.363z
 M49.043,77.008c0.11,0.037,0.166,0.075,0.166,0.111c-0.037,0.037-0.129,0.092-0.276,0.165c-0.147,0.075-0.323,0.147-0.524,0.221
c-0.202,0.075-0.378,0.138-0.524,0.193c-0.147,0.056-0.24,0.083-0.276,0.083c0-0.036,0.055-0.092,0.166-0.166
c0.11-0.073,0.248-0.156,0.414-0.248s0.331-0.174,0.497-0.248S48.969,77.008,49.043,77.008z M47.885,55.771
c-0.11,0-0.166-0.019-0.166-0.055c0-0.036,0.019-0.074,0.055-0.11c0.036-0.036,0.091-0.055,0.166-0.055l0.11-0.055l0.055,0.055
c0.036,0,0.055,0.019,0.055,0.055l-0.055,0.11H48.05C47.976,55.716,47.92,55.735,47.885,55.771z M48.216,76.512l0.166-0.055
c0,0.037-0.037,0.092-0.11,0.166h-0.166v-0.056L48.216,76.512z M48.767,76.347c-0.037,0.036-0.074,0.056-0.11,0.056
c-0.037,0-0.055-0.02-0.055-0.056h-0.055c0-0.036,0.018-0.056,0.055-0.056c0.036,0,0.073-0.008,0.11-0.027
c0.036-0.018,0.063-0.046,0.083-0.082c0.019-0.037,0.027-0.074,0.027-0.111c0-0.035,0.019-0.073,0.055-0.11v0.11
c0,0.074-0.009,0.139-0.027,0.193C48.831,76.318,48.803,76.347,48.767,76.347z M48.712,75.298l0.11-0.055
c0.036,0,0.074-0.018,0.11-0.055c0.036-0.036,0.074-0.063,0.11-0.083c0.036-0.019,0.055-0.046,0.055-0.083v-0.055
c-0.037,0.037-0.11,0.092-0.221,0.166c-0.074,0.037-0.129,0.075-0.166,0.11V75.298z M51.084,74.471c0.146,0,0.221,0.037,0.221,0.11
l-0.055,0.055c-0.037,0.074-0.11,0.166-0.221,0.276c-0.11,0.11-0.24,0.22-0.386,0.331c-0.185,0.075-0.368,0.185-0.552,0.331
c-0.185,0.185-0.386,0.331-0.607,0.441c-0.11,0.074-0.202,0.11-0.276,0.11c-0.074,0-0.147-0.019-0.221-0.056
c0-0.073,0.036-0.128,0.11-0.165c0.074-0.036,0.146-0.074,0.221-0.11c0.11-0.036,0.202-0.074,0.276-0.11
c0.074-0.036,0.11-0.091,0.11-0.166l-0.055-0.055c0-0.184,0.055-0.294,0.166-0.331c0.036-0.036,0.091-0.055,0.166-0.055
c0.036,0,0.091,0.02,0.166,0.055c0.184,0,0.358-0.036,0.524-0.11C50.836,74.949,50.974,74.766,51.084,74.471z M52.022,52.406
c-0.993,0.662-1.986,1.471-2.979,2.427v-0.055c0-0.294,0.395-0.772,1.186-1.434c0.791-0.662,1.773-1.379,2.951-2.151
c1.177-0.772,2.28-1.397,3.31-1.875c0.772-0.367,1.324-0.552,1.655-0.552c0.11,0,0.184,0.019,0.221,0.055
c-0.736,0.257-1.719,0.717-2.951,1.379C54.182,50.861,53.051,51.598,52.022,52.406z M49.871,56.322
c-0.185,0.11-0.295,0.166-0.331,0.166v-0.055c0-0.036,0.036-0.091,0.11-0.166c0.074-0.073,0.146-0.138,0.221-0.193
c0.074-0.055,0.129-0.101,0.166-0.138h0.11l-0.055,0.055C50.054,56.066,49.981,56.176,49.871,56.322z M50.036,72.651
c-0.075,0.074-0.147,0.11-0.221,0.11c-0.075,0-0.11-0.019-0.11-0.055c0-0.036,0.055-0.11,0.166-0.221
c0.146-0.074,0.256-0.11,0.331-0.11l0.055,0.055C50.257,72.504,50.182,72.577,50.036,72.651z M50.312,74.25
c0.256-0.146,0.441-0.257,0.551-0.331c0.074-0.036,0.11-0.074,0.11-0.11c0-0.036-0.055-0.055-0.166-0.055l-0.11,0.055
c-0.075,0.037-0.157,0.092-0.249,0.166c-0.092,0.074-0.175,0.147-0.248,0.221c-0.037,0.037-0.055,0.074-0.055,0.11L50.312,74.25z
 M50.643,72.099v0.055c-0.037,0.037-0.092,0.055-0.166,0.055l-0.11,0.055c0-0.036,0-0.063,0-0.083c0-0.018,0.018-0.036,0.055-0.055
c0.036-0.018,0.073-0.036,0.11-0.055C50.568,72.053,50.606,72.063,50.643,72.099z M53.346,70.113
c-0.074,0.074-0.138,0.129-0.193,0.166c-0.055,0.037-0.102,0.074-0.138,0.11c-0.037,0.037-0.074,0.074-0.11,0.11
c-0.037,0.037-0.11,0.074-0.22,0.11l0.055,0.055h0.055l0.055-0.055h0.055l-0.11,0.11c-0.147,0.075-0.258,0.13-0.331,0.166
c-0.075,0.037-0.13,0.075-0.166,0.11l-0.055,0.055v0.055c0,0.037,0,0.064,0,0.083c0,0.019-0.009,0.037-0.028,0.055
c-0.019,0.019-0.064,0.037-0.138,0.055c-0.075,0.019-0.166,0.047-0.276,0.083c-0.037,0.075-0.11,0.138-0.221,0.193
s-0.202,0.092-0.276,0.11c-0.074,0.019-0.147,0.027-0.221,0.027c0-0.036,0.008-0.063,0.028-0.083
c0.018-0.019,0.119-0.083,0.303-0.193c0.11-0.073,0.339-0.256,0.689-0.552c0.35-0.294,0.726-0.625,1.131-0.993
c0.367-0.367,0.717-0.698,1.048-0.993c0.331-0.294,0.607-0.496,0.827-0.606c0.146-0.074,0.239-0.11,0.276-0.11
c0.036,0,0.055,0.019,0.055,0.055c-0.295,0.295-0.662,0.626-1.103,0.993C53.971,69.562,53.64,69.856,53.346,70.113z M51.36,74.25
c0-0.036,0.008-0.063,0.028-0.083c0.018-0.018,0.046-0.027,0.083-0.027l0.11-0.055l0.055,0.055h0.055v0.055
c-0.037,0.037-0.092,0.074-0.166,0.11H51.47C51.434,74.306,51.396,74.287,51.36,74.25z M52.684,73.202
c0.256-0.11,0.422-0.166,0.496-0.166l0.055,0.055c0,0.037-0.064,0.13-0.193,0.276c-0.13,0.147-0.285,0.295-0.469,0.441
c-0.185,0.147-0.351,0.257-0.497,0.331l-0.11,0.055c-0.075,0-0.13-0.055-0.166-0.166v-0.055c0-0.036,0.036-0.091,0.11-0.166
c0.074-0.074,0.184-0.166,0.331-0.276c0.11-0.036,0.211-0.091,0.303-0.166C52.637,73.294,52.684,73.239,52.684,73.202z
 M52.408,72.706l0.11-0.11c-0.037,0-0.092,0.019-0.166,0.055c-0.075,0.037-0.157,0.092-0.249,0.165
c-0.092,0.075-0.157,0.13-0.193,0.166l-0.055,0.11h0.055c0.036,0,0.091-0.027,0.166-0.083c0.073-0.055,0.138-0.101,0.193-0.138
C52.325,72.835,52.371,72.78,52.408,72.706z M59.579,50.089c0.11-0.036,0.156-0.055,0.138-0.055c-0.02,0-0.064,0-0.138,0
c-0.074,0-0.157,0.009-0.248,0.028c-0.092,0.019-0.175,0.047-0.248,0.083c-0.772,0.221-1.49,0.571-2.151,1.048
c-0.662,0.441-1.214,0.681-1.655,0.717l0.166-0.166c0.074-0.073,0.166-0.146,0.276-0.22c0.184-0.11,0.276-0.202,0.276-0.276
l-0.221,0.11c-0.147,0.074-0.295,0.166-0.441,0.276c-0.147,0.11-0.276,0.202-0.386,0.275c-0.11,0.075-0.166,0.147-0.166,0.221
l0.166-0.11c0.11-0.036,0.184-0.055,0.22-0.055h0.055l-0.055,0.11c-0.037,0.037-0.138,0.13-0.303,0.276
c-0.166,0.147-0.35,0.295-0.552,0.441c-0.202,0.147-0.378,0.257-0.524,0.331h-0.055c-0.037,0-0.055-0.019-0.055-0.055v-0.055
l0.055-0.11c0-0.036-0.019-0.055-0.055-0.055c-0.074,0-0.166,0.047-0.276,0.138c-0.11,0.092-0.229,0.185-0.358,0.276
c-0.13,0.092-0.248,0.193-0.358,0.304l-0.11,0.11h-0.055l-0.11,0.22c0.073-0.036,0.146-0.055,0.221-0.055h0.166l0.165-0.055h0.055
v0.055c-0.037,0.037-0.092,0.075-0.165,0.11c-0.185,0.11-0.313,0.166-0.386,0.166h-0.166v0.055c0,0.037,0.036,0.055,0.11,0.055
c0.11,0,0.404-0.146,0.882-0.441c0.478-0.294,1.139-0.717,1.986-1.269c0.883-0.625,1.746-1.186,2.593-1.683
C58.715,50.337,59.284,50.089,59.579,50.089z M52.463,74.25c0.073,0,0.128,0,0.166,0c0.036,0,0.063,0.009,0.083,0.027
c0.019,0.02,0.019,0.037,0,0.055c-0.019,0.02-0.064,0.028-0.138,0.028c-0.075,0-0.11-0.019-0.11-0.055V74.25z M53.015,74.03h-0.055
c0-0.036,0.019-0.055,0.055-0.055c0.036,0,0.074-0.019,0.11-0.055h0.055C53.144,73.956,53.088,73.994,53.015,74.03z M52.959,74.416
c0.036-0.036,0.091-0.055,0.166-0.055c0.036,0,0.055,0.019,0.055,0.055h0.055v0.055h-0.276V74.416z M53.235,72.044
c0.074,0,0.119-0.019,0.138-0.055c0.018-0.036,0.027-0.074,0.027-0.11c0-0.036-0.019-0.055-0.055-0.055h-0.055
c-0.037,0.037-0.064,0.074-0.083,0.11c-0.02,0.037-0.028,0.064-0.028,0.083C53.18,72.036,53.199,72.044,53.235,72.044z
 M53.677,72.43l0.166-0.055v0.055l0.055,0.055c0,0.037-0.027,0.102-0.083,0.193c-0.055,0.092-0.12,0.193-0.193,0.303
c-0.074,0.075-0.129,0.11-0.166,0.11c-0.037,0-0.064-0.008-0.083-0.027c-0.02-0.019-0.028-0.046-0.028-0.083
c0-0.036,0-0.063,0-0.083c0-0.019-0.009-0.036-0.027-0.055c-0.02-0.019-0.047-0.028-0.083-0.028c0-0.036,0.028-0.073,0.083-0.11
c0.055-0.036,0.119-0.074,0.193-0.11L53.677,72.43z M53.346,74.085c0.036-0.036,0.074-0.055,0.11-0.055l0.055-0.055
c0.036,0,0.055,0.019,0.055,0.055c0,0.037-0.019,0.074-0.055,0.11c0,0.037-0.019,0.055-0.055,0.055l-0.11,0.055
c-0.037,0-0.055-0.019-0.055-0.055C53.291,74.159,53.309,74.122,53.346,74.085z M53.842,71.437c0.036,0,0.119-0.046,0.248-0.138
c0.129-0.091,0.257-0.202,0.386-0.331c0.129-0.129,0.239-0.249,0.331-0.358c0.091-0.11,0.138-0.184,0.138-0.221
c-0.037,0-0.13,0.037-0.276,0.11c-0.037,0.037-0.147,0.11-0.331,0.22c-0.11,0.11-0.23,0.212-0.359,0.304
c-0.129,0.092-0.212,0.175-0.248,0.248v0.055C53.732,71.364,53.768,71.401,53.842,71.437z M54.173,52.461
c-0.11,0.075-0.193,0.138-0.249,0.193c-0.055,0.055-0.083,0.102-0.083,0.138h0.055c0.036,0,0.074-0.008,0.11-0.028
c0.036-0.018,0.091-0.055,0.166-0.11c0.074-0.055,0.138-0.11,0.193-0.166s0.091-0.101,0.11-0.138
c0.019-0.036,0.008-0.055-0.027-0.055C54.375,52.295,54.284,52.351,54.173,52.461z M54.008,72.32c0-0.146,0.055-0.276,0.166-0.386
s0.229-0.201,0.358-0.276c0.129-0.074,0.249-0.11,0.359-0.11c0.036,0,0.055,0.02,0.055,0.055l-0.055,0.11
c-0.037,0.075-0.185,0.221-0.441,0.441c-0.185,0.147-0.295,0.221-0.331,0.221h-0.055C54.026,72.375,54.008,72.356,54.008,72.32z
 M54.67,73.037c0.11,0,0.156,0.02,0.138,0.055c-0.019,0.037-0.064,0.075-0.138,0.11h-0.055c-0.037,0-0.055-0.018-0.055-0.055
c0-0.036,0.008-0.063,0.027-0.083C54.605,73.046,54.633,73.037,54.67,73.037z M55.773,71.878h0.055l0.055,0.055
c0.036,0.037,0.063,0.092,0.083,0.166c0.018,0.074,0.008,0.11-0.028,0.11h-0.055l-0.055-0.055c-0.074-0.036-0.11-0.091-0.11-0.166
C55.718,71.953,55.736,71.915,55.773,71.878z M55.883,67.41c0.074-0.074,0.166-0.129,0.276-0.166l0.11-0.055l0.055,0.11
c0,0.037-0.055,0.092-0.166,0.166c-0.185,0.147-0.313,0.221-0.386,0.221v-0.055C55.773,67.595,55.809,67.521,55.883,67.41z
 M56.27,71.768c0.074-0.11,0.128-0.166,0.166-0.166l0.055-0.055l0.055,0.055l-0.221,0.221l-0.166,0.11
C56.159,71.897,56.195,71.842,56.27,71.768z M56.656,50.806l-0.331,0.166c-0.075,0.037-0.11,0.074-0.11,0.11v0.055
c0.036,0,0.091-0.019,0.166-0.055c0.11-0.036,0.229-0.091,0.358-0.166c0.129-0.074,0.211-0.146,0.249-0.221l0.055-0.055
C57.005,50.641,56.876,50.696,56.656,50.806z M56.766,70.5c0.073,0,0.11,0.019,0.11,0.055c0,0.037-0.037,0.074-0.11,0.11h-0.055
c-0.037,0-0.055-0.019-0.055-0.055c0-0.036,0.008-0.063,0.027-0.083C56.702,70.508,56.729,70.5,56.766,70.5z M57.097,66.417
c0.074,0.037,0.119,0.074,0.138,0.11c0.019,0.037-0.028,0.055-0.138,0.055c-0.037,0-0.064-0.008-0.083-0.027
c-0.02-0.019-0.027-0.046-0.027-0.083c0-0.036,0.018-0.055,0.055-0.055l0.055-0.055V66.417z M58.2,67.962
c0.146-0.184,0.239-0.312,0.276-0.386l-0.11,0.055c-0.037,0.037-0.11,0.12-0.221,0.248c-0.11,0.13-0.221,0.258-0.331,0.386
c-0.11,0.13-0.202,0.24-0.276,0.331c-0.074,0.092-0.11,0.157-0.11,0.193c0.074-0.036,0.156-0.101,0.249-0.193
c0.091-0.091,0.174-0.193,0.248-0.303C58.035,68.146,58.125,68.036,58.2,67.962z M57.924,65.7c-0.147,0.185-0.24,0.313-0.276,0.386
h-0.055c-0.037,0-0.055-0.019-0.055-0.055c0-0.036,0.028-0.101,0.083-0.193c0.055-0.091,0.11-0.193,0.166-0.303
s0.119-0.201,0.193-0.276c0.074-0.074,0.129-0.11,0.166-0.11c0.074,0,0.11,0.02,0.11,0.055
C58.255,65.278,58.145,65.444,57.924,65.7z M58.255,49.096c0-0.074,0.027-0.101,0.083-0.083c0.055,0.02,0.091,0.047,0.11,0.083
c0.019,0.037,0.028,0.074,0.028,0.11h-0.11L58.255,49.096z M59.248,48.655l-0.11-0.055c0-0.036,0.036-0.055,0.11-0.055
c0.074,0,0.157,0,0.249,0c0.091,0,0.166,0.009,0.221,0.027c0.055,0.02,0.083,0.047,0.083,0.083c-0.037,0.074-0.092,0.11-0.166,0.11
c-0.11,0-0.202-0.019-0.276-0.055C59.322,48.674,59.284,48.655,59.248,48.655z M61.179,65.976h0.11c0.074,0,0.129,0.037,0.166,0.11
l0.055,0.11c0,0.074-0.027,0.157-0.083,0.248c-0.055,0.092-0.157,0.175-0.303,0.248c-0.075,0.075-0.185,0.147-0.331,0.221
c-0.11,0.037-0.212,0.083-0.304,0.138c-0.092,0.055-0.138,0.102-0.138,0.138v0.055c0,0.037,0.036,0.055,0.11,0.055
c0.074,0,0.11,0.02,0.11,0.055c0,0.037-0.028,0.092-0.083,0.166c-0.055,0.075-0.129,0.175-0.221,0.303
c-0.092,0.13-0.193,0.24-0.303,0.331c-0.11,0.092-0.185,0.138-0.221,0.138c-0.147,0-0.24-0.019-0.276-0.055
c0-0.036,0.008-0.055,0.028-0.055c0.019,0,0.063-0.019,0.138-0.055c0.11-0.074,0.202-0.146,0.276-0.221
c0.11-0.074,0.166-0.146,0.166-0.221l-0.055-0.166c0-0.11,0.063-0.229,0.193-0.358c0.128-0.129,0.284-0.284,0.469-0.469
c0.146-0.11,0.276-0.239,0.386-0.386c0.11-0.074,0.166-0.166,0.166-0.276L61.179,65.976z M62.834,63.935
c0-0.074,0.036-0.11,0.11-0.11L63,63.77c0.036,0,0.055,0.037,0.055,0.11c0,0.037-0.02,0.092-0.055,0.166
c-0.075,0.147-0.185,0.303-0.331,0.469c-0.147,0.166-0.313,0.313-0.497,0.441c-0.185,0.129-0.331,0.212-0.441,0.248
c-0.074,0-0.11-0.036-0.11-0.11v-0.055c0-0.036,0.028-0.091,0.083-0.166c0.055-0.073,0.128-0.146,0.221-0.221
c0.091-0.073,0.174-0.128,0.248-0.166l0.11-0.055l0.166,0.11l0.055-0.055c0.036,0,0.083-0.046,0.138-0.138
c0.055-0.091,0.101-0.174,0.138-0.248C62.814,64.027,62.834,63.972,62.834,63.935z M62.448,64.211
c-0.075,0.074-0.12,0.102-0.138,0.083c-0.019-0.019-0.019-0.046,0-0.083c0.019-0.036,0.046-0.074,0.083-0.11
c0.036-0.036,0.074-0.063,0.11-0.083c0.036-0.018,0.074-0.008,0.11,0.028c0,0.037-0.02,0.074-0.055,0.11
C62.521,64.192,62.483,64.211,62.448,64.211z M63.331,60.349c-0.037,0-0.064,0.028-0.083,0.083
c-0.019,0.055-0.027,0.102-0.027,0.138v0.055l0.11-0.11c0.073-0.11,0.11-0.184,0.11-0.221L63.331,60.349z M63.992,62.5
c-0.221,0.368-0.368,0.588-0.441,0.662l-0.055,0.055c-0.037,0-0.055-0.019-0.055-0.055v-0.221c0-0.073,0.008-0.184,0.028-0.331
c0.019-0.146,0.063-0.312,0.138-0.496c0.036-0.257,0.074-0.441,0.11-0.552c0,0.074,0,0.129,0,0.166
c0,0.037,0.008,0.064,0.028,0.083c0.019,0.02,0.046,0.028,0.083,0.028c0.036,0,0.055-0.008,0.055-0.028c0-0.018,0-0.018,0,0
c0,0.02-0.009,0.047-0.027,0.083c-0.02,0.037-0.037,0.092-0.055,0.166c-0.02,0.074-0.064,0.166-0.138,0.275l0.055,0.055
c0.036,0,0.063,0,0.083,0c0.018,0,0.046-0.036,0.083-0.11c0.036-0.073,0.074-0.146,0.11-0.22c0.074-0.11,0.129-0.202,0.166-0.276
c0.036-0.074,0.074-0.11,0.11-0.11h0.055c0.074,0,0.11,0.019,0.11,0.055c0,0.037-0.027,0.102-0.083,0.193
C64.296,62.013,64.176,62.207,63.992,62.5z" mask="url('#writing')"/>
  <path class="line2" d="M59.745,92.068c-0.294,0-0.57-0.111-0.827-0.331c-0.257-0.221-0.46-0.441-0.607-0.662
c-0.772-0.919-1.159-1.894-1.159-2.924l0.055-0.552c0.368-1.397,1.104-2.941,2.207-4.633c1.104-1.691,2.299-3.393,3.586-5.104
c1.287-1.71,2.353-3.245,3.199-4.606c0.221-0.404,0.477-0.606,0.772-0.606c0.331,0,0.662,0.257,0.993,0.772
c0.405,0.662,0.607,1.25,0.607,1.765c0,0.441-0.717,1.435-2.151,2.979c-0.919,1.067-1.619,1.931-2.096,2.593l-0.883,1.324
c-0.993,1.36-1.949,2.813-2.868,4.357c0.185,0,0.276-0.027,0.276-0.082c0-0.056,0.019-0.102,0.055-0.139
c2.28-3.936,4.725-6.932,7.336-8.992c1.949-1.544,3.953-2.352,6.013-2.426c0.918,0,1.691,0.257,2.317,0.772l0.883,0.717
c0.404,0.406,0.643,0.865,0.717,1.379l0.055,0.166l-0.22,0.387c-0.11-0.037-0.258-0.146-0.441-0.332v-0.055
c-0.185-0.256-0.331-0.532-0.441-0.828c-0.257-0.514-0.883-0.771-1.875-0.771c-0.847,0-1.747,0.221-2.703,0.662
c-2.243,1.178-4.339,2.96-6.289,5.351c-1.691,1.985-3.181,4.046-4.468,6.179l-1.6,2.758c0,0.294-0.036,0.441-0.11,0.441
c0,0.073-0.019,0.129-0.055,0.165C59.985,91.977,59.892,92.068,59.745,92.068z M58.697,88.262c0.809-1.839,1.747-3.621,2.813-5.351
c0.037-0.036,0.064-0.073,0.083-0.11c0.019-0.036,0.047-0.074,0.083-0.11c0.037-0.036,0.092-0.165,0.166-0.386h-0.11l-0.441,0.717
C59.634,85.596,58.771,87.344,58.697,88.262z M58.862,85.945c0.074-0.074,0.257-0.359,0.551-0.855
c0.295-0.496,0.662-1.111,1.104-1.848c0.626-0.993,1.26-2.014,1.903-3.063c0.643-1.047,1.186-1.883,1.627-2.51
c0.146-0.109,0.276-0.238,0.386-0.386c0.11-0.146,0.193-0.257,0.248-0.331c0.055-0.073,0.083-0.129,0.083-0.165v-0.221
c0.11-0.257,0.239-0.478,0.386-0.662c0.146-0.184,0.256-0.276,0.331-0.276c0.036,0,0.055,0.028,0.055,0.083
s0.019,0.083,0.055,0.083c0.036,0,0.055-0.055,0.055-0.166v-0.055c0-0.073,0.036-0.166,0.11-0.276
c0.552-0.662,0.918-1.139,1.104-1.434l0.055-0.276l-0.11,0.11c-1.03,1.178-2.061,2.519-3.089,4.027
c-0.551,0.81-1.103,1.591-1.655,2.345s-1.213,1.867-1.986,3.337c-0.367,0.737-0.662,1.324-0.883,1.766
C58.973,85.613,58.862,85.871,58.862,85.945z M59.579,90.578l0.497-0.827c0.368-0.698,0.607-1.103,0.717-1.214h-0.055h0.055h-0.055
v-0.055h0.055l0.606-0.992c1.324-2.391,2.998-4.726,5.02-7.006c1.287-1.471,2.849-2.759,4.689-3.861l0.276-0.276
c0.256,0,0.459-0.091,0.606-0.276c-0.257,0-0.46,0.092-0.606,0.276c-0.075,0.036-0.166,0.064-0.276,0.083
c-0.11,0.02-0.221,0.064-0.331,0.138c-0.809,0.405-1.544,0.882-2.207,1.435c-1.361,1.104-2.557,2.316-3.585,3.641l-0.552,0.551
c-0.074,0.258-0.257,0.479-0.552,0.662c-0.074,0.295-0.24,0.533-0.496,0.717c-0.075,0.258-0.258,0.479-0.552,0.662l0.221-0.496
c-0.516,0.406-0.863,0.883-1.048,1.435c0.075,0,0.138-0.019,0.193-0.056c0.055-0.035,0.118-0.055,0.193-0.055
c-0.441,0.588-0.735,1.048-0.883,1.379v-0.387c-0.367,0.332-0.587,0.718-0.662,1.159h0.166c-0.074,0.185-0.146,0.295-0.221,0.331
V87.27c-0.11,0.109-0.276,0.385-0.497,0.826s-0.405,0.865-0.552,1.27c-0.146,0.405-0.221,0.736-0.221,0.992L59.579,90.578z
 M60.241,87.766l0.221-1.104C60.352,86.92,60.278,87.288,60.241,87.766z M61.51,86c0.075-0.035,0.12-0.073,0.138-0.11
c0.019-0.036,0.027-0.073,0.027-0.11c-0.073,0-0.119,0.027-0.138,0.083C61.519,85.918,61.51,85.965,61.51,86z M61.675,85.725
c0.075,0,0.12-0.027,0.138-0.083c0.019-0.056,0.027-0.101,0.027-0.138c-0.073,0-0.119,0.027-0.138,0.082
C61.685,85.642,61.675,85.688,61.675,85.725z M61.841,85.449c0.11-0.037,0.166-0.111,0.166-0.221L61.841,85.449z M62.834,80.814
L63,80.76c0.367-0.331,0.643-0.67,0.827-1.021c0.184-0.35,0.395-0.681,0.634-0.993c0.239-0.312,0.359-0.523,0.359-0.635
c-0.037,0-0.12,0.056-0.249,0.166c-0.129,0.11-0.285,0.295-0.469,0.552l0.055,0.056c0,0.036-0.074,0.055-0.221,0.055
c-0.147,0-0.221,0.055-0.221,0.166c0.074,0.036,0.11,0.074,0.11,0.109c0,0.258-0.221,0.607-0.662,1.049
C62.944,80.559,62.834,80.741,62.834,80.814z M63.055,83.684l0.331-0.111c0.073-0.293,0.239-0.531,0.496-0.717
c0.074-0.256,0.239-0.477,0.497-0.662l0.166-0.221c0.11-0.221,0.662-0.882,1.655-1.985l1.104-1.323
c-0.295,0.109-0.516,0.275-0.662,0.496l-0.11,0.109c-1.25,1.289-2.354,2.648-3.31,4.082c-0.075,0.075-0.12,0.139-0.138,0.193
C63.063,83.601,63.055,83.647,63.055,83.684z M64.93,77.836c1.139-1.139,1.71-1.911,1.71-2.317v-0.055
c-0.11,0-0.386,0.295-0.828,0.883c-0.441,0.589-0.699,0.919-0.772,0.993l-0.166,0.386C64.875,77.801,64.893,77.836,64.93,77.836z
 M65.041,78.885l0.331-0.387c0.11-0.11,0.193-0.221,0.248-0.331s0.101-0.185,0.138-0.221c0-0.036-0.019-0.056-0.055-0.056
c-0.037,0-0.083,0.047-0.138,0.139s-0.138,0.212-0.248,0.358L65.041,78.885z M65.813,77.67c0.146,0,0.386-0.293,0.717-0.882
l0.331-0.497c-0.075,0-0.24,0.166-0.497,0.497c-0.257,0.331-0.441,0.606-0.552,0.827V77.67z M67.357,78.608l1.213-0.993
l-0.276,0.166C67.89,78.002,67.578,78.277,67.357,78.608z M72.267,75.96v-0.055h0.055h0.055h-0.055L72.267,75.96z"/>
  <path class="line3" d="M74.639,91.461c-0.11,0-0.267-0.009-0.469-0.027c-0.201-0.02-0.579-0.523-1.131-1.518
c-0.074-0.109-0.146-0.184-0.221-0.221c-0.11-0.366-0.166-0.752-0.166-1.158c0-2.096,1.785-5.369,5.351-9.818
c1.066-1.359,1.894-2.501,2.482-3.42l0.552-0.717c0.294-0.441,0.497-0.662,0.607-0.662c0.441,0.185,0.827,0.533,1.158,1.048
c0.146,0.295,0.221,0.571,0.221,0.828c0,0.221-0.055,0.441-0.166,0.662c-0.257,0.588-0.975,1.654-2.151,3.199
c-1.178,1.545-1.857,2.316-2.041,2.316H78.61c-0.074,0-0.11,0.037-0.11,0.111v0.165c0,0.185-0.11,0.386-0.331,0.606
c0,0.074-0.074,0.221-0.22,0.441c-0.515,0.736-0.818,1.15-0.91,1.241c-0.091,0.093-0.45,0.745-1.076,1.958
c-0.294,0.516-0.662,1.214-1.104,2.097c0,0.075,0.037,0.11,0.11,0.11c0.295,0,1.288-0.68,2.979-2.041
c2.169-1.729,3.641-2.997,4.413-3.807c0.074-0.146,0.174-0.238,0.304-0.275c0.128-0.036,0.239-0.063,0.331-0.083
c0.091-0.019,0.156-0.046,0.193-0.083l-0.055-0.109c0-0.111,0.074-0.257,0.221-0.441c0.625-0.441,1.894-1.545,3.806-3.311
c0.331-0.33,0.607-0.551,0.828-0.662v0.056c0,0.036-0.009,0.064-0.028,0.083c-0.019,0.02-0.248,0.248-0.689,0.689
c-0.257,0.258-0.423,0.387-0.497,0.387c-0.074,0-0.174,0.109-0.303,0.33c-0.129,0.221-0.212,0.331-0.248,0.331h-0.055
c-0.258,0.185-0.599,0.517-1.021,0.993c-0.423,0.479-0.864,0.938-1.324,1.379c-0.46,0.441-0.91,0.791-1.352,1.049v0.055
c0,0.185-1.178,1.343-3.53,3.475c-0.295,0.258-0.423,0.406-0.386,0.441l0.276-0.221c0.184-0.146,0.331-0.238,0.441-0.275
c0.036,0,0.055,0.02,0.055,0.055l-0.11,0.221c0.036,0,0.239-0.146,0.607-0.441c0.956-0.771,2.077-1.765,3.365-2.979
c2.463-2.316,3.897-3.715,4.303-4.192c0.184-0.184,0.284-0.377,0.303-0.579c0.019-0.201,0.055-0.35,0.11-0.441
c0.055-0.091,0.156-0.138,0.304-0.138c0.11,0.074,0.166,0.165,0.166,0.276c0,0.404-0.221,0.919-0.662,1.544
c-0.295,0.405-0.571,0.698-0.828,0.883l-0.221,0.276c-0.662,0.516-1.747,1.508-3.254,2.979c-1.103,1.066-1.655,1.691-1.655,1.875
c0,0.037,0.018,0.055,0.055,0.055h0.055c-0.037,0.147-0.12,0.268-0.249,0.359c-0.129,0.092-0.193,0.174-0.193,0.248
c-0.037,0-0.092-0.036-0.166-0.11c-0.075-0.073-0.147-0.146-0.221-0.221c-0.074,0-0.129,0.036-0.166,0.11
c-0.037,0.074-0.055,0.13-0.055,0.166s0.019,0.074,0.055,0.109l0.055,0.056c0,0.257-0.441,0.736-1.324,1.435
c-0.626,0.441-1.03,0.791-1.214,1.048c-0.368,0.589-0.974,1.122-1.82,1.6C75.872,91.26,75.227,91.461,74.639,91.461z
 M73.591,88.703c0.847-1.766,1.784-3.475,2.813-5.131l0.331-0.661l-0.11,0.056c0.075-0.074,0.11-0.146,0.11-0.221l-0.11,0.055
l-0.441,0.662c-1.618,2.537-2.482,4.229-2.592,5.074l0.055-0.055L73.591,88.703z M73.812,86.496c0.185-0.184,1.36-2.041,3.53-5.57
c0.625-0.994,1.158-1.802,1.6-2.428c0.477-0.367,0.717-0.662,0.717-0.883c0-0.073,0.146-0.331,0.441-0.771
c0.146-0.185,0.239-0.276,0.275-0.276c0.036,0,0.055,0.019,0.055,0.056c0,0.036,0.019,0.055,0.055,0.055v-0.11
c0-0.221,0.184-0.478,0.552-0.772c0.294-0.331,0.515-0.662,0.662-0.993l0.11-0.276l-0.055,0.055l0.055-0.221h-0.055l-0.11,0.11
c-0.957,1.14-1.95,2.447-2.979,3.917c-0.551,0.81-1.122,1.581-1.71,2.317c-0.184,0.257-0.45,0.689-0.8,1.296
c-0.35,0.606-0.717,1.25-1.104,1.931c-0.386,0.682-0.689,1.232-0.91,1.654c-0.22,0.424-0.331,0.672-0.331,0.745L74.032,86
L73.812,86.496z M77.728,81.477l0.166-0.055c0.331-0.295,0.587-0.615,0.772-0.966c0.184-0.349,0.395-0.681,0.634-0.993
c0.239-0.311,0.359-0.504,0.359-0.578h-0.055l0.055-0.166h-0.055l-0.11,0.055c-0.295,0.295-0.451,0.488-0.469,0.58
c-0.02,0.092-0.047,0.137-0.083,0.137c-0.037,0-0.102,0.01-0.193,0.028c-0.092,0.019-0.138,0.064-0.138,0.138l0.11,0.11
c-0.074,0-0.11,0.036-0.11,0.11h0.055c-0.037,0.11-0.11,0.24-0.221,0.387c-0.478,0.588-0.717,0.938-0.717,1.047l0.11-0.055
L77.728,81.477z M77.949,88.813c0.036,0,0.073-0.036,0.11-0.11l0.055-0.055l-0.055-0.056l-0.055,0.056
c-0.074,0-0.11,0.009-0.11,0.027c0,0.02,0,0.047,0,0.082C77.894,88.795,77.913,88.813,77.949,88.813z M79.824,78.608
c0.036-0.073,0.156-0.212,0.358-0.414c0.201-0.201,0.404-0.44,0.607-0.717c0.201-0.276,0.367-0.515,0.497-0.718
c0.128-0.201,0.193-0.339,0.193-0.413V76.07c-0.075,0.037-0.185,0.147-0.331,0.332c-0.147,0.184-0.303,0.386-0.469,0.605
c-0.166,0.221-0.313,0.414-0.441,0.58c-0.129,0.166-0.23,0.285-0.303,0.358c-0.075,0.075-0.12,0.147-0.138,0.221
c-0.019,0.074-0.027,0.13-0.027,0.165C79.769,78.518,79.787,78.608,79.824,78.608z M79.824,79.381c0.036,0,0.146-0.111,0.331-0.331
c0.036-0.073,0.083-0.138,0.138-0.193s0.101-0.101,0.138-0.138c0.036-0.036,0.074-0.129,0.11-0.275
c-0.11,0-0.257,0.146-0.441,0.441C79.915,79.18,79.824,79.345,79.824,79.381z M79.824,79.602l0.331-0.387
c0.036-0.035,0.083-0.091,0.138-0.165c0.055-0.073,0.11-0.146,0.166-0.221c0.055-0.073,0.083-0.129,0.083-0.165v-0.056
c-0.074,0.036-0.212,0.202-0.414,0.497C79.925,79.4,79.824,79.565,79.824,79.602z M80.817,71.161h-0.055
c-0.037,0-0.092-0.036-0.166-0.11c-0.295-0.367-0.441-0.827-0.441-1.379c0-0.184,0.027-0.414,0.083-0.689s0.248-0.505,0.579-0.689
c0.036-0.036,0.294-0.146,0.772-0.331c1.103-0.367,2.297-0.717,3.585-1.048c1.287-0.331,1.986-0.496,2.096-0.496v0.055h-0.11
l-1.875,0.607c0.036,0,0.055,0.02,0.055,0.055L85.009,67.3h-0.166l-0.055,0.11v0.055h0.055c0.184,0,0.339-0.027,0.469-0.083
c0.128-0.055,0.229-0.083,0.303-0.083l-0.11,0.276c0,0.037,0.036,0.055,0.11,0.055c0.184-0.074,0.276-0.146,0.276-0.221
c0-0.074,0.008-0.119,0.027-0.138c0.019-0.019,0.063-0.046,0.138-0.083h0.166v0.055c0.036,0.075,0.146,0.11,0.331,0.11
c0.11,0,0.304-0.027,0.58-0.083s0.643-0.146,1.103-0.276c0.46-0.129,0.836-0.221,1.131-0.276c0.294-0.055,0.515-0.083,0.662-0.083
c0.073,0,0.11,0.02,0.11,0.055c0,0.11-0.451,0.368-1.352,0.772c-0.902,0.405-1.719,0.847-2.455,1.324
c-0.075,0.075-0.533,0.258-1.379,0.552c-0.257,0.11-0.551,0.23-0.882,0.358c-0.331,0.13-0.599,0.23-0.8,0.304
c-0.203,0.074-0.386,0.12-0.552,0.138c-0.166,0.02-0.488,0.193-0.965,0.524C81.277,70.996,80.963,71.161,80.817,71.161z
 M80.651,78.388l0.662-0.827l0.331-0.497c-0.075,0-0.221,0.147-0.441,0.441l0.441-0.661l-0.441,0.551
c-0.368,0.406-0.552,0.635-0.552,0.689s0,0.102,0,0.139l0.11-0.111C80.688,78.223,80.651,78.314,80.651,78.388z M83.299,82.249
l0.11-0.056c0.074,0,0.166-0.045,0.276-0.137s0.239-0.193,0.386-0.305c0.146-0.109,0.221-0.201,0.221-0.275h-0.055
c-0.037,0-0.185,0.11-0.441,0.331C83.539,82.028,83.373,82.176,83.299,82.249z"/>
  <path class="line4" d="M103.379,69.23c0.146-0.11,0.275-0.256,0.386-0.441c0.11-0.146,0.202-0.256,0.276-0.331
c0.036-0.036,0.074-0.055,0.11-0.055c0.074,0,0.146,0.074,0.221,0.22c0.074,0.147,0.11,0.258,0.11,0.331
c0,0.037-0.009,0.102-0.027,0.193c-0.02,0.092-0.083,0.193-0.193,0.303c-0.074,0.11-0.175,0.248-0.303,0.414
c-0.13,0.166-0.249,0.304-0.359,0.414s-0.212,0.212-0.303,0.303c-0.092,0.092-0.157,0.157-0.193,0.193v0.055
c0.036,0,0.091-0.036,0.166-0.11c0.073-0.073,0.165-0.173,0.275-0.303c0.11-0.128,0.221-0.193,0.331-0.193h0.055
c0,0.037-0.092,0.129-0.276,0.276c-0.185,0.147-0.405,0.295-0.662,0.441c-0.185,0.11-0.368,0.24-0.552,0.386
c-0.185,0.147-0.313,0.313-0.386,0.497c-0.037,0.037-0.166,0.147-0.386,0.331c-0.331,0.295-0.708,0.626-1.131,0.993
c-0.423,0.368-0.8,0.709-1.131,1.021c-0.331,0.313-0.496,0.524-0.496,0.635c-0.221,0.074-1.067,0.809-2.538,2.206
c-0.662,0.662-1.564,1.51-2.703,2.538c-0.882,0.883-1.757,1.737-2.62,2.565c-0.864,0.827-1.536,1.498-2.014,2.014l-0.166,0.165
c-0.147,0.147-0.303,0.323-0.469,0.524c-0.166,0.202-0.313,0.386-0.441,0.551c-0.129,0.166-0.212,0.268-0.248,0.304v0.056
c0.036,0,0.184-0.056,0.441-0.166c0.256-0.109,0.441-0.238,0.551-0.386c0.257-0.185,0.405-0.276,0.441-0.276
c0,0.074-0.202,0.323-0.606,0.745c-0.406,0.423-0.607,0.653-0.607,0.689c0.036,0,0.294-0.221,0.772-0.662l0.11-0.11
c0.184-0.146,0.312-0.238,0.386-0.275h0.055c0.036,0,0.074,0.02,0.11,0.055c0.036,0.037,0.074,0.056,0.11,0.056
c0.036,0,0.063,0,0.083,0c0.018,0,0.046-0.036,0.083-0.11c0.036-0.036,0.083-0.074,0.138-0.11s0.102-0.056,0.138-0.056h0.166
l-0.055-0.109v-0.166c0.036,0,0.083-0.008,0.138-0.027c0.055-0.019,0.11-0.056,0.166-0.111c0.055-0.055,0.119-0.101,0.193-0.137
l0.166-0.166v0.055c0,0.037-0.055,0.111-0.166,0.221c-0.075,0.074-0.185,0.203-0.331,0.387l-0.441,0.441
c-0.075,0.109-0.166,0.165-0.276,0.165h-0.055c0-0.036-0.019-0.056-0.055-0.056c-0.037,0-0.092,0.037-0.166,0.111l-0.055,0.055
h0.11c0.036,0,0.074,0,0.11,0c0.036,0,0.055,0.02,0.055,0.055c0,0.037-0.037,0.093-0.11,0.166
c-0.074,0.074-0.185,0.186-0.331,0.331c-0.699,0.736-1.288,1.416-1.765,2.041c-0.477,0.626-0.983,1.187-1.517,1.683
c-0.532,0.496-1.259,0.91-2.179,1.241c-0.11,0.036-0.239,0.056-0.386,0.056c-0.294,0-0.607-0.156-0.938-0.47
c-0.331-0.312-0.57-0.745-0.717-1.296c-0.11-0.295-0.166-0.57-0.166-0.828c0-0.221,0.037-0.422,0.11-0.606
c0.919-2.722,2.593-5.755,5.02-9.102c1.47-2.17,2.758-4.266,3.861-6.289c1.029,0.516,1.544,1.158,1.544,1.931
c0,0.257-0.12,0.726-0.358,1.406c-0.24,0.682-0.764,1.5-1.572,2.455c-0.847,1.068-1.82,2.455-2.924,4.165
c-1.103,1.71-1.911,3.116-2.427,4.22c0.258-0.146,1.04-0.846,2.345-2.096s2.73-2.629,4.275-4.137
c1.324-1.287,2.446-2.373,3.365-3.256c0.846-0.808,1.287-1.213,1.324-1.213h0.055c0.036,0,0.063-0.008,0.083-0.027
c0.018-0.019,0.063-0.047,0.138-0.083c0.074-0.036,0.146-0.073,0.221-0.11c0.073-0.036,0.128-0.055,0.166-0.055
c0.036,0,0.055,0.02,0.055,0.055v0.055c0,0.037-0.028,0.083-0.083,0.139c-0.055,0.055-0.119,0.102-0.193,0.138
c-0.11,0.036-0.202,0.092-0.276,0.165l0.055-0.221c-0.185,0.147-0.313,0.276-0.386,0.387c-0.037,0.11-0.055,0.202-0.055,0.275v0.11
h0.055c0.074-0.036,0.166-0.165,0.276-0.386l0.055,0.055c0.036,0,0.083-0.018,0.138-0.055c0.055-0.036,0.129-0.11,0.221-0.221
c0.091-0.11,0.184-0.221,0.276-0.331c0.091-0.11,0.156-0.201,0.193-0.276l0.055-0.11c0-0.036-0.02-0.055-0.055-0.055
c0-0.074-0.02-0.119-0.055-0.138c-0.037-0.019-0.075-0.019-0.11,0c-0.037,0.019-0.075,0.027-0.11,0.027
c-0.037,0.037-0.075,0.055-0.11,0.055h-0.11v-0.055l0.055-0.055c0.073-0.073,0.156-0.128,0.248-0.166
c0.091-0.036,0.174-0.073,0.249-0.11c0.036-0.11,0.118-0.239,0.248-0.386c0.129-0.146,0.267-0.276,0.414-0.386l-0.11,0.11
c-0.074,0.074-0.147,0.157-0.221,0.248c-0.074,0.092-0.129,0.175-0.165,0.248v0.11h0.055c0.073,0,0.184-0.036,0.331-0.11
c0-0.146,0.019-0.22,0.055-0.22c0.036,0,0.074,0.019,0.11,0.055c0,0.037,0.019,0.055,0.055,0.055h0.055
c0.074,0,0.146-0.036,0.221-0.11c0.074-0.074,0.129-0.138,0.166-0.193c0.036-0.055,0.055-0.101,0.055-0.138l0.055-0.055
c0.036-0.036,0.091-0.074,0.166-0.11c0.11-0.036,0.201-0.083,0.276-0.138c0.073-0.055,0.128-0.101,0.166-0.138l0.055-0.11
l-0.055-0.055c0-0.036,0.036-0.074,0.11-0.11c0.036-0.036,0.083-0.091,0.138-0.166c0.055-0.074,0.11-0.138,0.166-0.193
s0.101-0.101,0.138-0.138c-0.074,0.037-0.129,0.055-0.166,0.055c-0.074,0-0.129-0.018-0.165-0.055v-0.11
c0-0.11,0.055-0.211,0.165-0.303c0.11-0.091,0.239-0.193,0.386-0.303c0.257-0.11,0.441-0.257,0.552-0.441
c-0.037,0.11-0.102,0.23-0.193,0.358c-0.092,0.13-0.157,0.249-0.193,0.358l0.166-0.165c0.146-0.11,0.294-0.229,0.441-0.359
c0.146-0.128,0.221-0.248,0.221-0.358c0-0.036,0.018-0.063,0.055-0.083c0.036-0.018,0.073-0.027,0.11-0.027
c0.11,0,0.166-0.036,0.166-0.11v-0.055c0.073-0.11,0.201-0.267,0.386-0.469c0.184-0.202,0.367-0.386,0.551-0.552
c0.184-0.166,0.35-0.267,0.497-0.303h0.055c0.036,0,0.055-0.019,0.055-0.055c-0.037-0.11-0.055-0.184-0.055-0.221v-0.055
c0.036,0,0.055,0.009,0.055,0.027c0,0.02,0.019,0.028,0.055,0.028c0.036,0.037,0.074,0.055,0.11,0.055h0.055
c0.036,0,0.073,0,0.11,0c0.036,0,0.091-0.055,0.166-0.166c0.036-0.074,0.073-0.129,0.11-0.166c0.036-0.036,0.055-0.074,0.055-0.11
h-0.055c-0.037,0.037-0.075,0.055-0.11,0.055C102.993,69.708,103.121,69.526,103.379,69.23z M88.65,84.842
c0.073-0.074,0.173-0.184,0.303-0.331c0.128-0.146,0.256-0.276,0.386-0.386c0.128-0.111,0.211-0.184,0.248-0.221l-0.11,0.165
c-0.11,0.147-0.24,0.285-0.386,0.413c-0.147,0.131-0.257,0.249-0.331,0.359c-0.074,0.037-0.129,0.055-0.165,0.055L88.65,84.842z
 M90.029,84.346l0.386-0.387v0.496H90.36L90.25,84.4c-0.037,0-0.083,0.027-0.138,0.082c-0.055,0.056-0.138,0.139-0.248,0.249
c-0.075,0.074-0.138,0.13-0.193,0.165c-0.055,0.037-0.102,0.056-0.138,0.056c0-0.036,0.046-0.11,0.138-0.221
C89.762,84.621,89.882,84.492,90.029,84.346z M91.077,81.531c0.074-0.035,0.129-0.072,0.166-0.109v-0.056l-0.055-0.056
l-0.276,0.276v0.056h0.055L91.077,81.531z M93.063,82.193c0-0.035,0.019-0.063,0.055-0.082c0.036-0.019,0.091-0.027,0.166-0.027
c0,0.036-0.083,0.146-0.248,0.33c-0.166,0.186-0.396,0.414-0.689,0.689c-0.295,0.276-0.588,0.562-0.883,0.855
c-0.147,0.11-0.24,0.166-0.276,0.166v-0.056l0.386-0.386c0.11-0.074,0.184-0.146,0.221-0.221c0.036-0.074,0.074-0.146,0.11-0.221
c0-0.036,0.019-0.083,0.055-0.139c0.036-0.055,0.11-0.118,0.221-0.192c0.036-0.036,0.073-0.091,0.11-0.165l0.055-0.111h-0.055
v-0.109l0.055,0.055h0.055l0.055-0.055c0.036-0.037,0.073-0.092,0.11-0.166c0.036-0.074,0.073-0.129,0.11-0.166l0.11-0.109
l0.055,0.055c0.036,0.074,0.073,0.11,0.11,0.11C92.989,82.249,93.026,82.23,93.063,82.193z M92.843,79.656v-0.11h-0.11
c-0.037,0.037-0.12,0.11-0.249,0.221c-0.129,0.11-0.257,0.24-0.386,0.386c-0.129,0.147-0.212,0.276-0.248,0.387v0.055
c0,0.074,0.036,0.13,0.11,0.166c0.036,0,0.074-0.036,0.11-0.11c0.036-0.073,0.074-0.146,0.11-0.221l0.11-0.221v-0.056
c0.11,0,0.193,0,0.248,0s0.091-0.027,0.11-0.082c0.019-0.055,0.028-0.102,0.028-0.139c0-0.035,0.008-0.082,0.027-0.137
C92.723,79.739,92.768,79.693,92.843,79.656z M95.104,77.726c0-0.11-0.019-0.165-0.055-0.165l-0.055,0.109
c-0.075,0.037-0.138,0.084-0.193,0.139s-0.102,0.102-0.138,0.138v-0.056l0.055-0.055c0.036-0.074,0.091-0.146,0.166-0.221
l0.055-0.055h-0.166c-0.11,0.037-0.304,0.174-0.58,0.414c-0.275,0.239-0.505,0.469-0.689,0.689
c-0.147,0.184-0.221,0.313-0.221,0.386c0,0.11,0.019,0.165,0.055,0.165c0.036,0,0.129-0.073,0.276-0.221
c0.074-0.035,0.129-0.073,0.166-0.109c0.074-0.074,0.129-0.119,0.166-0.139c0.036-0.018,0.074-0.027,0.11-0.027
c0.036,0,0.083-0.019,0.138-0.055c0.055-0.037,0.11-0.092,0.166-0.166s0.091-0.139,0.11-0.193c0.019-0.055-0.009-0.102-0.083-0.138
l-0.055-0.056c0-0.035,0.073-0.055,0.221-0.055C94.809,78.057,94.994,77.946,95.104,77.726z M94.001,78.388
c0.11-0.11,0.184-0.165,0.221-0.165l0.055-0.056v0.056c0,0.036-0.028,0.082-0.083,0.137c-0.055,0.056-0.119,0.103-0.193,0.139
c-0.075,0.037-0.138,0.074-0.193,0.11s-0.102,0.056-0.138,0.056l0.11-0.111C93.816,78.518,93.891,78.462,94.001,78.388z
 M93.946,81.311c0.036-0.072,0.174-0.238,0.414-0.496c0.239-0.256,0.532-0.551,0.883-0.883c0.349-0.33,0.689-0.652,1.021-0.965
c0.331-0.312,0.57-0.543,0.717-0.689l0.166-0.11l-0.11,0.165l-0.883,0.883c-0.368,0.406-0.717,0.764-1.048,1.076
c-0.331,0.313-0.606,0.579-0.827,0.8C94.092,81.238,93.981,81.311,93.946,81.311z M95.987,76.678c0-0.074,0.019-0.129,0.055-0.166
v0.166H95.987z M97.2,75.74c0.074,0,0.11-0.019,0.11-0.055c0-0.036-0.019-0.074-0.055-0.11c-0.037-0.036-0.074-0.055-0.11-0.055
l-0.055,0.055c0,0.075,0.018,0.11,0.055,0.11L97.2,75.74z M99.848,75.243c0.146,0,0.221,0.02,0.221,0.055
c0,0.037-0.009,0.083-0.027,0.138c-0.02,0.055-0.102,0.157-0.249,0.303c-0.147,0.147-0.303,0.286-0.469,0.414
c-0.166,0.13-0.323,0.229-0.469,0.304c-0.037,0.037-0.075,0.055-0.11,0.055c0-0.035,0-0.073,0-0.109
c0-0.037,0.128-0.185,0.386-0.442c0.184-0.221,0.349-0.367,0.496-0.441c0.036-0.036,0.074-0.063,0.11-0.083
c0.036-0.019,0.055-0.046,0.055-0.083v-0.055C99.793,75.263,99.812,75.243,99.848,75.243z M99.903,73.147
c0.074,0,0.11-0.018,0.11-0.055c0-0.036-0.027-0.083-0.083-0.138s-0.102-0.083-0.138-0.083h-0.055c-0.037,0-0.055,0.02-0.055,0.055
c0,0.037,0.019,0.083,0.055,0.138C99.774,73.12,99.829,73.147,99.903,73.147z M100.51,74.692c0.074-0.036,0.128-0.055,0.166-0.055
l0.055,0.055c0,0.037-0.02,0.074-0.055,0.11c-0.037,0.037-0.075,0.083-0.11,0.138c-0.037,0.055-0.075,0.083-0.11,0.083
c-0.037,0-0.055-0.036-0.055-0.11c0-0.036,0.008-0.074,0.027-0.11C100.446,74.766,100.474,74.729,100.51,74.692z M101.172,74.03
c-0.075,0.11-0.13,0.185-0.166,0.221h-0.055L101.172,74.03z M101.393,73.423c-0.037,0.11-0.074,0.185-0.11,0.221h-0.055l0.055-0.11
L101.393,73.423z M103.434,71.823c0,0.037,0.019,0.055,0.055,0.055l0.11-0.055c-0.331,0.295-0.736,0.681-1.213,1.158
c-0.147,0.11-0.24,0.166-0.276,0.166v-0.055l0.055-0.11c0-0.036-0.019-0.063-0.055-0.083c-0.037-0.019-0.083-0.028-0.138-0.028
s-0.102,0-0.138,0h-0.11v-0.11c0.036-0.036,0.129-0.11,0.276-0.221c0.11-0.11,0.184-0.166,0.221-0.166h0.055l-0.166,0.166
c-0.074,0.074-0.11,0.147-0.11,0.221c0,0.037,0.019,0.055,0.055,0.055c0.036,0,0.146-0.091,0.331-0.275
c0.036-0.074,0.083-0.119,0.138-0.138c0.055-0.018,0.119-0.027,0.193-0.027c0.073,0,0.128,0,0.166,0
c0.036,0,0.091-0.036,0.166-0.11c0-0.11-0.028-0.174-0.083-0.193c-0.055-0.018-0.12-0.018-0.193,0
c-0.075,0.02-0.147,0.047-0.221,0.083h-0.055c0-0.036,0.008-0.055,0.027-0.055c0.019,0,0.074-0.046,0.166-0.138
c0.091-0.091,0.229-0.229,0.414-0.414c0.146-0.11,0.256-0.201,0.331-0.276l0.11-0.055c0,0.037-0.009,0.075-0.027,0.11
c-0.02,0.037-0.083,0.13-0.193,0.276l-0.055,0.11v0.055l0.055-0.055C103.397,71.75,103.434,71.788,103.434,71.823z M102.496,72.706
c0.146-0.11,0.221-0.202,0.221-0.276c-0.037,0-0.083,0.009-0.138,0.027c-0.055,0.02-0.11,0.055-0.166,0.11
c-0.055,0.055-0.102,0.11-0.138,0.166c-0.037,0.055-0.064,0.092-0.083,0.11c-0.02,0.02-0.009,0.02,0.027,0
C102.256,72.825,102.349,72.78,102.496,72.706z M102.827,70.058c-0.037-0.036-0.055-0.074-0.055-0.11
c0-0.036,0.027-0.063,0.083-0.083c0.055-0.018,0.091-0.027,0.11-0.027c0.019,0,0.028,0.037,0.028,0.11
c-0.037,0.037-0.064,0.064-0.083,0.083C102.891,70.05,102.863,70.058,102.827,70.058z"/>
  <path class="line5" d="M111.708,91.461c-0.552,0-1.085-0.515-1.6-1.545l-0.221-0.221c-0.074-0.366-0.11-0.752-0.11-1.158
c0-1.359,0.552-2.924,1.655-4.688l-0.055,0.056c-2.427,2.096-3.898,3.291-4.413,3.586c-0.295,0.516-0.919,1.02-1.875,1.516
c-0.957,0.497-1.785,0.82-2.482,0.966c-0.184,0.037-0.552,0.056-1.104,0.056c-0.772-0.111-1.213-0.257-1.324-0.441
c-0.184-0.367-0.367-0.662-0.552-0.883c-0.404-0.552-0.606-1.104-0.606-1.654c0-2.354,0.957-4.652,2.868-6.896
c1.912-2.242,4.137-4.137,6.675-5.681c2.538-1.545,4.725-2.317,6.564-2.317c1.029,0,1.856,0.24,2.482,0.717
c0.625,0.479,1.084,0.965,1.379,1.462c0.294,0.497,0.441,0.819,0.441,0.965c0.146,0.441,0.221,0.737,0.221,0.883
s-0.055,0.221-0.166,0.221h-0.055c-0.368-0.111-0.689-0.442-0.965-0.994c-0.276-0.551-0.599-0.918-0.965-1.103
c-0.441-0.074-0.864-0.11-1.269-0.11c-0.919,0-1.895,0.24-2.923,0.717c-1.03,0.478-1.968,1.067-2.813,1.765
c-1.729,1.324-3.558,2.951-5.489,4.882s-3.282,4.036-4.054,6.315c1.839-0.587,3.613-1.544,5.323-2.867
c1.71-1.324,3.393-2.759,5.047-4.303c1.949-1.84,3.88-3.421,5.792-4.745c0.11-0.146,0.201-0.221,0.276-0.221
c0.221,0,0.587,0.13,1.103,0.386c0.515,0.368,0.772,0.772,0.772,1.214c-0.037,0.478-0.166,0.736-0.386,0.771l-1.269,1.766
c-1.14,1.398-1.765,2.096-1.875,2.096s-0.166,0.092-0.166,0.276s-0.11,0.386-0.331,0.606c0,0.074-0.075,0.221-0.221,0.441
c-0.552,0.772-0.855,1.186-0.91,1.241s-0.185,0.268-0.386,0.635c-0.202,0.368-0.441,0.81-0.717,1.323
c-0.275,0.516-0.634,1.214-1.076,2.097v0.056c0,0.036,0.019,0.055,0.055,0.055c0.257,0,1.25-0.68,2.979-2.041
c2.022-1.58,3.511-2.85,4.468-3.807c0.074-0.146,0.174-0.238,0.303-0.275c0.129-0.036,0.229-0.063,0.304-0.083
c0.073-0.019,0.128-0.046,0.166-0.083l-0.055-0.109c0-0.074,0.091-0.221,0.275-0.441c0.478-0.332,1.333-1.057,2.565-2.18
c1.232-1.121,1.903-1.718,2.013-1.793c0.036,0,0.055,0.02,0.055,0.056l-0.055,0.11c-0.331,0.331-0.571,0.552-0.717,0.662
c-0.258,0.258-0.423,0.405-0.497,0.441c-0.075,0.037-0.12,0.074-0.138,0.109c-0.019,0.037-0.055,0.103-0.11,0.193
c-0.055,0.092-0.102,0.158-0.138,0.193c-0.037,0.037-0.11,0.074-0.221,0.11c-0.221,0.185-0.571,0.533-1.048,1.048
c-0.957,1.141-1.84,1.932-2.648,2.373v0.055c0,0.185-0.902,1.104-2.703,2.758l-1.213,1.104v0.055c0.036,0,0.146-0.073,0.331-0.221
c0.184-0.146,0.331-0.238,0.441-0.275c0.036,0,0.055,0.02,0.055,0.055c0,0.037-0.037,0.092-0.11,0.166v0.055
c0.074,0,0.772-0.587,2.096-1.765c2.207-1.949,4.101-3.714,5.682-5.296c0.404-0.404,0.625-0.662,0.662-0.771
c0.036-0.111,0.074-0.202,0.11-0.276c0-0.257,0.036-0.433,0.11-0.524c0.074-0.091,0.184-0.138,0.331-0.138
c0.11,0.074,0.166,0.165,0.166,0.276c0,0.33-0.24,0.846-0.717,1.544c-0.257,0.405-0.516,0.698-0.772,0.883l-0.276,0.276
c-1.471,1.323-2.538,2.316-3.2,2.979c-1.14,1.14-1.71,1.765-1.71,1.875l0.166,0.055l-0.497,0.607c-0.037,0-0.083-0.036-0.138-0.11
c-0.055-0.073-0.12-0.146-0.193-0.221c-0.147,0-0.221,0.092-0.221,0.276c0,0.036,0.036,0.074,0.11,0.109v0.056
c0,0.295-0.461,0.772-1.379,1.435c-0.551,0.367-0.938,0.717-1.158,1.048c-0.406,0.589-0.985,1.103-1.738,1.544
C112.968,91.24,112.296,91.461,111.708,91.461z M99.903,86.055c0.478-1.066,1.048-2.076,1.71-3.033
c0.11-0.11,0.166-0.201,0.166-0.275c0.295-0.441,0.607-0.863,0.938-1.27h0.055l0.055-0.055c0.037-0.036,0.102-0.092,0.193-0.166
c0.092-0.073,0.157-0.138,0.193-0.193c0.036-0.055,0.128-0.192,0.275-0.413h-0.11c1.655-1.876,3.318-3.401,4.992-4.579
c1.673-1.176,3.097-2.059,4.275-2.647l1.765-0.827c-0.295,0-0.957,0.24-1.986,0.717c-2.538,1.178-4.781,2.629-6.73,4.357
c-1.029,0.92-1.857,1.766-2.482,2.538c0-0.036-0.019-0.056-0.055-0.056c-0.074,0.037-0.184,0.147-0.331,0.332
c-0.146,0.184-0.221,0.295-0.221,0.33l0.276-0.221c-1.986,2.244-2.979,4.027-2.979,5.352V86.055z M104.592,79.546l0.276-0.386
c-0.037,0-0.102,0.037-0.193,0.109c-0.092,0.075-0.138,0.148-0.138,0.221C104.537,79.527,104.556,79.546,104.592,79.546z
 M106.413,85.559l0.221-0.055c0.11-0.036,0.211-0.083,0.303-0.139c0.091-0.055,0.156-0.082,0.193-0.082l0.055,0.055
c0.846-0.367,1.765-1.066,2.758-2.096c0.698-0.625,1.36-1.178,1.986-1.655c0.441-0.587,1.084-1.231,1.931-1.931
c1.066-0.956,1.856-1.783,2.372-2.482L106.413,85.559z M108.178,85.834c0.036,0,0.091-0.035,0.166-0.109
c0.221-0.111,0.377-0.174,0.469-0.193c0.091-0.019,0.193-0.082,0.304-0.193c0.11-0.109,0.166-0.221,0.166-0.33
c0-0.037-0.02-0.056-0.055-0.056c-0.11,0-0.34,0.13-0.689,0.386c-0.351,0.258-0.524,0.424-0.524,0.496H108.178z M109.447,84.842
l0.276-0.331l-0.166,0.11c-0.11,0.11-0.166,0.185-0.166,0.221H109.447z M110.66,88.703c0.808-1.766,1.746-3.475,2.813-5.131
l0.331-0.605h-0.11l0.11-0.221l-0.11,0.055l-0.441,0.662c-0.516,0.809-1.067,1.719-1.655,2.73
c-0.588,1.012-0.902,1.793-0.938,2.344V88.703z M110.881,86.496c0.074-0.073,0.256-0.35,0.552-0.827
c2.207-3.565,3.731-5.958,4.579-7.171c0.146-0.11,0.275-0.239,0.386-0.387l0.331-0.496v-0.055c0-0.036,0.019-0.102,0.055-0.193
c0.036-0.092,0.091-0.221,0.166-0.387c0.074-0.165,0.146-0.303,0.221-0.413l-1.875,2.427l-1.269,1.711
c-0.516,0.698-1.167,1.783-1.958,3.254c-0.792,1.471-1.186,2.262-1.186,2.372L111.102,86L110.881,86.496z M114.742,81.477
l0.166-0.055c0.367-0.295,0.643-0.615,0.828-0.966c0.184-0.349,0.394-0.681,0.634-0.993c0.239-0.311,0.358-0.504,0.358-0.578
h-0.055l0.055-0.166h-0.055c-0.037,0-0.092,0.027-0.166,0.082c-0.074,0.056-0.157,0.139-0.248,0.249
c-0.092,0.11-0.147,0.212-0.166,0.304c-0.02,0.092-0.047,0.137-0.083,0.137c-0.037,0-0.083,0.01-0.138,0.028
s-0.102,0.036-0.138,0.056c-0.037,0.019-0.055,0.047-0.055,0.082l0.055,0.11l-0.055,0.11h0.055
c-0.368,0.552-0.662,0.957-0.883,1.214l-0.11,0.22l0.166-0.055C114.797,81.366,114.742,81.441,114.742,81.477z M115.018,88.813
c0.074,0,0.11-0.073,0.11-0.221h-0.055c-0.075,0.036-0.11,0.064-0.11,0.083c0,0.02,0,0.047,0,0.082
C114.963,88.795,114.981,88.813,115.018,88.813z M116.838,78.608c0.036-0.073,0.119-0.221,0.249-0.441
c0.128-0.221,0.275-0.469,0.441-0.745c0.166-0.275,0.322-0.504,0.469-0.689c-0.075,0-0.203,0.147-0.386,0.441
c-0.368,0.479-0.588,0.745-0.662,0.801c-0.074,0.055-0.11,0.174-0.11,0.357V78.608z M116.894,79.381l0.331-0.331l0.386-0.552
c0-0.036-0.02-0.055-0.055-0.055l-0.11,0.109c-0.075,0.074-0.185,0.221-0.331,0.441C116.967,79.215,116.894,79.345,116.894,79.381z
 M116.894,79.602l0.331-0.387c0.11-0.109,0.193-0.221,0.248-0.33c0.055-0.111,0.101-0.185,0.138-0.221v-0.056
c-0.075,0.036-0.212,0.202-0.414,0.497C116.995,79.4,116.894,79.565,116.894,79.602z M117.666,78.388
c0.036,0,0.073-0.019,0.11-0.056c0.036-0.035,0.101-0.118,0.193-0.248c0.091-0.129,0.193-0.256,0.303-0.386
c0.11-0.129,0.184-0.229,0.221-0.304l-0.441,0.221c-0.11,0.146-0.203,0.268-0.276,0.359c-0.075,0.092-0.11,0.156-0.11,0.192
l0.166-0.056L117.666,78.388z M120.369,82.249l0.11-0.056c0.074,0,0.276-0.146,0.607-0.441c0.146-0.109,0.221-0.201,0.221-0.275
h-0.055l-0.11,0.11c-0.11,0.075-0.221,0.157-0.331,0.249s-0.212,0.174-0.303,0.248C120.415,82.158,120.369,82.213,120.369,82.249z"
        mask="url('#writing')" />
  <path class="line5" d="M118.438,99.516h-0.11c-0.477,0-1.083-0.332-1.82-0.994l-0.221-0.165c-0.477-0.368-0.725-0.708-0.745-1.021
c-0.018-0.313-0.063-0.579-0.138-0.8l-0.11-1.048c0-0.186,0.037-0.406,0.11-0.662c1.398-4.523,3.926-9.598,7.585-15.225
s6.683-9.985,9.074-13.074l3.475-3.972l-0.11,0.055c-0.185,0-0.46,0.037-0.827,0.11c-0.699,0.11-1.233,0.166-1.6,0.166
c-0.295,0-0.516-0.055-0.662-0.166v0.055l-0.055,0.055c-0.075,0.037-0.175,0.064-0.304,0.083c-0.129,0.019-0.275,0.037-0.441,0.055
c-0.166,0.019-0.285,0.047-0.359,0.083c-0.699,0.221-1.591,0.414-2.675,0.579c-1.085,0.166-2.133,0.313-3.144,0.441
c-1.012,0.13-1.729,0.202-2.151,0.221c-0.422,0.02-0.781,0.064-1.076,0.138c-0.294,0.075-0.57,0.157-0.828,0.249
c-0.256,0.092-0.532,0.175-0.828,0.248c-0.625,0-0.938-0.459-0.938-1.379c0-1.029,0.295-1.544,0.882-1.544h0.386
c0.221,0,0.406-0.036,0.552-0.11c0.441-0.221,0.864-0.331,1.269-0.331l0.938,0.11c0.367,0,0.808-0.055,1.324-0.166
c2.869-0.294,5.517-0.497,7.944-0.607l4.413-0.276c4.083-4.449,6.564-7.005,7.447-7.667c0.184-0.184,0.284-0.35,0.303-0.497
c0.019-0.146,0.046-0.256,0.083-0.331c0.036-0.073,0.128-0.11,0.276-0.11c0.367,0,0.735,0.221,1.103,0.662
c0.587,0.736,0.883,1.379,0.883,1.931c0,0.257-0.074,0.46-0.221,0.607c-0.478,0.478-1.214,1.167-2.207,2.068
c-0.993,0.902-2.171,2.014-3.531,3.337c1.324-0.073,2.519-0.166,3.586-0.276h1.103v-0.055l0.828-0.055l-0.11,0.11h0.055
c0.146,0,0.221-0.036,0.221-0.11h0.22v0.055c4.449,0.075,6.675,0.13,6.675,0.166c0,0.037-1.012,0.055-3.034,0.055h-3.806
l-5.847,0.221L141,60.956c0.515,0,0.918-0.019,1.213-0.055h1.765l0.883-0.055l-0.055,0.055c0.331,0,0.57-0.019,0.717-0.055h0.717
v0.055h0.221l0.055-0.055h0.497c1.655,0,3.511,0.037,5.571,0.11c1.801,0.075,3.476,0.11,5.02,0.11c1.177,0,2.262-0.019,3.255-0.055
c0,0.11,0.221,0.166,0.662,0.166s0.689-0.027,0.745-0.083c0.055-0.055,0.101-0.083,0.138-0.083l0.11,0.11
c0.073,0.075,0.129,0.11,0.165,0.11l-0.055-0.11c0-0.036,0.019-0.055,0.055-0.055c0.11,0,0.267,0.028,0.469,0.083
c0.202,0.055,0.367,0.092,0.497,0.11c0.129,0.02,0.377,0.028,0.744,0.028c-1.029,0.074-2.096,0.11-3.199,0.11
c-1.84,0-4.192-0.129-7.061-0.386h-0.552h0.11c0.073,0.037,0.11,0.092,0.11,0.166l-0.331,0.055
c-6.215,0.11-10.666,0.331-13.35,0.662c-1.03,0.993-2.115,2.115-3.254,3.365c-0.147,0.147-0.258,0.323-0.331,0.524
c-0.075,0.203-0.351,0.451-0.828,0.745c-0.295,0.295-0.533,0.607-0.717,0.938c-0.368,0.588-0.809,1.123-1.324,1.6
c-0.185,0.368-0.736,1.03-1.655,1.986c-2.833,4.192-4.432,6.619-4.799,7.281c-0.516,0.883-1.14,1.876-1.875,2.979l-0.055,0.166
c-0.331,0.479-0.864,1.361-1.6,2.648c-2.28,4.119-3.842,7.667-4.689,10.646c-0.256,0.918-0.386,1.729-0.386,2.427
c0,0.625,0.11,1.14,0.331,1.544l0.055,0.332C118.99,99.33,118.806,99.479,118.438,99.516z M116.231,95.488
c0.516-1.178,1.454-3.016,2.813-5.517c3.089-5.59,5.497-9.837,7.227-12.743c0-0.072,0.083-0.256,0.248-0.551
s0.248-0.478,0.248-0.552l-0.165,0.056l-0.717,1.158c-0.883,1.508-2.078,3.558-3.585,6.15s-2.877,5.057-4.11,7.392
C116.958,93.217,116.306,94.752,116.231,95.488z M116.342,93.061c0.809-1.581,2.023-3.806,3.641-6.674
c4.118-7.172,7.097-12.081,8.936-14.729c0.478-0.367,0.717-0.662,0.717-0.883c0-0.11,0.146-0.386,0.441-0.827l0.276-0.276
c0.036,0,0.073,0.02,0.11,0.055v0.055h0.055v-0.166c0-0.11,0.166-0.331,0.496-0.662c0.515-0.587,0.772-1.011,0.772-1.269
l-0.11,0.166c-1.509,1.765-3.016,3.732-4.523,5.902c-1.839,2.758-4.468,7.301-7.888,13.625
C117.758,90.102,116.783,91.994,116.342,93.061z M125.885,78.608c0.036,0,0.091-0.036,0.166-0.11
c0.073-0.074,0.119-0.129,0.138-0.166c0.019-0.035,0.028-0.073,0.028-0.109l-0.11-0.111l-0.11,0.111
c-0.075,0.074-0.11,0.146-0.11,0.221S125.885,78.572,125.885,78.608z M126.051,62.225c0.036,0,0.063-0.008,0.083-0.028
c0.019-0.019,0.063-0.063,0.138-0.138l-0.11-0.055l-0.055,0.055c-0.037,0.037-0.055,0.074-0.055,0.11V62.225z M126.271,78.002
l0.166-0.111c0.11-0.073,0.166-0.129,0.166-0.165c0-0.073-0.02-0.11-0.055-0.11l-0.166,0.11c-0.11,0.075-0.166,0.147-0.166,0.221
C126.216,77.982,126.234,78.002,126.271,78.002z M126.878,78.332l0.055-0.055c0.036,0,0.055-0.036,0.055-0.11l-0.11-0.056
c-0.074,0-0.11,0.056-0.11,0.166C126.768,78.314,126.804,78.332,126.878,78.332z M127.043,75.629
c0.074-0.036,0.129-0.091,0.166-0.166c-0.11,0-0.193,0.028-0.248,0.083s-0.064,0.083-0.028,0.083H127.043z M127.154,62.004
l3.31-0.276l1.875-0.166c0-0.036-0.185-0.055-0.552-0.055c-0.552,0-1.306,0.047-2.262,0.138
C128.569,61.738,127.779,61.858,127.154,62.004z M127.706,74.637l0.166-0.055c0.367-0.294,0.634-0.615,0.8-0.965
c0.166-0.35,0.377-0.67,0.634-0.965c0.257-0.294,0.386-0.478,0.386-0.552l-0.055-0.055c-0.037,0-0.092,0.037-0.166,0.11
c-0.074,0.074-0.157,0.166-0.248,0.276c-0.092,0.11-0.157,0.202-0.193,0.276c-0.037,0.074-0.064,0.119-0.083,0.138
c-0.02,0.02-0.055,0.027-0.11,0.027s-0.102,0.009-0.138,0.028c-0.037,0.019-0.055,0.047-0.055,0.083l0.055,0.11l-0.055,0.166
c-0.11,0.295-0.295,0.571-0.552,0.828C127.834,74.342,127.706,74.526,127.706,74.637z M129.084,73.809l0.11-0.11
c0.074-0.036,0.129-0.11,0.166-0.221l0.055-0.166l-0.166,0.11c-0.11,0.075-0.166,0.147-0.166,0.221v0.11V73.809z M129.857,71.768
c0.074-0.074,0.201-0.221,0.386-0.441c0.184-0.221,0.386-0.459,0.606-0.717c0.221-0.257,0.386-0.487,0.497-0.69
c0.11-0.201,0.166-0.339,0.166-0.414v-0.055c-0.037,0-0.129,0.092-0.276,0.276c-0.147,0.185-0.313,0.386-0.496,0.607
c-0.185,0.221-0.34,0.414-0.469,0.579c-0.129,0.166-0.229,0.285-0.303,0.358c-0.075,0.075-0.13,0.147-0.166,0.221
c-0.037,0.075-0.055,0.13-0.055,0.166C129.747,71.732,129.782,71.768,129.857,71.768z M129.802,72.706
c0.036,0,0.129-0.083,0.276-0.249c0.146-0.166,0.256-0.303,0.331-0.414c0.073-0.11,0.128-0.184,0.166-0.221
c0-0.036-0.02-0.055-0.055-0.055c-0.037,0-0.083,0.047-0.138,0.138c-0.055,0.092-0.166,0.24-0.331,0.441
C129.884,72.549,129.802,72.67,129.802,72.706z M130.629,71.492c0.11,0,0.22-0.091,0.331-0.276c0.11-0.184,0.239-0.377,0.386-0.579
c0.146-0.201,0.257-0.358,0.331-0.469l-0.607,0.662C130.775,71.236,130.629,71.457,130.629,71.492z M131.677,69.396
c0.036,0,0.091-0.036,0.166-0.11c0.074-0.073,0.146-0.156,0.221-0.248c0.074-0.091,0.129-0.174,0.166-0.249
c0.036-0.073,0.073-0.128,0.11-0.166v-0.055c-0.037,0-0.157,0.102-0.359,0.303c-0.202,0.202-0.303,0.358-0.303,0.469V69.396z
 M132.229,67.355c0.036-0.036,0.11-0.119,0.221-0.248c0.11-0.129,0.166-0.229,0.166-0.304h-0.055c-0.037,0-0.092,0.028-0.166,0.083
c-0.075,0.055-0.147,0.129-0.221,0.221c-0.075,0.092-0.11,0.157-0.11,0.193c0,0.037,0.019,0.055,0.055,0.055H132.229z
 M133.167,68.348l0.055-0.055c0-0.074-0.24-0.11-0.717-0.11l-0.055,0.11L133.167,68.348z M132.836,61.508l0.055-0.055l-0.331-0.055
C132.633,61.472,132.726,61.508,132.836,61.508z M132.781,66.693c0.073,0,0.138-0.046,0.193-0.138
c0.055-0.091,0.101-0.146,0.138-0.166c0.036-0.019,0.091-0.063,0.166-0.138v0.055c0.036,0,0.138-0.102,0.304-0.304
c0.166-0.201,0.248-0.395,0.248-0.579l0.055-0.11l-0.221,0.166c-0.368,0.478-0.588,0.736-0.662,0.772
c-0.185,0.22-0.275,0.368-0.275,0.441H132.781z M132.891,67.962l0.11-0.221h-0.055c-0.037,0-0.064,0-0.083,0
c-0.02,0-0.047,0.074-0.083,0.221H132.891z M133.222,62.5c0.036,0,0.055-0.018,0.055-0.055l-0.276-0.055v0.055
c0,0.037,0.036,0.055,0.11,0.055h0.055H133.222z M133.332,67.521c0-0.036,0.063-0.129,0.193-0.276
c0.128-0.146,0.256-0.312,0.386-0.497c0.128-0.184,0.248-0.349,0.358-0.496l0.166-0.221c-0.037,0-0.11,0.074-0.221,0.221
c-0.662,0.662-0.993,1.084-0.993,1.269H133.332z M134.049,61.453c0.146-0.036,0.229-0.055,0.248-0.055
c0.019,0,0.028-0.019,0.028-0.055h-0.828v0.11H134.049z M134.546,62.556l0.551-0.166v-0.055l-0.441-0.11h-0.497l-0.441,0.11
c0,0.037,0,0.083,0,0.138s0.166,0.083,0.497,0.083H134.546z M134.104,65.204l0.055-0.055c0.036,0,0.055-0.074,0.055-0.221
l0.166-0.11l-0.166,0.166c-0.11,0.075-0.166,0.147-0.166,0.221H134.104z M134.932,61.342c0.11,0,0.184-0.019,0.22-0.055
c0-0.074-0.092-0.11-0.275-0.11l-0.221,0.11C134.656,61.324,134.747,61.342,134.932,61.342z M134.987,64.045l0.165-0.11
c0.11-0.074,0.166-0.129,0.166-0.166c-0.037,0-0.102,0.019-0.193,0.055c-0.092,0.037-0.138,0.074-0.138,0.11
C134.987,63.972,134.987,64.009,134.987,64.045z M135.594,61.287c0.698-0.036,1.057-0.063,1.076-0.083
c0.019-0.018,0.046-0.036,0.083-0.055c0.036-0.019,0.063-0.036,0.083-0.055c0.019-0.019,0.046-0.046,0.083-0.083
c-0.11,0-0.406,0.028-0.883,0.083c-0.478,0.055-0.717,0.102-0.717,0.138C135.318,61.269,135.409,61.287,135.594,61.287z
 M135.759,62.335l0.166-0.22l-0.607,0.11c0,0.037,0.063,0.055,0.193,0.055C135.64,62.28,135.723,62.299,135.759,62.335z
 M138.352,60.625l0.11-0.331h-0.055c-0.11,0.11-0.166,0.203-0.166,0.276C138.242,60.607,138.277,60.625,138.352,60.625z" mask="url('#writing')"/>
  <path class="line6" d="M131.457,91.461c-0.515,0-0.993-0.129-1.434-0.386l-0.441-0.662c-1.029-1.36-1.544-2.904-1.544-4.634
c0-1.25,0.433-2.528,1.296-3.834c0.864-1.305,2.143-2.73,3.833-4.275c1.618-1.617,3.494-3.144,5.627-4.578
c1.729-1.177,3.31-1.765,4.744-1.765c0.294,0,0.57,0.02,0.828,0.055c0.846,0.662,1.269,1.471,1.269,2.427
c0,0.295-0.11,0.875-0.331,1.738c-0.221,0.864-1.002,1.857-2.345,2.979c-1.343,1.123-2.86,1.683-4.551,1.683
c-0.809,0-1.692-0.276-2.648-0.827l-0.441,0.386c-0.882,0.883-1.747,1.784-2.592,2.703c-1.104,1.214-1.876,2.206-2.317,2.979
c-0.146,0.295-0.276,0.726-0.386,1.295c-0.11,0.572-0.166,1.003-0.166,1.297c0,0.479,0.257,0.717,0.772,0.717
c0.957,0,1.949-0.221,2.979-0.662l3.641-1.654c0.184-0.11,0.294-0.221,0.331-0.331c0.036-0.11,0.166-0.257,0.386-0.441
c1.618-0.956,3.861-2.573,6.73-4.854c-0.185,0.295-0.644,0.745-1.379,1.352c-0.737,0.607-1.537,1.223-2.4,1.848
c-0.864,0.627-1.536,1.104-2.013,1.436c-0.221,0.074-0.406,0.174-0.552,0.303c-0.147,0.129-0.221,0.213-0.221,0.248
c0,0.037,0.019,0.055,0.055,0.055l0.552-0.221c0.073,0,0.11,0.02,0.11,0.056c0,0.075-0.047,0.147-0.138,0.221
c-0.092,0.075-0.138,0.13-0.138,0.165l0.827-0.386c1.139-0.661,2.096-1.379,2.869-2.151c0.477-0.441,1.011-0.883,1.6-1.324
c0.846-0.662,1.581-1.323,2.207-1.985c0.477-0.515,0.717-0.846,0.717-0.993l-0.055-0.055l-0.166,0.055
c0-0.073,0.091-0.193,0.276-0.358c0.184-0.165,0.376-0.312,0.58-0.441c0.201-0.128,0.321-0.192,0.358-0.192h0.055l-0.055,0.109
c0,0.074,0.035,0.158,0.11,0.248c0.073,0.092,0.11,0.176,0.11,0.249c0,0.405-0.828,1.343-2.482,2.813
c-0.185,0.146-0.276,0.24-0.276,0.275c-1.619,1.324-3.117,2.475-4.496,3.447c-1.379,0.975-2.546,1.537-3.503,1.684
c-0.037,0.074-0.22,0.201-0.551,0.385c-0.699,0.441-1.435,0.81-2.207,1.104c-0.295,0.074-0.441,0.147-0.441,0.221
c0,0.074,0.091,0.111,0.276,0.111c0.367,0,0.708-0.102,1.021-0.305c0.312-0.201,0.598-0.303,0.855-0.303l0.221,0.055
c0.074,0,0.193-0.035,0.358-0.109s0.359-0.146,0.579-0.221c0.221-0.074,0.469-0.174,0.745-0.304
c0.275-0.129,0.652-0.358,1.131-0.689c0.662-0.515,1.232-0.919,1.71-1.214c0.477-0.294,0.735-0.441,0.772-0.441l0.055,0.055
c0,0.037-0.027,0.075-0.083,0.111c-0.055,0.037-0.268,0.202-0.634,0.496c-0.147,0.146-0.497,0.387-1.048,0.717l-0.772,0.497
c-0.479,0.295-0.754,0.533-0.828,0.717c-0.331,0.221-0.497,0.368-0.497,0.441c-0.037,0.11-0.276,0.331-0.717,0.662l-0.551,0.331
h0.166c0.11,0,0.166,0.02,0.166,0.055c0,0.074-0.276,0.221-0.828,0.441s-0.919,0.367-1.104,0.441
c-0.626,0.331-1.526,0.607-2.703,0.828C132.045,91.424,131.752,91.461,131.457,91.461z M128.864,85.725
c1.545-3.163,3.448-5.737,5.709-7.723c2.262-1.986,4.293-3.439,6.096-4.358l2.703-1.379c-0.295,0-0.958,0.24-1.986,0.717
c-2.574,1.178-4.818,2.629-6.729,4.358c-1.03,0.919-1.857,1.766-2.482,2.537v-0.055c-0.036,0-0.063,0.009-0.083,0.027
c-0.018,0.02-0.11,0.119-0.275,0.303c-0.166,0.186-0.249,0.295-0.249,0.332l0.276-0.276c-0.883,1.067-1.6,2.061-2.151,2.979
c-0.552,0.918-0.828,1.729-0.828,2.426V85.725z M132.284,79.656l0.055-0.055c0.036-0.036,0.101-0.102,0.193-0.193
c0.091-0.092,0.174-0.193,0.249-0.303L132.284,79.656z M137.8,78.885c0.587,0,1.296-0.212,2.124-0.635
c0.827-0.422,1.608-1.049,2.344-1.876c0.735-0.827,1.159-1.498,1.269-2.013c-0.516,0.147-1.029,0.35-1.544,0.606
c-1.544,0.737-3.329,1.968-5.351,3.697C137.009,78.811,137.395,78.885,137.8,78.885z M142.213,85.229h-0.055
c-0.037,0-0.055-0.02-0.055-0.056s0.036-0.091,0.11-0.165c0.074-0.074,0.11-0.166,0.11-0.276l-0.055-0.11
c0.074,0,0.129,0.092,0.166,0.275l0.552-0.441c0.146-0.109,0.312-0.238,0.497-0.386c0.184-0.146,0.294-0.221,0.331-0.221h0.055
c-0.074,0.075-0.185,0.165-0.331,0.276c-0.147,0.109-0.22,0.185-0.22,0.221h0.055l0.496-0.497l0.662-0.331h0.055
c0,0.037-0.083,0.13-0.248,0.275c-0.166,0.148-0.396,0.323-0.69,0.525c-0.295,0.201-0.571,0.395-0.827,0.578
C142.563,85.082,142.359,85.191,142.213,85.229z" mask="url('#writing')"/>
  <path class="line7" d="M208.851,76.182c0.184,0.074,0.275,0.129,0.275,0.165s-0.055,0.074-0.165,0.11c-0.221,0.037-0.516,0.055-0.883,0.055
c-0.368,0-0.736-0.018-1.104-0.055c-0.257-0.036-0.478-0.055-0.662-0.055c-0.11-0.037-0.185-0.056-0.221-0.056
c-0.074,0-0.129,0.019-0.165,0.056c0-0.074-0.074-0.139-0.221-0.193c-0.147-0.056-0.313-0.102-0.496-0.139
c-0.186-0.035-0.313-0.055-0.387-0.055c-0.036,0-0.055,0.02-0.055,0.055c0,0.074,0.035,0.147,0.11,0.221v0.056
c-0.037,0.036-0.092,0.056-0.166,0.056s-0.157-0.009-0.248-0.028c-0.092-0.019-0.193-0.046-0.304-0.083l-0.11-0.055v-0.055h0.166
c0.073,0,0.138-0.009,0.193-0.028c0.055-0.019,0.101-0.046,0.138-0.083h0.055c-0.331-0.256-0.698-0.386-1.103-0.386
c-0.368,0-0.855,0.166-1.462,0.497c-0.607,0.33-1.076,0.882-1.407,1.654c-0.257,0.037-0.516,0.175-0.772,0.414
c-0.257,0.24-0.423,0.45-0.496,0.635c-0.295,0.074-0.516,0.165-0.662,0.275c-0.146,0.109-0.257,0.24-0.331,0.386
c-0.11,0.147-0.248,0.296-0.413,0.441c-0.166,0.147-0.47,0.257-0.91,0.331c-0.147,0.037-0.258,0.055-0.331,0.055l-0.056,0.056
c0,0.036,0.019,0.064,0.056,0.083c0.035,0.02,0.073,0.027,0.11,0.027c0.073,0,0.138,0.01,0.192,0.027
c0.056,0.02,0.083,0.047,0.083,0.083v0.11c-0.037,0.037-0.13,0.092-0.275,0.166c-0.186,0.184-0.387,0.367-0.607,0.551
c-0.331,0.258-0.57,0.533-0.717,0.828c0,0.036-0.01,0.064-0.027,0.082c-0.02,0.02-0.047,0.047-0.083,0.083
c-0.037,0-0.056,0.019-0.056,0.056v0.109c0,0.037-0.019,0.056-0.055,0.056c-0.258,0.036-0.461,0.13-0.606,0.276
c-0.147,0.146-0.276,0.313-0.387,0.496c-0.146,0.258-0.331,0.461-0.552,0.606c-1.434,1.214-2.832,2.519-4.192,3.917
c-1.434,1.545-2.905,2.904-4.413,4.082c-0.074,0.035-0.138,0.063-0.192,0.082c-0.056,0.019-0.083,0.047-0.083,0.083v0.056
l0.055,0.055c0,0.036-0.009,0.083-0.027,0.139c-0.02,0.055-0.064,0.137-0.138,0.248c-0.074,0.109-0.202,0.238-0.386,0.386
c-0.11,0.146-0.203,0.248-0.276,0.304c-0.074,0.055-0.147,0.101-0.221,0.138h-0.165c-0.037,0-0.064,0-0.083,0
c-0.02,0-0.037,0-0.056,0s-0.047,0.019-0.082,0.056c-0.589,0.771-1.195,1.488-1.82,2.15c-0.81,0.846-1.472,1.711-1.986,2.593
v-0.165c0-0.295,0.146-0.645,0.441-1.049c0.294-0.404,0.698-0.863,1.214-1.379c0.477-0.479,0.91-0.947,1.296-1.406
c0.387-0.461,0.598-0.893,0.635-1.297c0.036,0.036,0.11,0.056,0.221,0.056c0.073,0,0.146-0.028,0.221-0.083
c0.073-0.055,0.129-0.102,0.165-0.138c0-0.036-0.036-0.056-0.11-0.056c-0.185,0-0.275-0.036-0.275-0.109
c0-0.037,0.046-0.092,0.138-0.166c0.091-0.074,0.193-0.146,0.304-0.221s0.201-0.11,0.275-0.11c0.184-0.221,0.46-0.516,0.827-0.882
c0.809-0.919,1.793-1.967,2.951-3.145c1.159-1.178,2.18-2.077,3.062-2.703c-0.036-0.036-0.055-0.074-0.055-0.11
c0-0.185,0.221-0.487,0.662-0.911c0.441-0.422,1.011-0.91,1.71-1.461c0.515-0.441,0.974-0.846,1.379-1.214
c0.404-0.366,0.643-0.643,0.717-0.827v-0.055c0-0.111-0.074-0.166-0.221-0.166c-0.11,0-0.239,0.02-0.386,0.055
c0.036-0.184,0.166-0.358,0.386-0.523c0.221-0.166,0.478-0.322,0.772-0.469c0.221-0.146,0.414-0.276,0.579-0.387
c0.166-0.11,0.249-0.221,0.249-0.33c-0.037-0.037-0.056-0.074-0.056-0.111h0.056c0.035,0,0.073,0,0.11,0
c0.035,0,0.073-0.008,0.11-0.027c0.035-0.019,0.055-0.046,0.055-0.082c0.036-0.074,0.11-0.139,0.221-0.193
c0.11-0.056,0.239-0.102,0.386-0.139c0.11-0.035,0.202-0.073,0.276-0.109c0.036,0,0.055-0.02,0.055-0.056v-0.056
c0-0.035-0.074-0.091-0.221-0.165h0.056c0.11-0.036,0.211-0.063,0.304-0.083c0.091-0.018,0.156-0.082,0.192-0.193l0.056-0.221
c0-0.109-0.037-0.165-0.11-0.165c-0.037,0-0.092,0.009-0.166,0.028c-0.074,0.019-0.257,0.146-0.552,0.386s-0.598,0.505-0.91,0.8
s-0.598,0.57-0.854,0.827c-0.221,0.221-0.368,0.351-0.441,0.386c-0.11,0.111-0.185,0.166-0.221,0.166
c-0.074,0-0.119-0.019-0.138-0.055c-0.02-0.036-0.027-0.074-0.027-0.111c0-0.035-0.02-0.073-0.056-0.109l-0.055-0.056h-0.056
c-0.037,0.036-0.092,0.11-0.165,0.221c-0.075,0.075-0.11,0.13-0.11,0.165h0.165c0.036,0,0.056,0.02,0.056,0.056
c0,0.075-0.047,0.192-0.139,0.358s-0.275,0.406-0.551,0.717c-0.276,0.313-0.571,0.617-0.883,0.91
c-0.313,0.295-0.599,0.516-0.855,0.662c-0.146,0.074-0.257,0.111-0.331,0.111s-0.11-0.037-0.11-0.111
c-0.074,0.147-0.185,0.332-0.331,0.552c-0.146,0.221-0.295,0.414-0.441,0.579c-0.146,0.166-0.257,0.268-0.33,0.304h-0.056
c0-0.073,0.036-0.185,0.11-0.331c0.073,0,0.138-0.027,0.193-0.082c0.055-0.056,0.101-0.119,0.138-0.193
c0.036-0.074,0.055-0.129,0.055-0.166l-0.055-0.055l-0.11,0.055c-0.037,0-0.11,0.074-0.221,0.221
c-0.037,0.074-0.11,0.157-0.221,0.248c-0.11,0.092-0.221,0.193-0.331,0.304c-0.221,0.147-0.386,0.295-0.496,0.441
c-0.074,0.074-0.11,0.147-0.11,0.221c0,0.036,0.019,0.074,0.055,0.11h0.056c-0.037,0.037-0.092,0.055-0.166,0.055
c-0.074,0.037-0.157,0.083-0.248,0.139c-0.092,0.055-0.157,0.102-0.193,0.138v0.056c0,0.036,0.036,0.092,0.11,0.164
c0.036,0.037,0.056,0.075,0.056,0.111l-0.11,0.055c-0.074-0.035-0.13-0.073-0.166-0.11c-0.074-0.036-0.129-0.063-0.165-0.083
c-0.037-0.018-0.074,0.01-0.11,0.083v0.386c-0.552,0.296-0.938,0.589-1.158,0.883c-0.221,0.295-0.368,0.571-0.441,0.828
c-0.074,0.185-0.138,0.313-0.193,0.386c-0.055,0.075-0.175,0.11-0.358,0.11c-0.185,0-0.378,0.092-0.579,0.275
c-0.202,0.186-0.378,0.351-0.524,0.497c-0.146,0.185-0.239,0.276-0.275,0.276c-0.221,0.109-0.351,0.165-0.387,0.165V89.42
c0-0.035,0.036-0.109,0.11-0.221c0.074-0.109,0.185-0.256,0.331-0.441c0.146-0.184,0.276-0.35,0.387-0.496
c0.11-0.11,0.165-0.184,0.165-0.221v-0.055l-0.055-0.056c0-0.036,0.035-0.056,0.11-0.056h0.11c0.221-0.072,0.487-0.248,0.8-0.523
c0.312-0.275,0.606-0.57,0.883-0.883c0.275-0.312,0.449-0.579,0.523-0.8c-0.037,0-0.064,0.009-0.083,0.028
c-0.019,0.019-0.047,0.047-0.082,0.082c0,0.037,0,0.064,0,0.083s-0.01,0.027-0.028,0.027s-0.063,0-0.138,0
c-0.036,0-0.064,0-0.083,0s-0.027,0.02-0.027,0.056c0,0.037,0,0.074,0,0.109c0,0.037,0,0.075,0,0.111s-0.036,0.055-0.11,0.055
c-0.036-0.035-0.092-0.055-0.165-0.055c-0.11,0-0.248,0.064-0.414,0.193c-0.165,0.129-0.358,0.322-0.579,0.578
c-0.221,0.221-0.423,0.406-0.606,0.553c-0.186,0.109-0.351,0.164-0.497,0.164h-0.11c-0.036,0-0.082,0.028-0.138,0.084
c-0.055,0.055-0.083,0.109-0.083,0.165s0,0.103,0,0.138v0.055c0.036,0,0.063,0,0.083,0c0.019,0,0.063-0.035,0.138-0.109
s0.129-0.111,0.166-0.111l0.055-0.055l0.056,0.11c0,0.037-0.037,0.083-0.11,0.138c-0.074,0.056-0.175,0.12-0.304,0.193
c-0.13,0.074-0.248,0.13-0.358,0.166s-0.185,0.055-0.221,0.055c0-0.035,0.036-0.091,0.11-0.166l0.165-0.275
c-0.036,0-0.129,0.074-0.275,0.221c-0.147,0.146-0.313,0.322-0.496,0.523c-0.186,0.203-0.341,0.387-0.47,0.553
c-0.129,0.165-0.192,0.268-0.192,0.303c0.035,0,0.129-0.073,0.275-0.221c0.11-0.109,0.184-0.165,0.221-0.165l-0.055,0.165v0.056
c0.035,0,0.082-0.019,0.138-0.056c0.055-0.035,0.101-0.091,0.138-0.165c0.11-0.11,0.201-0.202,0.276-0.276
c0.073-0.073,0.128-0.109,0.165-0.109h0.055v0.055c-0.036,0.074-0.109,0.175-0.22,0.303c-0.11,0.131-0.24,0.268-0.387,0.414
c-0.147,0.147-0.275,0.268-0.386,0.359s-0.186,0.137-0.221,0.137l0.11-0.165c0.073-0.073,0.11-0.128,0.11-0.165v-0.056l-0.166,0.11
c-0.074,0.037-0.129,0.055-0.165,0.055h-0.056v-0.165h0.056l-0.056-0.056l-0.165,0.166c-0.074,0.11-0.12,0.203-0.138,0.275
c-0.02,0.075-0.028,0.131-0.028,0.166c0,0.037,0.019,0.064,0.056,0.083c0.036,0.019,0.073,0.027,0.11,0.027h0.055v0.11
c0,0.037-0.047,0.119-0.138,0.248c-0.092,0.13-0.229,0.285-0.414,0.469l0.166-0.33c0.11-0.295,0.165-0.478,0.165-0.553l-0.11-0.055
c-0.036,0-0.082,0.027-0.138,0.083c-0.055,0.056-0.146,0.138-0.275,0.248c-0.13,0.11-0.23,0.212-0.304,0.304
c-0.074,0.092-0.11,0.156-0.11,0.193h0.056c0.035,0,0.11-0.037,0.221-0.111l0.165-0.165l-0.055,0.11
c-0.075,0.074-0.139,0.157-0.193,0.248c-0.056,0.092-0.083,0.157-0.083,0.193h0.166l0.055-0.055l0.056,0.055
c0,0.035-0.01,0.073-0.028,0.11c-0.02,0.036-0.047,0.056-0.082,0.056h-0.056c-0.037,0-0.074,0-0.11,0
c-0.037,0-0.055-0.037-0.055-0.111c-0.037-0.109-0.075-0.165-0.11-0.165c-0.037,0-0.103,0.047-0.193,0.138
c-0.092,0.092-0.248,0.248-0.469,0.469l-0.166,0.166c0.11-0.186,0.312-0.451,0.607-0.801c0.294-0.349,0.634-0.752,1.021-1.213
c0.386-0.46,0.78-0.91,1.186-1.352c0.404-0.441,0.764-0.818,1.076-1.131c0.312-0.312,0.523-0.487,0.634-0.523h0.056
c0.035,0,0.082-0.009,0.138-0.028c0.055-0.019,0.11-0.056,0.165-0.11c0.056-0.055,0.083-0.119,0.083-0.193l0.056-0.055
c-0.037,0-0.093,0.02-0.166,0.055l-0.11,0.056h-0.055v-0.056c0-0.035,0.019-0.073,0.055-0.109v-0.166l-0.055,0.055
c0,0.037-0.02,0.075-0.056,0.111c-0.074,0.074-0.146,0.129-0.221,0.165s-0.129,0.056-0.165,0.056v-0.221
c0-0.036-0.027-0.047-0.083-0.028c-0.055,0.02-0.147,0.064-0.275,0.138c-0.13,0.074-0.258,0.158-0.387,0.248
c-0.13,0.093-0.229,0.166-0.303,0.221c-0.075,0.056-0.11,0.103-0.11,0.139c0.035,0.037,0.082,0.055,0.138,0.055
c0.055,0,0.101,0,0.138,0c0,0.037-0.02,0.064-0.055,0.083c-0.037,0.02-0.093,0.064-0.166,0.138l-0.055,0.055
c-0.075,0.037-0.13,0.056-0.166,0.056l-0.11,0.056c0.036-0.036,0.073-0.056,0.11-0.056c0-0.036,0.019-0.091,0.056-0.165
l0.055-0.055c0-0.037-0.037-0.056-0.11-0.056h-0.055c-0.552,0.257-1.27,0.606-2.151,1.048c-0.883,0.441-1.582,0.957-2.097,1.545
c0.073-0.036,0.146-0.056,0.221-0.056c0.221-0.11,0.367-0.165,0.441-0.165c0,0.037-0.165,0.221-0.496,0.551L180,90.303
c0.036,0,0.083-0.008,0.138-0.027c0.056-0.019,0.102-0.046,0.139-0.083h0.055c0.184-0.11,0.294-0.165,0.331-0.165l-0.056,0.055
c-0.036,0.074-0.102,0.139-0.192,0.193c-0.092,0.055-0.185,0.11-0.276,0.166c-0.092,0.055-0.175,0.082-0.248,0.082v-0.055
l-0.055-0.056c-0.037-0.036-0.083-0.063-0.139-0.083c-0.055-0.018-0.092-0.027-0.11-0.027s-0.027,0.02-0.027,0.055
c0,0.037-0.027,0.075-0.083,0.111c-0.055,0.036-0.157,0.102-0.303,0.193c-0.147,0.092-0.295,0.192-0.441,0.303
c-0.185,0.11-0.351,0.202-0.496,0.275l-0.166,0.166c0.036,0,0.146-0.036,0.331-0.11c0.735-0.441,1.122-0.662,1.158-0.662
c0,0.036-0.193,0.192-0.579,0.469s-0.91,0.598-1.572,0.966c-0.11,0.073-0.202,0.129-0.275,0.165
c-0.074,0.036-0.13,0.056-0.166,0.056h-0.055v-0.056c0-0.074,0-0.138,0-0.192c0-0.056-0.037-0.103-0.11-0.139l-0.11-0.055
l-0.056,0.055c-0.036,0.036-0.055,0.092-0.055,0.166c0,0.035-0.01,0.073-0.027,0.109c-0.02,0.037-0.047,0.056-0.083,0.056
c-0.037,0-0.092-0.019-0.166-0.056h-0.22c-0.258,0-0.497,0.037-0.718,0.111c-0.221,0.073-0.441,0.184-0.662,0.33
c-0.257,0.146-0.57,0.276-0.938,0.387c-0.368,0.11-0.847,0.184-1.435,0.221c-1.25,0.441-2.096,0.771-2.537,0.992
c-0.331,0.146-0.496,0.24-0.496,0.276s0.035,0.056,0.11,0.056h0.11c0.184,0,0.532-0.064,1.048-0.193
c0.515-0.13,1.139-0.304,1.875-0.523c0.736-0.221,1.435-0.461,2.097-0.718s1.177-0.516,1.545-0.772c0,0.036-0.02,0.063-0.056,0.083
c-0.037,0.019-0.074,0.046-0.11,0.083c-0.074,0-0.13,0.018-0.165,0.055c-0.037,0.036-0.056,0.074-0.056,0.11v0.11
c0,0.035-0.009,0.063-0.027,0.082c-0.02,0.02-0.064,0.028-0.138,0.028c-0.075,0-0.139,0-0.193,0c-0.056,0-0.103,0.027-0.138,0.083
c-0.037,0.055-0.056,0.138-0.056,0.248s0.019,0.166,0.056,0.166h0.11c0.035,0,0.091,0,0.165,0c0.073,0,0.146-0.02,0.221-0.056
l0.056-0.056v0.056c-0.037,0.036-0.093,0.073-0.166,0.11c-0.221,0.221-0.331,0.367-0.331,0.441v0.055
c0.036,0,0.193-0.064,0.469-0.193c0.276-0.129,0.579-0.285,0.91-0.469c0.221-0.146,0.414-0.257,0.579-0.33
c0.166-0.075,0.268-0.111,0.304-0.111c0,0.036-0.074,0.111-0.221,0.221c-1.985,1.25-3.916,2.354-5.792,3.311
c-1.509,0.771-2.868,1.158-4.082,1.158c-0.883,0-1.931-0.367-3.145-1.104c-1.213-0.736-2.06-2.207-2.537-4.413
c-0.185-0.772-0.276-1.563-0.276-2.372c0-1.47,0.313-2.959,0.938-4.468l-0.056-0.056c0-0.035,0.009-0.082,0.028-0.138
c0.018-0.056,0.027-0.118,0.027-0.192l0.055-0.056h0.056l0.11,0.11v-0.166c-0.037-0.035-0.056-0.073-0.056-0.109
c0-0.037,0-0.064,0-0.084c0-0.018,0.019-0.027,0.056-0.027c0.073,0,0.138-0.055,0.192-0.165c0.056-0.11,0.102-0.239,0.139-0.386
c0.035-0.146,0.091-0.285,0.165-0.414c0.073-0.129,0.166-0.193,0.276-0.193l0.055-0.055c0-0.036,0-0.063,0-0.083
c0-0.019-0.02-0.046-0.055-0.083c-0.037-0.035-0.064-0.073-0.083-0.109c-0.02-0.037-0.01-0.092,0.027-0.166
c0.073-0.074,0.129-0.119,0.166-0.139c0.035-0.018,0.063-0.018,0.082,0c0.019,0.02,0.027,0.047,0.027,0.084h0.11
c0.036,0,0.083-0.028,0.139-0.084c0.055-0.055,0.118-0.156,0.192-0.303c0.221-0.404,0.331-0.643,0.331-0.717
c-0.036,0-0.146,0.109-0.331,0.33c-0.11,0.258-0.185,0.387-0.221,0.387s-0.055-0.019-0.055-0.055v-0.056
c0-0.11,0.055-0.294,0.165-0.552c0.11-0.256,0.304-0.56,0.58-0.91c0.275-0.35,0.652-0.615,1.13-0.8l-0.055,0.11
c-0.037,0.11-0.092,0.221-0.165,0.331c-0.075,0.11-0.157,0.212-0.249,0.304s-0.157,0.138-0.192,0.138h-0.056
c-0.037,0-0.055-0.036-0.055-0.11v-0.055h-0.056c-0.036,0.036-0.064,0.074-0.083,0.109c-0.019,0.037-0.027,0.074-0.027,0.111
c0,0.036,0.019,0.055,0.056,0.055c0.035,0.074,0.055,0.166,0.055,0.275c0,0.11-0.064,0.295-0.193,0.552s-0.303,0.571-0.523,0.938
c-0.185,0.221-0.331,0.441-0.441,0.661c-0.11,0.221-0.185,0.386-0.221,0.497v0.165h0.056c0.035,0,0.063-0.019,0.082-0.056
c0.019-0.035,0.027-0.09,0.027-0.165c0.036-0.036,0.074-0.091,0.11-0.165c0.185-0.295,0.387-0.598,0.607-0.91
s0.477-0.505,0.772-0.58h0.055l-0.055-0.221c0-0.035,0.008-0.063,0.027-0.082c0.019-0.019,0.063-0.027,0.138-0.027
c0.036-0.036,0.063-0.074,0.083-0.111c0.019-0.035,0.027-0.09,0.027-0.165l0.056-0.11h-0.056c-0.11,0.037-0.202,0.074-0.275,0.11
c-0.037,0.037-0.093,0.056-0.166,0.056l-0.055,0.055l0.055-0.166c0.073-0.072,0.166-0.184,0.276-0.33
c0.184-0.184,0.366-0.441,0.551-0.772c0.11-0.146,0.221-0.276,0.331-0.386l0.11-0.111h0.056c0.036,0.037,0.063,0.056,0.083,0.056
c0.018,0,0.046,0,0.082,0l0.056-0.056c0.036,0,0.055,0.02,0.055,0.056s-0.019,0.075-0.055,0.11v0.055l0.11-0.055
c0.073-0.035,0.156-0.129,0.248-0.275c0.091-0.146,0.184-0.295,0.275-0.441s0.156-0.275,0.193-0.387v-0.221
c0.036-0.109,0.056-0.184,0.056-0.221v-0.055l-0.056,0.055c-0.037,0-0.055,0.02-0.055,0.056c-0.037,0.074-0.083,0.13-0.139,0.165
c-0.055,0.037-0.119,0.056-0.192,0.056c0-0.036,0.027-0.11,0.082-0.221c0.056-0.11,0.139-0.239,0.249-0.386
s0.229-0.276,0.358-0.387c0.129-0.11,0.211-0.166,0.248-0.166h0.11v-0.055c0-0.035-0.02-0.055-0.056-0.055
c-0.036-0.036-0.063-0.074-0.082-0.111c-0.02-0.035-0.028-0.063-0.028-0.082c0-0.019,0.036-0.046,0.11-0.083
c0.036-0.036,0.074-0.056,0.11-0.056l0.056-0.055v0.055c0.036,0.037,0.055,0.064,0.055,0.084c0,0.019,0.019,0.027,0.056,0.027
c0.036,0,0.073-0.019,0.11-0.056h0.055l-0.055,0.11c0,0.037-0.037,0.11-0.11,0.221c-0.074,0.11-0.147,0.221-0.221,0.331
c-0.11,0.147-0.193,0.268-0.248,0.358c-0.056,0.092-0.083,0.157-0.083,0.193l0.11-0.11c0.11-0.036,0.201-0.11,0.275-0.221
s0.146-0.239,0.221-0.386c0.146-0.221,0.295-0.404,0.441-0.553c0.036-0.072,0.091-0.109,0.166-0.109
c0.035,0,0.091,0.037,0.165,0.109c-0.221,0.332-0.496,0.699-0.827,1.104c-0.736,0.957-1.324,1.912-1.766,2.869
c0.074-0.184,0.174-0.313,0.304-0.387c0.129-0.073,0.229-0.109,0.304-0.109c0.073-0.037,0.118-0.064,0.138-0.084
c0.019-0.018,0.027-0.045,0.027-0.082l-0.055-0.055c0-0.037,0.018-0.064,0.055-0.084c0.036-0.018,0.073-0.045,0.11-0.082
c0.036,0,0.073-0.019,0.11-0.055v-0.166c0-0.074,0.027-0.156,0.083-0.248c0.055-0.092,0.138-0.156,0.248-0.193
c0.073,0,0.118-0.018,0.138-0.055c0.019-0.036,0.027-0.064,0.027-0.083s0-0.046,0-0.083v-0.11l0.056,0.056
c0.11-0.074,0.229-0.193,0.358-0.359c0.129-0.165,0.248-0.339,0.358-0.523c0.221-0.367,0.422-0.643,0.607-0.828
c-0.075-0.035-0.11-0.072-0.11-0.109c0-0.036,0.046-0.119,0.138-0.248c0.091-0.129,0.257-0.305,0.496-0.524
c0.239-0.221,0.487-0.441,0.745-0.662c0.221-0.221,0.404-0.386,0.552-0.497c0.11-0.11,0.165-0.184,0.165-0.221v-0.11
c0-0.184,0.146-0.459,0.441-0.827c0.294-0.367,0.625-0.717,0.993-1.048c0.366-0.331,0.625-0.515,0.772-0.552v-0.055l0.055-0.165
c0.036-0.036,0.056-0.074,0.056-0.11v-0.11l0.055,0.055c0.036,0.074,0.073,0.11,0.11,0.11c0.036,0,0.056-0.019,0.056-0.055v-0.386
c0.035,0,0.073,0.037,0.11,0.11c0.035,0.074,0.073,0.11,0.109,0.11s0.074-0.036,0.11-0.11c0.036-0.074,0.083-0.119,0.139-0.138
c0.055-0.018,0.101-0.046,0.138-0.083c0.036,0,0.073-0.008,0.11-0.028c0.036-0.018,0.055-0.046,0.055-0.083
c-0.036-0.036-0.092-0.046-0.165-0.028c-0.074,0.02-0.13,0.047-0.166,0.083c-0.074,0-0.146,0.019-0.221,0.055
c0-0.036,0.009-0.074,0.028-0.11c0.019-0.036,0.082-0.11,0.192-0.221l0.441-0.441h0.056l0.055,0.055
c0,0.037,0.019,0.055,0.056,0.055v0.055c0.036,0,0.083-0.019,0.138-0.055c0.056-0.036,0.118-0.11,0.193-0.221
c0.11-0.11,0.165-0.184,0.165-0.221l-0.165,0.055h-0.11c0-0.036,0.035-0.083,0.11-0.138c0.073-0.055,0.156-0.129,0.248-0.221
c0.091-0.091,0.174-0.174,0.248-0.248l0.11-0.055l0.11-0.11c0,0.037-0.02,0.083-0.055,0.138c-0.037,0.055-0.075,0.12-0.11,0.193
c-0.037,0.037-0.075,0.074-0.11,0.11c-0.037,0.037-0.056,0.074-0.056,0.11c0.404-0.331,0.681-0.496,0.827-0.496h0.056
c0.073,0.037,0.11,0.092,0.11,0.166c0,0.074-0.027,0.202-0.083,0.386c-0.055,0.185-0.202,0.396-0.441,0.635
c-0.239,0.24-0.506,0.395-0.8,0.469c0,0.037-0.027,0.092-0.083,0.166c-0.055,0.074-0.129,0.166-0.22,0.276
c-0.093,0.11-0.193,0.221-0.304,0.331c-0.11,0.147-0.221,0.275-0.331,0.386l-0.11,0.166l0.165-0.055l0.11-0.166
c0,0.037-0.019,0.11-0.055,0.221c-0.037,0.11-0.11,0.175-0.221,0.193c-0.11,0.019-0.221,0.027-0.331,0.027h-0.331
c0,0.037-0.02,0.083-0.055,0.138c-0.037,0.055-0.075,0.102-0.11,0.138c-0.11,0.11-0.202,0.221-0.276,0.331
c-0.074,0.074-0.11,0.129-0.11,0.166c0,0.037,0.019,0.074,0.056,0.11l-0.056,0.055c-0.036,0-0.064,0-0.082,0
c-0.02,0-0.037,0-0.056,0c-0.02,0-0.027,0.037-0.027,0.11c-0.037,0.11-0.092,0.202-0.166,0.276
c-0.074,0.074-0.146,0.129-0.221,0.165c-0.074,0.037-0.138,0.083-0.192,0.138c-0.056,0.055-0.083,0.12-0.083,0.193
c-0.185,0.037-0.351,0.147-0.496,0.331c-0.147,0.185-0.285,0.378-0.414,0.58c-0.13,0.202-0.213,0.358-0.248,0.469
c0.035,0.074,0.055,0.129,0.055,0.166c0,0.074-0.02,0.137-0.055,0.192c-0.037,0.056-0.092,0.092-0.166,0.11
c-0.074,0.02-0.165,0.027-0.275,0.027c-0.074,0-0.147,0-0.221,0c-0.074,0-0.13,0.02-0.166,0.055c0,0.075-0.027,0.12-0.082,0.139
c-0.056,0.02-0.12,0.027-0.193,0.027h-0.11l0.056,0.055l0.22,0.111c0,0.037,0,0.064,0,0.082c0,0.02-0.074,0.064-0.22,0.139
c-0.441,0.588-0.81,1.232-1.104,1.93c-0.074,0.075-0.13,0.111-0.166,0.111v-0.056l0.11-0.165v-0.056
c-0.036,0.037-0.082,0.083-0.138,0.138c-0.055,0.056-0.11,0.119-0.165,0.193c-0.056,0.074-0.103,0.146-0.139,0.221
s-0.055,0.146-0.055,0.221c0,0.037,0.019,0.055,0.055,0.055c0.036,0.037,0.056,0.074,0.056,0.111c0,0.036-0.074,0.055-0.221,0.055
h-0.11c-0.037,0-0.074,0.02-0.11,0.055c-0.037,0.037-0.074,0.074-0.11,0.111l-0.11,0.109h-0.165
c-0.037,0.037-0.064,0.119-0.083,0.248c-0.02,0.131-0.027,0.285-0.027,0.469v0.441c-0.037-0.035-0.083-0.063-0.139-0.082
c-0.055-0.019-0.11-0.019-0.165,0c-0.056,0.02-0.13,0.092-0.221,0.221c-0.092,0.129-0.193,0.322-0.304,0.579
c-0.074,0.147-0.11,0.239-0.11,0.276l-0.055,0.055h0.11c0.036-0.036,0.063-0.074,0.083-0.11c0.018-0.036,0.046-0.056,0.082-0.056
c-0.295,0.589-0.626,1.178-0.993,1.766c-0.588,0.992-1.012,1.875-1.269,2.648c-0.11,0.33-0.239,0.605-0.386,0.826
c-0.147,0.186-0.258,0.379-0.331,0.58c-0.074,0.202-0.11,0.469-0.11,0.8v0.11c-0.037,0-0.074,0.009-0.11,0.027
c-0.037,0.02-0.074,0.064-0.11,0.138c-0.037,0.075-0.055,0.165-0.055,0.276c0,0.109,0.018,0.275,0.055,0.496v0.166
c0,0.074-0.009,0.165-0.027,0.275c-0.02,0.11-0.064,0.24-0.138,0.387c-0.075,0.221-0.13,0.422-0.166,0.605v0.111
c0,0.146,0.056,0.256,0.166,0.33c0.035,0.994,0.44,1.637,1.213,1.932c0.552,0.184,1.122,0.275,1.71,0.275
c0.295,0,0.764-0.037,1.407-0.11c0.643-0.074,1.204-0.239,1.683-0.497c4.669-2.206,9.368-4.908,14.094-8.108
c4.725-3.199,8.89-6.288,12.494-9.267c-0.074,0-0.11-0.02-0.11-0.056c0.036,0,0.129-0.019,0.276-0.056
c0.146-0.035,0.284-0.073,0.413-0.109c0.129-0.037,0.249-0.092,0.359-0.166c0.035-0.036,0.055-0.074,0.055-0.11
s-0.037-0.091-0.11-0.166c-0.037-0.036-0.138-0.055-0.304-0.055c-0.165,0-0.34,0.009-0.523,0.028
c-0.185,0.019-0.331,0.047-0.441,0.083c-0.11,0.074-0.166,0.129-0.166,0.166c0,0.036-0.009,0.055-0.027,0.055
c-0.02,0-0.055,0-0.11,0c-0.055,0-0.119,0-0.192,0c-0.075,0-0.139-0.008-0.193-0.027c-0.056-0.019-0.103-0.009-0.138,0.027v0.055
c0,0.037-0.028,0.056-0.083,0.056c-0.056,0-0.13,0-0.221,0c-0.092,0-0.193,0-0.304,0c-0.146,0-0.268,0-0.358,0
c-0.092,0-0.138,0.019-0.138,0.056c-0.368,0-0.552-0.02-0.552-0.056h0.11c0.11-0.036,0.441-0.11,0.993-0.221
c0.477-0.073,1.021-0.174,1.627-0.303c0.606-0.129,1.131-0.267,1.572-0.414c0.441-0.146,0.681-0.294,0.717-0.441
c-2.758,0.11-5.498,0.441-8.219,0.993c-2.723,0.551-5.277,1.454-7.668,2.704l-0.055,0.055c0,0.037,0.035,0.055,0.11,0.055
c0.146,0,0.358-0.045,0.634-0.138c0.276-0.091,0.561-0.174,0.855-0.248c0.294-0.11,0.552-0.185,0.772-0.221
c0.146-0.036,0.238-0.056,0.275-0.056h0.055c0.11-0.035,0.239-0.09,0.387-0.165c0.331-0.11,0.689-0.229,1.075-0.358
c0.387-0.129,0.764-0.229,1.131-0.304h0.276c0.221,0,0.386,0.037,0.496,0.11c0.11-0.073,0.184-0.129,0.221-0.166
c0.036-0.035,0.073-0.055,0.11-0.055l0.056,0.055c0.035,0.037,0.073,0.075,0.11,0.111c0.035,0.037,0.091,0.055,0.165,0.055h0.331
c-0.295,0.074-0.635,0.166-1.021,0.275c-0.386,0.111-0.745,0.221-1.076,0.332c0.146,0,0.414-0.036,0.801-0.111
c0.386-0.073,0.817-0.165,1.296-0.275c0.441-0.073,0.836-0.146,1.186-0.221c0.35-0.073,0.615-0.11,0.8-0.11
c0.146,0,0.221,0.02,0.221,0.056l-0.055,0.055c-0.221,0.147-0.441,0.258-0.662,0.331c-0.11,0-0.221,0.02-0.331,0.056
c-0.11,0.074-0.165,0.129-0.165,0.165s0.027,0.075,0.082,0.11c0.056,0.037,0.102,0.074,0.139,0.11h0.055l0.056,0.056h-0.11
c-0.037,0-0.103,0.019-0.193,0.055c-0.092,0.037-0.175,0.074-0.248,0.11c-0.11,0.074-0.202,0.12-0.276,0.138
c-0.074,0.02-0.129,0.027-0.165,0.027c0.294-0.184,0.441-0.33,0.441-0.441c-0.037-0.035-0.13-0.055-0.276-0.055
c-0.036,0-0.157,0.02-0.358,0.055c-0.202,0.037-0.496,0.103-0.883,0.193c-0.386,0.092-0.736,0.203-1.048,0.331
c-0.313,0.13-0.543,0.229-0.689,0.304c-0.037,0.037-0.055,0.074-0.055,0.11s0.073,0.056,0.22,0.056
c-0.551,0.22-1.323,0.516-2.316,0.882c-1.802,0.589-3.696,1.36-5.682,2.317c-1.986,0.957-3.255,2.133-3.807,3.529
c-1.471-0.955-2.206-1.855-2.206-2.702c0-0.331,0.229-0.863,0.689-1.601c0.46-0.734,1.765-1.563,3.916-2.481
s4.366-1.701,6.647-2.345c2.28-0.643,3.972-1.076,5.075-1.296c0.184-0.036,0.331-0.073,0.441-0.11
c0.477-0.146,0.883-0.239,1.214-0.276l0.496-0.055l0.496,0.055c0.405,0,0.919-0.018,1.545-0.055
c0.662-0.11,1.122-0.201,1.379-0.275c0.073,0,0.11-0.019,0.11-0.055h-0.938l0.221-0.055c0.184-0.074,0.35-0.11,0.496-0.11
c0.073,0,0.156,0.009,0.248,0.027c0.092,0.02,0.193,0.064,0.304,0.138c0.11,0.037,0.221,0.055,0.331,0.055s0.239-0.074,0.386-0.221
c1.397,0,2.436-0.202,3.117-0.607c0.681-0.404,1.167-0.791,1.462-1.158c0.256-0.331,0.496-0.533,0.717-0.607h0.11
c0.221,0,0.487,0.147,0.8,0.441c0.312,0.295,0.78,0.919,1.407,1.875c0.184,0.037,0.275,0.092,0.275,0.166l-0.055,0.055l0.165,0.11
c0.11,0.037,0.257,0.083,0.441,0.138c0.184,0.055,0.377,0.102,0.579,0.138c0.201,0.037,0.358,0.055,0.469,0.055h0.166
c0.221,0.075,0.422,0.13,0.606,0.166c0.184,0.037,0.35,0.055,0.496,0.055c0.221,0.037,0.478,0.075,0.772,0.11
c0.184,0.11,0.276,0.185,0.276,0.221c0,0.037-0.037,0.055-0.11,0.055c-0.11,0-0.368-0.036-0.772-0.11
c-0.405-0.073-0.847-0.166-1.324-0.276s-0.93-0.201-1.352-0.276c-0.423-0.073-0.764-0.11-1.021-0.11h-0.165
c-0.11,0-0.166,0.019-0.166,0.055l0.056,0.055c-0.037,0.037-0.056,0.075-0.056,0.11c0,0.11,0.185,0.212,0.552,0.304
c0.367,0.092,0.891,0.193,1.572,0.303c0.681,0.11,1.352,0.193,2.014,0.248c0.662,0.055,1.194,0.102,1.6,0.138
C208.666,76.126,208.813,76.145,208.851,76.182z M163.065,87.324v0.166l0.055-0.111c0.036-0.035,0.073-0.102,0.11-0.193
c0.036-0.09,0.056-0.174,0.056-0.248l0.055-0.109l-0.055,0.055c-0.037,0.037-0.075,0.102-0.11,0.193
C163.139,87.168,163.101,87.25,163.065,87.324z M163.396,89.916l0.055,0.332c-0.037,0-0.055-0.019-0.055-0.056
c0-0.036,0-0.091,0-0.165V89.916z M164.334,84.566c-0.221,0.441-0.331,0.698-0.331,0.771v0.056l0.11-0.165
c0.11-0.111,0.248-0.313,0.414-0.607c0.165-0.295,0.33-0.588,0.496-0.883c0.165-0.294,0.304-0.551,0.414-0.771
s0.165-0.35,0.165-0.387c-0.11,0-0.295,0.202-0.552,0.607C164.794,83.592,164.555,84.051,164.334,84.566z M176.194,67.796h-0.166
c-0.479,0-1.223,0.497-2.234,1.489c-1.012,0.993-2.16,2.271-3.447,3.834c-1.288,1.564-2.428,3.025-3.42,4.385
c-0.993,1.36-1.637,2.243-1.931,2.647c3.456-5.736,7.713-10.775,12.77-15.114c5.056-4.338,10.344-8.145,15.859-11.418
c2.243-1.177,4.523-2.151,6.841-2.924c-0.075-0.074-0.166-0.11-0.276-0.11c-0.146,0-0.386,0.047-0.717,0.138
c-0.331,0.092-0.717,0.212-1.158,0.359c-0.479,0.185-0.81,0.276-0.993,0.276c0.331-0.184,0.67-0.339,1.021-0.469
c0.35-0.128,0.635-0.229,0.855-0.303l0.386-0.055c0.883-0.404,1.729-0.607,2.537-0.607c0.846,0,1.812,0.331,2.896,0.993
c1.084,0.662,1.737,1.802,1.958,3.42c0.036,0.221,0.056,0.441,0.056,0.662c0,1.398-0.736,2.722-2.207,3.972
c-1.25,1.434-3.053,3.292-5.406,5.571c-1.839,1.766-3.364,3.292-4.578,4.579c-0.147,0.257-0.331,0.386-0.552,0.386
c-0.11,0-0.285-0.055-0.523-0.166c-0.24-0.11-0.524-0.349-0.855-0.717c-0.331-0.367-0.552-0.68-0.662-0.938
c1.177-1.47,2.887-3.163,5.13-5.075c1.691-1.508,3.273-3.015,4.744-4.523c1.471-1.507,2.446-2.923,2.924-4.248h-0.883
c-1.509,0-3.07,0.276-4.688,0.828c-1.619,0.552-3.108,1.233-4.468,2.041c-0.441,0.257-0.92,0.497-1.435,0.717
c-0.405,0.185-0.754,0.35-1.048,0.497c-0.186,0.11-0.323,0.22-0.414,0.331c-0.092,0.11-0.175,0.221-0.248,0.331
c-0.074,0.147-0.166,0.276-0.276,0.386s-0.275,0.166-0.496,0.166c-0.074-0.036-0.147-0.055-0.221-0.055
c-0.074,0-0.147,0.037-0.221,0.11c-0.074,0.074-0.147,0.185-0.221,0.331c-0.074,0.185-0.165,0.313-0.275,0.386
c-0.075,0.11-0.221,0.212-0.441,0.303c-0.221,0.092-0.461,0.193-0.718,0.303c-0.185,0.037-0.35,0.083-0.496,0.138
c-0.147,0.055-0.24,0.12-0.275,0.193c-0.037,0-0.056,0.02-0.056,0.055l0.056,0.055c0.221-0.074,0.366-0.128,0.441-0.166h0.055
c0,0.037,0,0.064,0,0.083c0,0.019-0.027,0.055-0.083,0.11c-0.055,0.055-0.119,0.102-0.192,0.138
c-0.075,0.037-0.147,0.075-0.221,0.11c-0.037,0-0.139,0.037-0.304,0.11c-0.165,0.074-0.351,0.157-0.552,0.248
c-0.202,0.092-0.396,0.193-0.579,0.304c-0.185,0.11-0.331,0.202-0.441,0.276l-0.055,0.055c0,0.037,0.035,0.055,0.11,0.055
c0.035,0,0.055,0.037,0.055,0.11c0,0.037-0.064,0.13-0.193,0.276c-0.129,0.147-0.35,0.331-0.661,0.552
c-0.313,0.221-0.616,0.441-0.911,0.662c-0.146,0.11-0.275,0.202-0.386,0.276c-0.11,0.074-0.185,0.129-0.221,0.166h-0.055
c-0.037,0-0.075-0.019-0.11-0.055c-0.037-0.036-0.075-0.074-0.11-0.11c-0.037-0.036-0.075-0.055-0.11-0.055
c-0.037,0-0.092,0.037-0.166,0.11c-0.11,0.074-0.157,0.129-0.138,0.165c0.019,0.037,0.083,0.075,0.193,0.11
c0.11,0.037,0.165,0.075,0.165,0.11c0,0.037,0,0.075,0,0.11c0,0.037-0.047,0.075-0.138,0.11c-0.092,0.037-0.193,0.055-0.304,0.055
s-0.202-0.036-0.275-0.11c-0.589,0.368-1.288,0.938-2.097,1.71c-0.847,0.919-1.674,1.619-2.482,2.097
c-0.074,0.22-0.257,0.478-0.551,0.772c-0.296,0.295-0.589,0.497-0.883,0.607c-0.147,0.074-0.258,0.11-0.331,0.11
c-0.185,0-0.313-0.074-0.387-0.221v-0.166c-0.036,0-0.109,0.02-0.22,0.055c-0.406,0.295-0.662,0.441-0.772,0.441
c-0.037,0-0.056-0.019-0.056-0.055c0-0.184,0.46-0.662,1.379-1.435l0.607-0.606c0.184-0.221,0.377-0.422,0.579-0.607
c0.201-0.184,0.469-0.294,0.8-0.331c0.036,0,0.055,0.02,0.055,0.055v0.055c-0.036,0.075-0.11,0.157-0.221,0.249
c-0.11,0.092-0.22,0.193-0.33,0.303c-0.11,0.074-0.213,0.147-0.304,0.221c-0.092,0.074-0.157,0.129-0.193,0.166v0.055l0.056,0.055
c0.035,0,0.101-0.036,0.192-0.11c0.092-0.074,0.193-0.166,0.304-0.276c0.184-0.11,0.331-0.202,0.441-0.276
c0.073-0.074,0.129-0.11,0.165-0.11h0.056l0.055,0.055c0.036,0,0.063,0,0.083,0c0.019,0,0.056-0.028,0.11-0.083
c0.056-0.055,0.102-0.119,0.138-0.193l0.056-0.055l-0.056-0.055l-0.11,0.055c0-0.11,0.009-0.184,0.028-0.221
c0.018-0.036,0.055-0.055,0.11-0.055c0.055,0,0.101,0,0.138,0c0.035,0.037,0.073,0.055,0.11,0.055c0.073,0,0.118-0.018,0.138-0.055
c0.019-0.036,0.027-0.091,0.027-0.166c0-0.036,0.036-0.073,0.11-0.11c0.073-0.036,0.146-0.073,0.221-0.11
c0.073-0.036,0.146-0.063,0.221-0.083c0.073-0.019,0.11-0.046,0.11-0.083c0-0.036-0.055-0.055-0.165-0.055
c-0.075,0-0.12-0.019-0.139-0.055c-0.019-0.036,0.009-0.091,0.083-0.166c0.036-0.036,0.091-0.063,0.166-0.083
c0.073-0.018,0.146-0.046,0.22-0.083c0.11,0,0.221-0.019,0.331-0.055c0.036-0.036,0.056-0.074,0.056-0.11V65.7
c0-0.074,0.046-0.156,0.138-0.249c0.091-0.091,0.212-0.173,0.358-0.248c0.074-0.036,0.138-0.074,0.193-0.11
c0.055-0.036,0.083-0.073,0.083-0.11c0.073-0.146,0.192-0.267,0.358-0.358c0.165-0.091,0.339-0.174,0.523-0.249
c-0.036,0-0.063-0.008-0.082-0.027c-0.02-0.019-0.027-0.046-0.027-0.083c0-0.036,0.008-0.055,0.027-0.055c0.019,0,0.046,0,0.082,0
l0.056,0.055l0.055,0.11c0.221-0.073,0.405-0.165,0.552-0.275s0.221-0.239,0.221-0.386c-0.036,0-0.082,0.019-0.138,0.055
c-0.055,0.037-0.13,0.092-0.221,0.166c-0.092,0.074-0.175,0.147-0.248,0.221c-0.037,0.037-0.074,0.055-0.11,0.055
c0.11-0.146,0.248-0.304,0.414-0.469c0.165-0.166,0.339-0.322,0.523-0.469c0.146-0.074,0.276-0.11,0.387-0.11h0.11
c0-0.146,0.091-0.284,0.275-0.414c0.184-0.129,0.367-0.193,0.552-0.193c0-0.036,0.027-0.101,0.083-0.193
c0.055-0.091,0.128-0.174,0.221-0.248c0.091-0.074,0.174-0.129,0.248-0.166h0.055c0.074,0,0.129,0.019,0.166,0.055
c0.035-0.074,0.146-0.184,0.331-0.331c0.184-0.221,0.404-0.45,0.661-0.689c0.257-0.239,0.497-0.432,0.718-0.58
c0.146-0.11,0.256-0.166,0.331-0.166c0.035,0,0.073,0.02,0.11,0.055c0.035,0.075,0.073,0.11,0.11,0.11
c0.073,0,0.138-0.018,0.192-0.055c0.056-0.036,0.102-0.073,0.139-0.11v-0.055l-0.056-0.11l-0.11,0.055h-0.165l0.055-0.055
c0.036-0.036,0.138-0.11,0.304-0.221c0.165-0.11,0.339-0.239,0.523-0.386c0.185-0.146,0.34-0.267,0.47-0.359
c0.128-0.091,0.192-0.156,0.192-0.193c0.221-0.146,0.532-0.331,0.938-0.552c0.404-0.221,0.79-0.449,1.158-0.689
c0.366-0.239,0.587-0.469,0.662-0.689c-0.406,0.147-0.947,0.451-1.628,0.91c-0.681,0.46-1.388,0.965-2.123,1.517
c-0.81,0.588-1.509,1.067-2.097,1.434c-0.479,0.295-0.809,0.441-0.993,0.441c-0.036,0-0.074-0.019-0.11-0.055
c0.146-0.146,0.524-0.422,1.131-0.828c0.607-0.404,1.297-0.846,2.069-1.324c0.771-0.515,1.517-1.001,2.233-1.462
c0.718-0.459,1.222-0.835,1.518-1.131c0.11-0.11,0.165-0.184,0.165-0.221c0-0.073-0.074-0.11-0.221-0.11h-0.165
c-1.104,0.258-2.116,0.792-3.034,1.6c-0.81,0.699-1.729,1.25-2.758,1.655c0.035-0.036,0.073-0.055,0.11-0.055v-0.055
c0-0.036-0.01-0.055-0.028-0.055s-0.047,0-0.082,0h-0.11l0.055-0.055c0.036-0.036,0.102-0.083,0.193-0.138
c0.091-0.055,0.211-0.138,0.358-0.249c0.404-0.221,0.8-0.459,1.186-0.717c0.387-0.256,0.708-0.496,0.966-0.717
c0.257-0.221,0.422-0.367,0.496-0.441v-0.055c-0.036,0-0.175,0.055-0.413,0.166c-0.24,0.11-0.689,0.386-1.352,0.828
c-1.03,0.772-2.151,1.6-3.365,2.482c-1.509,1.03-2.96,2.116-4.357,3.255c-1.398,1.14-2.52,2.317-3.365,3.53
c0,0.037-0.01,0.083-0.027,0.138c-0.02,0.055-0.047,0.083-0.083,0.083l-0.056,0.055C176.34,67.521,176.268,67.65,176.194,67.796z
 M165.657,79.546c0-0.036,0.019-0.063,0.056-0.083c0.036-0.018,0.073-0.027,0.11-0.027c0.036,0,0.073,0.02,0.11,0.055l-0.056,0.056
c0,0.037-0.019,0.064-0.055,0.083c-0.037,0.02-0.074,0.027-0.11,0.027C165.676,79.656,165.657,79.621,165.657,79.546z
 M165.878,81.808c-0.036,0.037-0.055,0.093-0.055,0.165c0,0.037,0.019,0.075,0.055,0.111h0.11c0.036-0.036,0.063-0.092,0.083-0.166
c0.019-0.073,0.027-0.129,0.027-0.166c0-0.035-0.019-0.055-0.055-0.055h-0.056L165.878,81.808z M165.934,79.16
c0.036-0.036,0.083-0.074,0.138-0.11c0.056-0.036,0.102-0.056,0.138-0.056s0.056,0.02,0.056,0.056v0.11
c-0.037,0.037-0.083,0.064-0.138,0.082c-0.056,0.02-0.103,0.027-0.139,0.027S165.934,79.234,165.934,79.16z M166.596,78.332
c0.11,0,0.165,0.02,0.165,0.056c0,0.074-0.055,0.165-0.165,0.276c-0.11,0.109-0.202,0.165-0.276,0.165
c-0.036,0-0.055-0.019-0.055-0.056c0-0.035,0.019-0.091,0.055-0.165c0.036-0.073,0.083-0.138,0.139-0.193
C166.513,78.359,166.559,78.332,166.596,78.332z M166.43,79.49c0.074-0.072,0.129-0.118,0.166-0.137
c0.035-0.02,0.073-0.028,0.11-0.028l-0.056,0.056c0,0.037-0.027,0.074-0.082,0.109c-0.056,0.037-0.11,0.075-0.166,0.111
c-0.055,0.037-0.092,0.055-0.11,0.055c-0.02,0-0.02-0.008,0-0.027C166.311,79.61,166.355,79.565,166.43,79.49z M166.871,78.167
c0,0.036-0.036,0.056-0.11,0.056c-0.036,0-0.064-0.02-0.082-0.056c-0.02-0.036-0.028-0.073-0.028-0.11v-0.055l0.11,0.055
c0.036,0,0.056,0.02,0.056,0.055L166.871,78.167z M167.864,95.709l0.275-0.055c-0.074-0.075-0.146-0.111-0.221-0.111
c-0.11,0-0.202,0.019-0.275,0.056c-0.074,0.036-0.119,0.063-0.138,0.083c-0.02,0.018-0.01,0.027,0.027,0.027H167.864z
 M169.464,75.74c0.073-0.036,0.129-0.055,0.166-0.055c0.035,0,0.055,0.02,0.055,0.055l-0.11,0.11l-0.221,0.332
c-0.074,0.146-0.157,0.248-0.248,0.303c-0.092,0.055-0.185,0.092-0.276,0.11c-0.092,0.02-0.174,0.028-0.248,0.028l-0.055-0.056
c0.036-0.036,0.129-0.073,0.275-0.11c0.036,0,0.074-0.019,0.11-0.055c0.036-0.037,0.074-0.092,0.11-0.166
c0.036-0.11,0.083-0.211,0.138-0.303C169.216,75.842,169.316,75.776,169.464,75.74z M169.685,76.402
c-0.295,0.257-0.461,0.386-0.496,0.386h-0.056c0-0.036,0.063-0.129,0.193-0.276c0.129-0.146,0.304-0.35,0.524-0.606
c0.294-0.331,0.569-0.662,0.827-0.993c0.256-0.331,0.441-0.607,0.552-0.828c0.073-0.074,0.11-0.129,0.11-0.166
c0-0.036-0.02-0.055-0.056-0.055l-0.165,0.055c-0.075,0-0.12-0.008-0.139-0.028c-0.019-0.018-0.009-0.036,0.028-0.055
c0.035-0.018,0.082-0.036,0.138-0.055c0.055-0.019,0.118-0.008,0.193,0.027c0.184-0.074,0.312-0.11,0.386-0.11
c0.073,0,0.11,0.037,0.11,0.11v0.055c0,0.075-0.11,0.24-0.331,0.497c-0.221,0.257-0.478,0.533-0.772,0.827
c-0.257,0.258-0.488,0.497-0.689,0.717C169.841,76.126,169.721,76.291,169.685,76.402z M174.429,109.885
c0.11,0,0.165,0.02,0.165,0.057c0,0.035,0,0.082,0,0.137c0,0.057-0.074,0.146-0.221,0.277c-0.146,0.127-0.295,0.266-0.44,0.412
c-0.147,0.146-0.285,0.277-0.414,0.387c-0.13,0.111-0.229,0.221-0.304,0.332v0.055c0,0.035,0.036,0.072,0.11,0.109v0.057
c0,0.145-0.13,0.33-0.386,0.551c-0.258,0.221-0.571,0.422-0.938,0.607c-0.22,0.109-0.405,0.164-0.551,0.164
c-0.075,0-0.13-0.02-0.166-0.055c-0.146-0.109-0.275-0.186-0.386-0.221c-0.221-0.109-0.396-0.23-0.524-0.357
c-0.13-0.131-0.229-0.324-0.304-0.58c-0.22-0.186-0.386-0.516-0.496-0.992c-0.074-0.332-0.11-0.682-0.11-1.049
c0-0.109,0.019-0.303,0.056-0.58c0.035-0.275,0.201-0.523,0.496-0.744c0,0.037-0.02,0.102-0.056,0.193s-0.074,0.174-0.109,0.248
c-0.037,0.074-0.075,0.146-0.11,0.221c-0.037,0.074-0.056,0.129-0.056,0.166h0.056l0.055-0.055
c0.036-0.037,0.083-0.094,0.138-0.166c0.056-0.074,0.119-0.166,0.193-0.275c0.073-0.111,0.129-0.203,0.165-0.277l0.11-0.109
c0,0.035-0.019,0.129-0.055,0.275c-0.074,0.256-0.11,0.441-0.11,0.553v0.109c0,0.035,0.019,0.055,0.055,0.055
s0.102-0.055,0.193-0.164c0.091-0.111,0.229-0.277,0.414-0.498c0.073-0.221,0.11-0.367,0.11-0.441v-0.055
c-0.037,0-0.147,0.146-0.331,0.441c-0.11,0.221-0.185,0.332-0.221,0.332c0-0.037,0.019-0.111,0.055-0.221
c0.036-0.111,0.11-0.258,0.221-0.441c0.588-0.92,0.974-1.582,1.159-1.986c0.109-0.258,0.229-0.479,0.358-0.662
c0.128-0.186,0.339-0.35,0.634-0.496c-0.074-0.074-0.11-0.148-0.11-0.221c0-0.074,0.036-0.158,0.11-0.248
c0.074-0.092,0.201-0.193,0.387-0.305c0.146-0.074,0.256-0.146,0.331-0.221v-0.055c0-0.037-0.02-0.074-0.056-0.109
c-0.11,0-0.165-0.057-0.165-0.166v-0.111c0.035-0.074,0.118-0.184,0.248-0.33c0.129-0.146,0.267-0.24,0.413-0.275
c0.036-0.037,0.074-0.055,0.11-0.055s0.074,0.035,0.11,0.109l-0.055,0.055c0,0.037,0.019,0.057,0.055,0.057v-0.057h0.056
l0.055-0.055v0.055c0.074-0.295,0.139-0.533,0.193-0.717c0.056-0.186,0.102-0.322,0.138-0.414s0.063-0.146,0.083-0.164
c0.019-0.02,0.046-0.029,0.083-0.029l0.055,0.057c0.036,0.035,0.074,0.055,0.11,0.055h0.056l0.055-0.055
c0.074-0.037,0.166-0.148,0.276-0.332c0.256-0.258,0.404-0.387,0.441-0.387c0.035,0,0.055,0.02,0.055,0.057l-0.11,0.221
c-0.037,0.184-0.258,0.578-0.662,1.186c-0.405,0.607-0.847,1.277-1.323,2.014l-1.545,2.316c-0.331,0.551-0.496,0.9-0.496,1.049
v0.055c0.11,0,0.551-0.424,1.323-1.27c-0.847,1.398-1.379,2.354-1.6,2.869c-0.147,0.33-0.221,0.551-0.221,0.662
c0,0.109,0.036,0.166,0.11,0.166c0.184,0,0.478-0.111,0.883-0.332c0.367-0.184,0.753-0.35,1.158-0.496
c0.146-0.037,0.294-0.055,0.441-0.055C174.098,109.721,174.281,109.775,174.429,109.885z M170.126,73.699h0.165
c0,0.075-0.055,0.185-0.165,0.331c-0.074,0.11-0.13,0.166-0.166,0.166s-0.055-0.019-0.055-0.055c0-0.036,0.008-0.091,0.027-0.166
c0.019-0.074,0.046-0.138,0.083-0.193C170.052,73.727,170.089,73.699,170.126,73.699z M170.016,74.802
c0.036-0.036,0.073-0.055,0.11-0.055l0.055-0.055v0.055c0.036,0,0.047,0.019,0.028,0.055c-0.02,0.037-0.064,0.055-0.139,0.055
l-0.11,0.055C169.96,74.876,169.979,74.839,170.016,74.802z M170.181,109.941c0-0.074-0.009-0.119-0.027-0.139
c-0.02-0.02-0.047,0-0.083,0.055c-0.036,0.057-0.074,0.119-0.11,0.193v0.166h0.11l0.056-0.111
C170.162,110.07,170.181,110.014,170.181,109.941z M170.401,107.68l0.056,0.055c0.036,0,0.073,0,0.11,0
c0.036,0,0.091-0.064,0.165-0.193s0.146-0.248,0.221-0.357c0.074-0.148,0.138-0.285,0.193-0.414
c0.055-0.131,0.118-0.193,0.193-0.193c0.073-0.037,0.118-0.047,0.138-0.027c0.019,0.018,0.027,0.045,0.027,0.082
c0,0.074-0.02,0.146-0.055,0.221c-0.037,0.074-0.075,0.129-0.11,0.166c-0.037,0.109-0.11,0.248-0.221,0.414
c-0.11,0.164-0.23,0.32-0.358,0.469c-0.13,0.146-0.249,0.256-0.359,0.33c-0.036,0.037-0.074,0.055-0.11,0.055v-0.164
C170.291,108.047,170.327,107.9,170.401,107.68z M170.347,74.416c0-0.074,0.027-0.119,0.083-0.138
c0.055-0.018,0.118-0.027,0.192-0.027c0.036,0,0.074-0.019,0.11-0.055h0.11c0.074-0.036,0.129-0.055,0.166-0.055h-0.056
c0,0.037-0.036,0.092-0.11,0.166c-0.074,0.074-0.146,0.138-0.221,0.193s-0.146,0.083-0.221,0.083c-0.036,0-0.055-0.019-0.055-0.055
V74.416z M170.347,76.844v0.109h0.055c0.074-0.035,0.11-0.091,0.11-0.165v-0.165l-0.055,0.055
C170.42,76.678,170.383,76.732,170.347,76.844z M170.788,109.996c0.073-0.111,0.11-0.186,0.11-0.221
c-0.037,0-0.075,0.018-0.11,0.055c-0.037,0-0.064,0.02-0.083,0.055c-0.02,0.037-0.027,0.074-0.027,0.111v0.055
C170.714,110.051,170.751,110.031,170.788,109.996z M171.284,73.257c-0.037,0.075-0.092,0.11-0.165,0.11
c-0.037,0-0.083-0.008-0.139-0.027c-0.055-0.019-0.102-0.046-0.138-0.083l-0.055-0.055c0-0.036,0.027-0.055,0.082-0.055
c0.056,0,0.11,0,0.166,0c0.055,0,0.11,0.009,0.165,0.028C171.257,73.194,171.284,73.222,171.284,73.257z M170.898,106.631
c0.035-0.074,0.073-0.137,0.11-0.193c0.035-0.055,0.073-0.082,0.11-0.082l-0.056,0.055c0,0.074-0.036,0.166-0.11,0.275
c-0.074,0.111-0.13,0.166-0.165,0.166C170.788,106.814,170.823,106.742,170.898,106.631z M171.063,109.445
c0.11-0.148,0.166-0.24,0.166-0.277l-0.221,0.166c-0.11,0.074-0.166,0.146-0.166,0.221c0,0.035,0.019,0.055,0.056,0.055
C170.934,109.609,170.989,109.555,171.063,109.445z M172.111,72.926c-0.11,0.11-0.257,0.166-0.44,0.166
c-0.186,0-0.296-0.055-0.331-0.166l0.11-0.055c0.11-0.036,0.229-0.083,0.358-0.138c0.129-0.055,0.248-0.101,0.358-0.138l0.11-0.055
c0.036,0,0.055,0.019,0.055,0.055v0.055C172.332,72.725,172.258,72.816,172.111,72.926z M171.505,106.189
c0-0.037,0.027-0.102,0.083-0.193c0.055-0.092,0.118-0.156,0.193-0.193c0.035-0.035,0.073-0.055,0.11-0.055h0.055l-0.055,0.055
c0,0.074-0.028,0.146-0.083,0.221c-0.056,0.074-0.103,0.139-0.138,0.193c-0.037,0.057-0.075,0.084-0.11,0.084
C171.523,106.301,171.505,106.264,171.505,106.189z M172.057,109.445c-0.185,0.293-0.313,0.477-0.386,0.551l-0.11,0.055
c0-0.074,0.035-0.174,0.11-0.303c0.073-0.131,0.156-0.268,0.248-0.414c0.091-0.146,0.174-0.258,0.248-0.33
c0.036,0,0.055,0.018,0.055,0.055C172.222,109.131,172.167,109.26,172.057,109.445z M172.222,104.369v0.055
c0,0.037-0.019,0.084-0.055,0.139c-0.037,0.055-0.083,0.109-0.138,0.166c-0.056,0.055-0.083,0.1-0.083,0.137h-0.055l0.109-0.164
l0.11-0.221L172.222,104.369z M172.498,104.645l0.165-0.164l0.056,0.055c0,0.109-0.074,0.256-0.221,0.441
c-0.185,0.33-0.313,0.496-0.387,0.496c-0.036,0-0.055-0.037-0.055-0.109c0-0.037,0.019-0.111,0.055-0.221
C172.147,105.031,172.277,104.865,172.498,104.645z M172.773,72.871c-0.257,0.258-0.441,0.423-0.552,0.497h-0.11
c0-0.036,0.009-0.073,0.028-0.11c0.019-0.036,0.082-0.128,0.192-0.276c0.11-0.146,0.239-0.294,0.387-0.441
c0.146-0.146,0.275-0.267,0.386-0.359c0.11-0.091,0.201-0.138,0.276-0.138v0.055c0,0.037-0.056,0.12-0.166,0.248
C173.104,72.477,172.958,72.651,172.773,72.871z M172.663,72.209h-0.165l-0.056-0.055c0-0.036,0.019-0.074,0.056-0.11
c0.036-0.036,0.073-0.055,0.11-0.055h0.055c0.036,0,0.074,0.019,0.11,0.055c0,0.037-0.009,0.064-0.027,0.083
C172.727,72.146,172.699,72.173,172.663,72.209z M172.829,103.266l0.221-0.221v0.111c0,0.035-0.056,0.09-0.166,0.166
c-0.074,0.072-0.129,0.109-0.165,0.109L172.829,103.266z M173.05,107.404c0.11-0.111,0.184-0.166,0.221-0.166
c0.035,0,0.055,0.035,0.055,0.109c0,0.111-0.027,0.213-0.083,0.305c-0.055,0.09-0.119,0.156-0.192,0.191
c-0.075,0.111-0.13,0.166-0.166,0.166s-0.047-0.037-0.027-0.109c0.019-0.074,0.046-0.158,0.083-0.248
C172.975,107.561,173.013,107.477,173.05,107.404z M173.602,102.715l0.11-0.111l-0.056,0.111c0,0.072-0.02,0.166-0.055,0.275
c-0.037,0.111-0.093,0.201-0.166,0.275c-0.185,0.367-0.331,0.607-0.441,0.717l-0.11,0.057l0.056-0.057
c0-0.074,0.035-0.191,0.11-0.357c0.073-0.166,0.156-0.34,0.248-0.523C173.389,102.916,173.491,102.787,173.602,102.715z
 M173.491,71.161c0.184-0.11,0.294-0.166,0.331-0.166l-0.166,0.166c-0.221,0.147-0.367,0.221-0.441,0.221
C173.215,71.346,173.306,71.271,173.491,71.161z M173.933,110.271c0.184-0.074,0.294-0.129,0.33-0.166v-0.055
c0-0.037-0.019-0.055-0.055-0.055c-0.037,0-0.11,0.018-0.221,0.055s-0.221,0.074-0.331,0.111c-0.221,0.109-0.331,0.184-0.331,0.221
l0.056,0.055C173.454,110.438,173.637,110.383,173.933,110.271z M173.767,71.713v0.055c0,0.037-0.02,0.055-0.055,0.055
c-0.075,0.074-0.13,0.11-0.166,0.11c-0.037,0-0.055-0.036-0.055-0.11c0-0.036,0.035-0.074,0.11-0.11l0.055-0.055L173.767,71.713z
 M174.042,105.197c0.074,0.035,0.11,0.09,0.11,0.166v0.055c-0.036,0.072-0.11,0.109-0.22,0.109c-0.075,0-0.13,0.008-0.166,0.027
c-0.037,0.02-0.074,0.064-0.11,0.139c-0.037,0.146-0.074,0.221-0.11,0.221h-0.055c0-0.037,0.018-0.102,0.055-0.193
c0.036-0.092,0.083-0.174,0.138-0.248c0.056-0.074,0.11-0.139,0.166-0.193C173.904,105.225,173.968,105.197,174.042,105.197z
 M179.338,98.467c-0.221,0.257-0.561,0.726-1.021,1.406c-0.46,0.682-0.965,1.443-1.517,2.289c-0.589,0.846-1.123,1.629-1.6,2.346
c-0.479,0.717-0.864,1.24-1.159,1.572l-0.275,0.275c0-0.037,0.046-0.158,0.138-0.359c0.091-0.201,0.469-0.93,1.131-2.178
c0.295-0.59,0.764-1.279,1.407-2.068c0.643-0.793,1.296-1.537,1.958-2.234C178.768,99.109,179.081,98.762,179.338,98.467z
 M174.318,70.94l0.055-0.055v0.11c0,0.037,0,0.083,0,0.138s-0.027,0.12-0.082,0.193c-0.056,0.075-0.103,0.11-0.139,0.11
c-0.11,0.075-0.185,0.11-0.22,0.11l0.055-0.11c0.036-0.036,0.073-0.091,0.11-0.166c0.073-0.073,0.129-0.138,0.165-0.193
S174.318,70.978,174.318,70.94z M174.429,101.666c0.036-0.037,0.073-0.055,0.11-0.055c0,0.035-0.092,0.166-0.276,0.387
c-0.074,0.072-0.11,0.129-0.11,0.164h-0.055c0-0.035,0.008-0.074,0.027-0.109c0.019-0.037,0.046-0.082,0.083-0.139
c0.036-0.055,0.073-0.102,0.11-0.137C174.354,101.74,174.392,101.703,174.429,101.666z M174.318,93.447
c-0.037,0.073-0.056,0.146-0.056,0.221l0.056,0.055h0.11c0.036,0,0.091,0,0.165,0s0.156-0.02,0.249-0.055
c0.091-0.037,0.138-0.074,0.138-0.11h-0.221c-0.074,0-0.13-0.009-0.166-0.028c-0.036-0.019-0.055-0.047-0.055-0.082
c-0.037,0-0.074-0.01-0.11-0.027C174.392,93.4,174.354,93.41,174.318,93.447z M174.594,70.72c0.036-0.036,0.083-0.083,0.139-0.138
c0.055-0.055,0.11-0.101,0.165-0.138c0.056-0.036,0.102-0.055,0.138-0.055h0.11c0,0.037-0.027,0.083-0.082,0.138
c-0.056,0.055-0.12,0.12-0.193,0.193c-0.074,0.075-0.147,0.13-0.221,0.166h-0.11L174.594,70.72z M174.814,101.225
c-0.11,0.111-0.185,0.166-0.221,0.166s-0.055-0.037-0.055-0.111c0-0.035,0.027-0.074,0.083-0.109
c0.055-0.037,0.11-0.074,0.165-0.109c0.056-0.037,0.119-0.076,0.193-0.111v0.055C174.98,101.041,174.925,101.115,174.814,101.225z
 M176.084,68.072l-0.166,0.166l-0.331,0.386c-0.257,0.221-0.441,0.351-0.552,0.386h-0.055c0-0.036,0.008-0.083,0.027-0.138
c0.019-0.055,0.091-0.146,0.221-0.276c0.129-0.129,0.267-0.248,0.414-0.358C175.788,68.127,175.937,68.072,176.084,68.072z
 M175.477,69.396c0,0.037-0.027,0.083-0.083,0.138c-0.055,0.055-0.119,0.102-0.192,0.138c-0.075,0.037-0.147,0.055-0.221,0.055
v-0.11c0.036-0.074,0.091-0.138,0.165-0.193s0.146-0.083,0.221-0.083l0.056-0.055L175.477,69.396z M175.312,101.445
c-0.075,0.074-0.12,0.119-0.139,0.139c-0.019,0.018-0.027-0.01-0.027-0.084c0-0.035,0.019-0.082,0.056-0.137
c0.035-0.055,0.073-0.119,0.11-0.193c0.073-0.074,0.129-0.109,0.165-0.109v0.055c0,0.035-0.02,0.09-0.055,0.164
C175.385,101.354,175.347,101.408,175.312,101.445z M175.532,103.211c-0.037,0-0.075,0.008-0.11,0.027
c-0.037,0.02-0.064,0.055-0.083,0.111c-0.02,0.055-0.027,0.1-0.027,0.137v0.055c0.035,0,0.073-0.035,0.11-0.109
c0.146-0.074,0.221-0.129,0.221-0.166S175.605,103.211,175.532,103.211z M175.753,99.404l0.055-0.055l-0.055,0.166
c-0.075,0.035-0.11,0.072-0.11,0.109c0,0.036,0.008,0.056,0.027,0.056c0.019,0,0.036,0,0.055,0s0.046-0.02,0.083-0.056h0.11
l0.056-0.055v0.055l-0.221,0.221c-0.075,0.111-0.147,0.166-0.221,0.166l-0.056-0.055c0-0.037,0.009-0.084,0.027-0.139
s0.056-0.129,0.11-0.221C175.67,99.506,175.716,99.44,175.753,99.404z M175.697,100.729c0.036-0.11,0.073-0.166,0.11-0.166
l0.056-0.055v0.055c0,0.037-0.01,0.084-0.028,0.139s-0.047,0.102-0.082,0.139h-0.056V100.729z M175.974,70.94
c-0.11,0.037-0.166,0.075-0.166,0.11l0.056,0.055c0.073,0.037,0.128,0.055,0.165,0.055h0.11c0.036,0,0.056-0.036,0.056-0.11
l0.055-0.055l-0.055-0.055l-0.11-0.055L175.974,70.94z M176.028,100.287c0-0.037,0.019-0.082,0.056-0.138
c0.035-0.056,0.073-0.103,0.11-0.138c0.035-0.037,0.073-0.055,0.109-0.055l0.056-0.056v0.056c0,0.035-0.037,0.109-0.11,0.221
c-0.037,0.035-0.074,0.063-0.11,0.082C176.102,100.278,176.064,100.287,176.028,100.287z M176.194,100.949l-0.056-0.055
c0-0.037,0.019-0.084,0.056-0.139c0.035-0.055,0.082-0.119,0.138-0.193c0.055-0.074,0.101-0.129,0.138-0.165l0.055-0.056
l0.056,0.056c-0.037,0-0.064,0.028-0.083,0.083c-0.02,0.055-0.047,0.119-0.083,0.193c-0.036,0.072-0.074,0.137-0.11,0.193
C176.268,100.922,176.229,100.949,176.194,100.949z M176.249,99.239h-0.055v-0.11c0.035-0.074,0.063-0.119,0.082-0.138
c0.019-0.02,0.027-0.009,0.027,0.027l0.056,0.056l-0.056,0.055C176.304,99.203,176.285,99.239,176.249,99.239z M176.635,69.506
h0.056c0.036,0,0.073-0.008,0.11-0.027c0.036-0.019,0.101-0.055,0.193-0.11c0.091-0.055,0.173-0.119,0.248-0.193
c0.073-0.073,0.129-0.128,0.165-0.166v-0.055h-0.055c-0.075-0.036-0.147-0.055-0.221-0.055c-0.11-0.036-0.23-0.046-0.358-0.027
c-0.13,0.019-0.249,0.138-0.359,0.358c-0.074,0.075-0.11,0.13-0.11,0.166l-0.055,0.055h0.11c0.073,0,0.129,0,0.165,0
S176.599,69.471,176.635,69.506z M176.635,97.64c0.11-0.11,0.193-0.185,0.249-0.221c0.055-0.036,0.073-0.036,0.055,0
c-0.02,0.036-0.055,0.083-0.11,0.138c-0.055,0.055-0.119,0.119-0.193,0.193c-0.074,0.073-0.146,0.129-0.221,0.166L176.635,97.64z
 M176.47,68.183c0.146-0.036,0.239-0.091,0.275-0.166v-0.055c0-0.036-0.009-0.063-0.027-0.083c-0.02-0.018-0.064-0.046-0.138-0.083
c-0.037-0.036-0.056-0.074-0.056-0.11v-0.055h0.166c0.073,0,0.165-0.028,0.275-0.083c0.11-0.055,0.221-0.118,0.331-0.193
c0.11-0.074,0.201-0.11,0.276-0.11l0.055-0.055v0.055c0,0.037-0.074,0.147-0.221,0.331c-0.036,0.074-0.083,0.12-0.138,0.138
c-0.056,0.02-0.103,0.028-0.138,0.028c-0.037,0.037-0.074,0.074-0.11,0.11c-0.037,0.037-0.074,0.092-0.11,0.166
c0,0.074-0.02,0.129-0.056,0.166c-0.036,0.037-0.082,0.055-0.138,0.055c-0.055,0-0.119,0-0.193,0h-0.055
C176.433,68.238,176.433,68.219,176.47,68.183z M176.69,100.178l-0.056-0.056c0-0.037,0.009-0.075,0.028-0.11
c0.018-0.037,0.063-0.102,0.138-0.193c0.073-0.092,0.156-0.166,0.248-0.221c0.091-0.055,0.156-0.102,0.193-0.138h0.055l-0.055,0.11
c0,0.035-0.056,0.129-0.166,0.275c-0.074,0.111-0.146,0.193-0.221,0.248C176.781,100.149,176.727,100.178,176.69,100.178z
 M176.69,90.634h0.11c0.036,0,0.091-0.019,0.165-0.056c0.074-0.035,0.129-0.073,0.166-0.109l0.055-0.056v-0.056l-0.11,0.056
c-0.036,0-0.082,0.02-0.138,0.056c-0.055,0.036-0.119,0.074-0.193,0.109L176.69,90.634z M176.911,69.23
c0.073-0.036,0.129-0.055,0.165-0.055h-0.055c0,0.037-0.037,0.11-0.11,0.221c-0.074,0.037-0.13,0.055-0.166,0.055
s-0.047-0.019-0.027-0.055c0.019-0.036,0.036-0.074,0.056-0.11C176.791,69.25,176.837,69.23,176.911,69.23z M177.628,91.406
l0.11-0.11V91.24l-0.165,0.056c-0.11,0-0.221,0.027-0.331,0.083c-0.11,0.055-0.202,0.118-0.276,0.193l-0.11,0.055v0.055h0.166
c0.073,0,0.174-0.027,0.303-0.082C177.453,91.544,177.554,91.479,177.628,91.406z M177.076,98.854c0.074,0,0.11,0.018,0.11,0.055
c0,0.036-0.055,0.092-0.165,0.166l-0.11,0.109c-0.037,0-0.047-0.02-0.027-0.055c0.018-0.037,0.035-0.082,0.055-0.138
c0.019-0.056,0.046-0.103,0.083-0.138H177.076z M177.959,101.279c0.073,0,0.11,0.037,0.11,0.111c0,0.109-0.221,0.404-0.662,0.883
c-0.185,0.221-0.313,0.35-0.386,0.387h-0.056c0-0.037,0.009-0.084,0.028-0.139c0.018-0.055,0.082-0.158,0.192-0.303
c0.11-0.148,0.221-0.305,0.331-0.469c0.11-0.166,0.201-0.285,0.276-0.359l0.11-0.111H177.959z M177.353,98.467h0.165l0.056,0.055
h-0.056c-0.036,0.037-0.074,0.064-0.11,0.084c-0.036,0.018-0.083,0.027-0.138,0.027c-0.056,0-0.083-0.02-0.083-0.056
s0.019-0.064,0.056-0.083C177.277,98.475,177.315,98.467,177.353,98.467z M177.518,99.074l-0.055-0.056
c0-0.036,0.019-0.064,0.055-0.083c0.036-0.02,0.083-0.047,0.138-0.082c0.056-0.037,0.102-0.074,0.139-0.111l0.055-0.055
l0.056,0.055c0,0.037-0.037,0.092-0.11,0.166c-0.037,0.074-0.083,0.119-0.139,0.139C177.601,99.064,177.554,99.074,177.518,99.074z
 M177.573,66.638c0-0.073,0.027-0.146,0.082-0.22c0.056-0.074,0.119-0.129,0.193-0.166l0.11-0.055l0.056,0.055l-0.056,0.055
c-0.037,0.074-0.083,0.138-0.138,0.193c-0.056,0.055-0.12,0.102-0.193,0.138H177.573z M177.904,68.734
c0-0.036-0.01-0.055-0.028-0.055s-0.047,0-0.082,0c-0.037,0-0.075,0-0.11,0c-0.037,0-0.056,0.02-0.056,0.055H177.904z
 M178.455,89.476c-0.11,0.074-0.248,0.175-0.413,0.304c-0.166,0.129-0.304,0.248-0.414,0.357c0.073-0.035,0.184-0.101,0.331-0.192
c0.146-0.091,0.284-0.185,0.414-0.276c0.129-0.09,0.211-0.156,0.248-0.192V89.42C178.584,89.42,178.529,89.439,178.455,89.476z
 M177.849,97.916h-0.11l0.056-0.111c0.035-0.037,0.073-0.074,0.11-0.109c0.035-0.037,0.073-0.056,0.11-0.056
c0.035,0,0.055,0.036,0.055,0.11l-0.055,0.055C177.978,97.841,177.922,97.879,177.849,97.916z M178.565,92.068
c0.074,0,0.102,0.018,0.083,0.055c-0.02,0.036-0.064,0.092-0.138,0.166c-0.074,0.073-0.166,0.146-0.275,0.221
c-0.11,0.073-0.203,0.129-0.276,0.165c-0.074,0.036-0.13,0.056-0.165,0.056c0-0.037,0.027-0.092,0.082-0.166
c0.056-0.074,0.129-0.146,0.221-0.221s0.185-0.138,0.276-0.193C178.464,92.096,178.529,92.068,178.565,92.068z M177.849,65.976
c0.036-0.036,0.119-0.091,0.248-0.166c0.129-0.074,0.248-0.146,0.358-0.221c0.074-0.036,0.129-0.055,0.166-0.055h0.055
c0.036,0,0.056,0.019,0.056,0.055c0,0.037-0.037,0.092-0.11,0.166c-0.037,0.037-0.103,0.083-0.193,0.138
c-0.092,0.055-0.185,0.092-0.275,0.11c-0.092,0.02-0.175,0.028-0.248,0.028C177.867,66.031,177.849,66.013,177.849,65.976z
 M178.565,90.799c0.185-0.073,0.295-0.146,0.331-0.221v-0.055l-0.11-0.055c-0.074,0-0.146,0.02-0.221,0.055
c-0.074,0.037-0.146,0.074-0.221,0.11c-0.22,0.147-0.33,0.239-0.33,0.276l0.055,0.055h0.056
C178.198,90.965,178.345,90.91,178.565,90.799z M178.18,96.48c0.036-0.036,0.073-0.055,0.11-0.055c0,0.036-0.037,0.11-0.11,0.221
c-0.037,0.074-0.074,0.11-0.11,0.11v-0.11c0-0.037,0.009-0.074,0.027-0.11S178.143,96.48,178.18,96.48z M178.455,68.127
c0.11-0.074,0.193-0.146,0.249-0.221c0.055-0.074,0.082-0.129,0.082-0.166c-0.036,0-0.082,0.019-0.138,0.055
c-0.055,0.037-0.119,0.083-0.193,0.138s-0.138,0.12-0.192,0.193c-0.056,0.074-0.083,0.129-0.083,0.166h0.056
C178.271,68.293,178.345,68.238,178.455,68.127z M178.4,80.373c0.073-0.073,0.11-0.129,0.11-0.165s-0.037-0.056-0.11-0.056
c-0.037,0-0.064,0.02-0.083,0.056c-0.02,0.036-0.027,0.064-0.027,0.083c0,0.02,0.008,0.037,0.027,0.055
C178.336,80.365,178.363,80.373,178.4,80.373z M178.731,95.543c0.073-0.037,0.146-0.055,0.221-0.055
c0.036,0,0.055,0.018,0.055,0.055c0,0.074-0.047,0.166-0.138,0.276c-0.092,0.11-0.193,0.22-0.304,0.331
c-0.074,0.073-0.129,0.146-0.165,0.221v-0.056c0-0.147,0.027-0.304,0.083-0.469C178.538,95.682,178.621,95.579,178.731,95.543z
 M179.779,95.764c-0.037,0.036-0.092,0.102-0.165,0.193c-0.075,0.091-0.166,0.211-0.276,0.358c-0.185,0.146-0.34,0.304-0.469,0.47
c-0.13,0.165-0.229,0.266-0.304,0.303h-0.055c0-0.037,0.019-0.092,0.055-0.166s0.129-0.201,0.276-0.386
c0.146-0.185,0.312-0.378,0.496-0.579c0.185-0.202,0.35-0.378,0.497-0.523c0.146-0.111,0.238-0.166,0.275-0.166
C180.11,95.303,180,95.469,179.779,95.764z M178.565,100.508h-0.055c0-0.037,0.008-0.082,0.027-0.138
c0.019-0.056,0.083-0.165,0.193-0.331s0.229-0.322,0.358-0.469c0.129-0.146,0.248-0.295,0.358-0.441l0.166-0.166
c0.035,0.037,0.055,0.074,0.055,0.111c0,0.073-0.055,0.201-0.165,0.386c-0.11,0.184-0.268,0.386-0.469,0.606
C178.832,100.287,178.676,100.434,178.565,100.508z M178.676,66.252l-0.055-0.055c0-0.036,0.019-0.055,0.055-0.055
s0.074-0.019,0.11-0.055c0.036,0,0.056-0.008,0.056-0.028c0-0.018,0-0.046,0-0.083v-0.055c0-0.036,0.019-0.074,0.055-0.11
c0.036-0.036,0.102-0.055,0.193-0.055c0.091,0,0.156,0,0.193,0l0.055,0.055v0.055c-0.036,0.037-0.074,0.055-0.11,0.055
s-0.074-0.019-0.11-0.055h-0.165v0.055c0,0.037-0.01,0.083-0.027,0.138c-0.02,0.055-0.047,0.11-0.083,0.166
c-0.037,0.055-0.074,0.083-0.11,0.083C178.694,66.307,178.676,66.289,178.676,66.252z M178.786,66.858
c0-0.036,0.028-0.091,0.083-0.166c0.056-0.073,0.129-0.146,0.221-0.221c0.091-0.073,0.156-0.128,0.193-0.165
c0.035-0.036,0.073-0.055,0.11-0.055h0.055l-0.11,0.11c-0.036,0.074-0.102,0.147-0.192,0.22c-0.093,0.075-0.175,0.13-0.249,0.166
L178.786,66.858z M178.952,97.033c0.036-0.075,0.073-0.111,0.11-0.111c0.036,0,0.055,0.036,0.055,0.111
c0,0.035-0.009,0.063-0.027,0.082c-0.02,0.019-0.047,0.027-0.083,0.027s-0.055-0.02-0.055-0.055V97.033z M179.007,95.102
c0-0.036,0.046-0.082,0.139-0.138c0.091-0.055,0.184-0.11,0.275-0.165c0.091-0.055,0.165-0.092,0.221-0.11
c0.055-0.02,0.083-0.009,0.083,0.027s-0.075,0.129-0.221,0.276c-0.147,0.109-0.276,0.165-0.387,0.165h-0.055
C179.025,95.157,179.007,95.138,179.007,95.102z M187.392,84.896c0.478-0.367,0.809-0.625,0.993-0.771
c0.11-0.111,0.166-0.184,0.166-0.221l-0.11,0.055c-0.037,0-0.166,0.055-0.387,0.166c-0.221,0.109-0.46,0.24-0.717,0.386v-0.056
c0-0.035,0.055-0.09,0.165-0.165c0.11-0.073,0.257-0.174,0.441-0.304c0.185-0.129,0.367-0.248,0.552-0.358
c0.257-0.184,0.469-0.339,0.635-0.469c0.165-0.129,0.248-0.212,0.248-0.248l0.055-0.056h0.11c0.036,0.037,0.074,0.092,0.11,0.166
h0.11l0.056,0.055c0-0.035-0.02-0.091-0.056-0.165c0-0.146,0.019-0.249,0.056-0.304c0.035-0.055,0.091-0.073,0.165-0.055
c0.074,0.02,0.166,0.027,0.276,0.027c0.073,0.037,0.128,0.055,0.165,0.055c0.073,0,0.11-0.035,0.11-0.109
c-0.037-0.037-0.074-0.064-0.11-0.084c-0.037-0.018-0.092-0.027-0.165-0.027h-0.056v-0.109l0.221-0.221
c0.146-0.146,0.221-0.239,0.221-0.276l-0.055-0.056l-0.11,0.056c-0.075,0.037-0.166,0.075-0.276,0.11
c-0.074,0.037-0.165,0.055-0.275,0.055l-0.11,0.056c0.073-0.146,0.221-0.239,0.441-0.276c0.221-0.035,0.441-0.072,0.662-0.109
c0.109-0.036,0.22-0.056,0.33-0.056h0.11l-0.11-0.056c-0.036-0.072-0.055-0.128-0.055-0.165c0.036-0.036,0.11-0.056,0.221-0.056
c0.073-0.035,0.165-0.072,0.275-0.109c0.11-0.036,0.201-0.11,0.276-0.221c0.146-0.11,0.366-0.257,0.662-0.441
c0.256-0.146,0.469-0.284,0.634-0.414c0.166-0.129,0.248-0.248,0.248-0.358c-0.074-0.036-0.129-0.056-0.165-0.056
c-0.074,0-0.13,0.028-0.166,0.084c-0.036,0.055-0.074,0.119-0.11,0.192c-0.036,0.036-0.055,0.064-0.055,0.083
c0,0.02-0.02,0.027-0.055,0.027l-0.166,0.055c-0.074,0.037-0.165,0.111-0.275,0.221c-0.11,0.111-0.24,0.221-0.387,0.332
c-0.146,0.109-0.275,0.212-0.386,0.303c-0.11,0.092-0.185,0.139-0.221,0.139c0-0.037-0.037-0.047-0.11-0.028
c-0.074,0.02-0.166,0.047-0.276,0.083c1.545-1.214,2.317-1.895,2.317-2.041c-0.11,0-0.682,0.34-1.71,1.02
c-1.03,0.682-2.326,1.564-3.89,2.648c-1.563,1.085-3.08,2.151-4.551,3.199c-1.471,1.049-2.611,1.885-3.42,2.51
c-0.516,0.406-0.772,0.627-0.772,0.662v0.056c0.036,0,0.193-0.073,0.469-0.221c0.276-0.146,1.536-0.956,3.779-2.427
c-0.331,0.295-0.736,0.606-1.214,0.938c-0.516,0.295-0.938,0.606-1.269,0.938c0.184,0,0.449-0.11,0.8-0.331
c0.35-0.221,0.726-0.478,1.131-0.772c0.515-0.33,0.974-0.625,1.379-0.883c0.257-0.146,0.46-0.221,0.606-0.221
c0.11,0,0.185,0.02,0.221,0.056l-0.055,0.11h0.055c0.036,0,0.083-0.008,0.139-0.027c0.055-0.019,0.118-0.047,0.192-0.083
c0.11-0.073,0.212-0.129,0.304-0.165c0.091-0.036,0.156-0.056,0.193-0.056h0.055c0.036,0,0.056,0.02,0.056,0.056h0.055
c0.036,0,0.091-0.009,0.166-0.027c0.073-0.02,0.165-0.074,0.275-0.166s0.184-0.193,0.221-0.304l0.11-0.11h-0.056
c-0.036,0-0.109,0.037-0.22,0.11c-0.11,0.075-0.193,0.12-0.249,0.138c-0.055,0.02-0.102,0.027-0.138,0.027
c-0.037,0-0.055-0.018-0.055-0.055c0-0.035,0.018-0.073,0.055-0.11l-0.055-0.165h-0.056c-0.037,0.037-0.13,0.102-0.275,0.193
c-0.147,0.092-0.351,0.229-0.607,0.413c0-0.036,0.036-0.101,0.11-0.192s0.212-0.221,0.414-0.387
c0.201-0.166,0.414-0.322,0.635-0.469s0.404-0.257,0.551-0.331c0.11-0.036,0.185-0.056,0.221-0.056
c0,0.074-0.092,0.221-0.275,0.441c-0.147,0.111-0.221,0.186-0.221,0.221l-0.055,0.056c0.035,0,0.091-0.019,0.165-0.056
c0.073-0.035,0.165-0.091,0.275-0.165c0.074-0.073,0.156-0.129,0.249-0.165C187.29,84.971,187.355,84.934,187.392,84.896z
 M179.283,89.695c0.035-0.035,0.055-0.072,0.055-0.109v-0.055l-0.055-0.056c-0.037,0-0.075,0.019-0.11,0.056
c-0.037,0.036-0.056,0.074-0.056,0.109c0,0.037,0.009,0.055,0.028,0.055C179.163,89.695,179.208,89.695,179.283,89.695z
 M179.779,91.352c0,0.036-0.02,0.082-0.055,0.137c-0.037,0.056-0.092,0.111-0.166,0.166s-0.146,0.102-0.221,0.138
s-0.146,0.073-0.221,0.11c0-0.037,0.009-0.074,0.028-0.11c0.018-0.036,0.101-0.11,0.248-0.22c0.073-0.075,0.138-0.131,0.192-0.166
C179.642,91.37,179.705,91.352,179.779,91.352z M179.504,64.707c0-0.036,0.019-0.073,0.055-0.11
c0.036-0.036,0.083-0.055,0.138-0.055c0.056,0,0.11,0,0.166,0c0.055,0,0.101,0,0.138,0l-0.055,0.055
c-0.037,0.037-0.083,0.075-0.139,0.11c-0.055,0.037-0.11,0.064-0.165,0.083c-0.056,0.019-0.092,0.027-0.11,0.027
C179.512,64.817,179.504,64.782,179.504,64.707z M179.89,96.26c0,0.037-0.027,0.074-0.083,0.111
c-0.055,0.035-0.102,0.073-0.138,0.109h-0.11c0-0.036,0.019-0.074,0.056-0.109c0.035-0.037,0.073-0.074,0.11-0.111
c0.035-0.036,0.073-0.063,0.11-0.082C179.87,96.158,179.89,96.186,179.89,96.26z M179.945,65.976c0,0.037-0.01,0.074-0.028,0.11
c-0.019,0.037-0.064,0.055-0.138,0.055l-0.165,0.055c0-0.036,0.008-0.063,0.027-0.083c0.019-0.018,0.046-0.036,0.083-0.055
c0.035-0.018,0.073-0.036,0.11-0.055C179.87,65.985,179.908,65.976,179.945,65.976z M179.945,65.093l0.11-0.055h0.055
c0,0.037-0.037,0.092-0.11,0.166h-0.221C179.779,65.168,179.835,65.13,179.945,65.093z M180.056,79.602
c0.404-0.074,0.661-0.146,0.771-0.221v-0.056c0-0.036-0.055-0.056-0.165-0.056h-0.166c-0.036,0-0.102,0.02-0.192,0.056
c-0.092,0.037-0.185,0.083-0.276,0.138c-0.092,0.056-0.157,0.103-0.192,0.139l-0.056,0.055
C179.815,79.656,179.908,79.639,180.056,79.602z M179.779,89.311h0.056c0.035,0,0.091-0.009,0.165-0.028
c0.073-0.019,0.156-0.063,0.248-0.138c0.091-0.074,0.156-0.146,0.193-0.221v-0.055c0-0.037-0.02-0.074-0.056-0.111
c-0.036,0-0.092,0.027-0.165,0.083c-0.074,0.056-0.147,0.12-0.221,0.193c-0.074,0.074-0.13,0.13-0.165,0.165L179.779,89.311z
 M180.056,94.274c-0.075,0.073-0.12,0.118-0.139,0.138c-0.019,0.019-0.027,0.009-0.027-0.027l-0.055-0.055
c0-0.037,0.008-0.064,0.027-0.083c0.019-0.02,0.036-0.056,0.055-0.11s0.046-0.083,0.083-0.083c0.073-0.036,0.129-0.056,0.166-0.056
v0.056C180.166,94.09,180.129,94.164,180.056,94.274z M180.331,64.928h-0.055c-0.037,0-0.056-0.027-0.056-0.083s0-0.119,0-0.193
c0-0.073,0.027-0.138,0.083-0.193c0.055-0.055,0.138-0.083,0.248-0.083c0.478-0.477,1.084-0.918,1.82-1.324
c0.146-0.036,0.312-0.074,0.496-0.11c0.185-0.036,0.359-0.101,0.524-0.193c0.165-0.091,0.267-0.229,0.304-0.414
c-0.037-0.036-0.075-0.055-0.11-0.055c-0.037,0-0.075,0-0.11,0h-0.11v-0.11c0-0.036,0.055-0.11,0.165-0.221
c0.074-0.074,0.129-0.11,0.166-0.11c0.035,0,0.063,0.009,0.082,0.027c0.019,0.02,0.028,0.047,0.028,0.083
c0,0.074,0.019,0.129,0.055,0.166h0.056c0.035,0,0.082,0,0.138,0c0.055,0,0.156-0.055,0.304-0.166
c0.073-0.036,0.165-0.074,0.275-0.11c0.11-0.036,0.201-0.055,0.275-0.055h0.11c0,0.037-0.074,0.074-0.221,0.11
c-0.516,0.478-1.232,1.095-2.151,1.848c-0.919,0.754-1.765,1.407-2.537,1.958c0.221-0.441,0.57-0.808,1.048-1.104
c0.367-0.256,0.681-0.532,0.938-0.827c0.256-0.221,0.44-0.367,0.551-0.441c0.074-0.074,0.11-0.129,0.11-0.166
c-0.036,0-0.11,0.028-0.221,0.083c-0.109,0.055-0.239,0.129-0.386,0.22c-0.147,0.092-0.285,0.175-0.414,0.249
c-0.129,0.074-0.212,0.147-0.248,0.221c-0.036,0.037-0.102,0.092-0.192,0.166c-0.093,0.074-0.193,0.166-0.304,0.275
c-0.147,0.11-0.276,0.221-0.387,0.331S180.404,64.892,180.331,64.928z M180.221,64.156c0.073,0,0.11,0.019,0.11,0.055
c0,0.037-0.037,0.074-0.11,0.11h-0.055c-0.037,0-0.056-0.019-0.056-0.055c0-0.036,0.009-0.063,0.027-0.083
C180.156,64.165,180.184,64.156,180.221,64.156z M180.386,94.826c-0.036-0.037-0.055-0.074-0.055-0.11
c0-0.147,0.138-0.405,0.414-0.772c0.275-0.367,0.67-0.818,1.186-1.352c0.515-0.533,1.012-1.039,1.489-1.517
c0.441-0.405,0.718-0.681,0.828-0.827c-0.037-0.036-0.056-0.074-0.056-0.111c0-0.035,0.019-0.082,0.056-0.137
c0.035-0.056,0.11-0.111,0.221-0.166s0.22-0.102,0.33-0.139c0.11-0.035,0.185-0.055,0.221-0.055v0.055
c0,0.037-0.019,0.111-0.055,0.221c-0.037,0.037-0.083,0.075-0.138,0.111c-0.056,0.037-0.12,0.055-0.193,0.055
c-0.037,0.037-0.064,0.055-0.083,0.055c-0.02,0-0.009,0.02,0.028,0.056l0.055,0.056c0,0.037-0.009,0.074-0.027,0.109
c-0.02,0.037-0.064,0.092-0.138,0.166c-0.075,0.037-0.13,0.092-0.166,0.166v0.165c-0.772,0.587-1.435,1.249-1.985,1.985
C181.766,93.538,181.122,94.2,180.386,94.826z M180.331,90.854c0.036-0.036,0.083-0.073,0.138-0.11
c0.056-0.035,0.129-0.063,0.221-0.082c0.091-0.02,0.156-0.028,0.193-0.028v0.056l-0.221,0.109
c-0.074,0.037-0.147,0.056-0.221,0.056H180.331z M180.772,94.937c0.146-0.147,0.239-0.221,0.275-0.221
c0,0.036-0.092,0.185-0.275,0.441l-0.221,0.221h-0.056v-0.056c0-0.037,0.028-0.092,0.083-0.165
C180.635,95.082,180.698,95.01,180.772,94.937z M180.717,63.659c0.478-0.587,1.149-1.194,2.014-1.82
c0.863-0.625,1.646-1.104,2.345-1.435c-0.074,0.075-0.147,0.166-0.221,0.276c-0.037,0.075-0.074,0.138-0.11,0.193
c-0.037,0.055-0.056,0.102-0.056,0.138h0.056c0.036,0,0.091-0.008,0.165-0.027c0.074-0.019,0.221-0.102,0.441-0.249h0.056V60.68
h-0.056l-0.11-0.11c0-0.036,0.019-0.055,0.056-0.055c0.036,0,0.11-0.018,0.221-0.055c0.035,0,0.138-0.046,0.303-0.138
c0.166-0.091,0.359-0.211,0.58-0.358c0.184-0.11,0.349-0.211,0.496-0.304c0.146-0.091,0.257-0.156,0.331-0.193h0.055
c0,0.037-0.036,0.074-0.11,0.11c-0.146,0.147-0.45,0.386-0.91,0.717c-0.46,0.331-0.91,0.654-1.352,0.965
c-0.44,0.313-0.717,0.488-0.827,0.524h-0.055c0-0.036,0.184-0.221,0.552-0.552c-0.037-0.036-0.093-0.055-0.166-0.055
c-0.147,0-0.386,0.11-0.717,0.331s-0.718,0.533-1.159,0.938c-0.367,0.295-0.717,0.561-1.048,0.8
C181.158,63.485,180.901,63.623,180.717,63.659z M181.048,92.896l0.11-0.11c0,0.035-0.036,0.129-0.11,0.275
c-0.074,0.074-0.129,0.111-0.165,0.111c-0.037,0-0.064-0.02-0.083-0.056c-0.02-0.036-0.009-0.083,0.027-0.138
C180.863,92.923,180.938,92.896,181.048,92.896z M180.883,89.972c0-0.036,0.046-0.091,0.138-0.165
c0.091-0.074,0.184-0.146,0.276-0.221c0.091-0.074,0.184-0.129,0.275-0.166c0.091-0.035,0.156-0.073,0.193-0.109l-0.056,0.109
c-0.036,0.037-0.146,0.13-0.331,0.275c-0.11,0.075-0.212,0.139-0.303,0.193C180.983,89.944,180.919,89.972,180.883,89.972z
 M181.048,90.523c0-0.074,0.092-0.174,0.276-0.303c0.184-0.129,0.413-0.285,0.689-0.47c0.275-0.184,0.552-0.358,0.827-0.524
c0.276-0.165,0.45-0.266,0.524-0.303h0.055c0,0.037-0.036,0.092-0.11,0.166c-0.036,0.036-0.146,0.109-0.331,0.221
c-0.185,0.146-0.423,0.313-0.717,0.496c-0.295,0.185-0.561,0.35-0.8,0.496C181.222,90.449,181.084,90.523,181.048,90.523z
 M181.324,94.219c0.184-0.221,0.294-0.35,0.331-0.385c0.035,0,0.055,0.018,0.055,0.055v0.055c-0.036,0.074-0.102,0.166-0.193,0.275
c-0.092,0.111-0.212,0.221-0.358,0.332L181.324,94.219z M181.6,89.145l-0.221,0.055c-0.036,0-0.047-0.018-0.027-0.055
c0.019-0.036,0.056-0.083,0.11-0.139c0.055-0.055,0.11-0.082,0.165-0.082c0.056,0,0.102,0,0.139,0v0.055L181.6,89.145z
 M181.931,91.627c0.036,0.035,0.063,0.073,0.083,0.11c0.019,0.036,0.027,0.073,0.027,0.11c0,0.035-0.02,0.055-0.055,0.055
c-0.037-0.037-0.064-0.074-0.083-0.11c-0.02-0.036-0.027-0.074-0.027-0.11v-0.109L181.931,91.627z M182.924,94.66l0.055-0.055v0.11
c0,0.036-0.019,0.073-0.055,0.11c-0.037,0.035-0.074,0.055-0.11,0.055h-0.056c0-0.036,0.019-0.082,0.056-0.137
C182.85,94.688,182.887,94.66,182.924,94.66z M184.082,77.229c0.073,0,0.174-0.018,0.304-0.055
c0.129-0.035,0.248-0.082,0.358-0.138s0.201-0.101,0.275-0.138l0.056-0.055h-0.056c-0.036-0.037-0.092-0.056-0.165-0.056
c-0.074,0-0.166,0.019-0.275,0.056c-0.296,0.036-0.599,0.129-0.911,0.275s-0.579,0.275-0.8,0.386h0.11
c0.036,0,0.11-0.009,0.221-0.027s0.257-0.063,0.441-0.138c0.11-0.036,0.201-0.064,0.276-0.083
C183.99,77.238,184.045,77.229,184.082,77.229z M183.199,79.05c-0.11,0.074-0.165,0.13-0.165,0.165
c0.036,0,0.118-0.018,0.248-0.055c0.129-0.036,0.248-0.092,0.358-0.166c0.11-0.073,0.201-0.146,0.276-0.221
c0.035-0.073,0.055-0.129,0.055-0.165l-0.11,0.056c-0.074,0.036-0.146,0.092-0.221,0.165c-0.11,0.074-0.202,0.13-0.275,0.165
C183.291,79.031,183.235,79.05,183.199,79.05z M184.138,87.214c0.294-0.257,0.496-0.423,0.606-0.497
c0.073-0.073,0.11-0.129,0.11-0.165h-0.056c-0.074,0-0.185,0.036-0.33,0.11c-0.147,0.074-0.313,0.166-0.497,0.275
c-0.146,0.074-0.295,0.176-0.441,0.304c-0.146,0.13-0.268,0.24-0.358,0.331c-0.092,0.092-0.138,0.157-0.138,0.193h0.055
c0.036,0,0.129-0.036,0.276-0.111C183.511,87.582,183.77,87.435,184.138,87.214z M183.145,92.233v-0.056
c0.073-0.074,0.129-0.109,0.165-0.109s0.056,0.055,0.056,0.165c0,0.036-0.02,0.073-0.056,0.11
c-0.036,0.036-0.092,0.055-0.165,0.055h-0.056v-0.055C183.089,92.307,183.107,92.27,183.145,92.233z M183.586,87.986
c0.11-0.074,0.184-0.111,0.221-0.111h0.055l-0.165,0.111c-0.221,0.185-0.351,0.275-0.387,0.275l-0.055-0.055
C183.255,88.171,183.365,88.096,183.586,87.986z M183.53,86.441c0-0.035,0.063-0.11,0.193-0.221
c0.129-0.11,0.284-0.229,0.469-0.358c0.184-0.129,0.358-0.239,0.524-0.331c0.165-0.091,0.267-0.138,0.303-0.138h0.056l-0.11,0.11
c-0.074,0.074-0.276,0.221-0.606,0.441c-0.221,0.146-0.406,0.268-0.552,0.358C183.659,86.396,183.566,86.441,183.53,86.441z
 M184.248,91.186c0,0.037-0.02,0.074-0.056,0.11c-0.037,0.036-0.092,0.056-0.165,0.056l-0.11,0.055c0-0.036,0.018-0.074,0.055-0.11
c0.036-0.036,0.073-0.083,0.11-0.138c0.036-0.056,0.073-0.083,0.11-0.083C184.229,91.075,184.248,91.111,184.248,91.186z
 M185.186,90.082c-0.11,0.074-0.185,0.11-0.221,0.11v-0.056c0.036-0.035,0.083-0.082,0.138-0.137
c0.056-0.056,0.11-0.111,0.166-0.166c0.055-0.055,0.101-0.083,0.138-0.083c0.036,0,0.055,0.037,0.055,0.11l-0.11,0.055
C185.314,89.991,185.259,90.047,185.186,90.082z M185.296,62.39c0.073-0.036,0.11-0.073,0.11-0.11c0-0.036-0.037-0.055-0.11-0.055
c-0.037,0-0.064,0.009-0.083,0.027c-0.02,0.02-0.027,0.047-0.027,0.083c0,0.037,0.019,0.055,0.055,0.055H185.296z M185.461,84.952
c0.295-0.185,0.552-0.331,0.772-0.441c0.146-0.073,0.239-0.11,0.276-0.11c0,0.037-0.166,0.185-0.497,0.441
c-0.11,0.074-0.212,0.129-0.304,0.166c-0.092,0.036-0.157,0.074-0.192,0.109c-0.037,0-0.056-0.018-0.056-0.055V84.952z
 M185.737,86.441c0.11-0.074,0.165-0.129,0.165-0.166h-0.11c-0.036,0-0.083,0.01-0.138,0.028s-0.103,0.047-0.138,0.083v0.109
c0.035,0.037,0.073,0.056,0.11,0.056C185.662,86.552,185.7,86.516,185.737,86.441z M185.848,78.223
c-0.11,0.074-0.175,0.129-0.193,0.165c-0.02,0.036-0.009,0.056,0.027,0.056s0.091-0.009,0.166-0.028
c0.073-0.019,0.146-0.046,0.221-0.083c0.073-0.035,0.128-0.055,0.165-0.055v-0.11l-0.055-0.056l-0.056,0.056
C186.049,78.167,185.958,78.186,185.848,78.223z M186.344,76.402v-0.056c0-0.036-0.02-0.056-0.055-0.056
c-0.037,0-0.093,0.02-0.166,0.056c-0.037,0-0.055,0.019-0.055,0.056H186.344z M187.447,85.779l0.165-0.166
c0-0.035-0.019-0.045-0.055-0.027c-0.037,0.02-0.074,0.047-0.11,0.083c-0.074,0.037-0.147,0.075-0.221,0.11
c-0.074,0.037-0.13,0.055-0.166,0.055h-0.055c-0.074,0-0.138,0.02-0.193,0.056c-0.055,0.037-0.119,0.093-0.192,0.165
c-0.075,0.075-0.139,0.158-0.193,0.249c-0.056,0.092-0.103,0.157-0.138,0.192c0.11,0,0.221-0.073,0.331-0.221
c0.073-0.073,0.138-0.129,0.192-0.165c0.056-0.036,0.083-0.073,0.083-0.11l0.055,0.055v0.111c0.036,0,0.063-0.009,0.083-0.027
c0.019-0.02,0.063-0.056,0.138-0.111C187.245,85.973,187.337,85.89,187.447,85.779z M186.84,76.457
c0.074-0.036,0.11-0.074,0.11-0.11s-0.019-0.056-0.055-0.056c-0.037,0-0.092,0.02-0.166,0.056c-0.109,0.036-0.165,0.074-0.165,0.11
H186.84z M186.729,78.002h-0.109v0.109c0.035,0.037,0.073,0.056,0.109,0.056s0.074-0.019,0.11-0.056
c0.036-0.035,0.056-0.073,0.056-0.109l-0.056-0.056L186.729,78.002z M186.675,62.335c0-0.073,0.019-0.128,0.055-0.165
c0.036-0.036,0.074-0.074,0.11-0.11c0.036-0.036,0.074-0.055,0.11-0.055h0.056l-0.056,0.055c0,0.037-0.036,0.11-0.11,0.22
c-0.074,0.11-0.129,0.166-0.165,0.166V62.335z M187.227,89.751c0.073,0,0.118-0.019,0.138-0.056
c0.019-0.035,0.027-0.072,0.027-0.109c0-0.036-0.019-0.074-0.055-0.11c-0.037,0-0.064,0.019-0.083,0.056
c-0.02,0.036-0.037,0.063-0.055,0.082c-0.02,0.02-0.028,0.047-0.028,0.082C187.171,89.732,187.189,89.751,187.227,89.751z
 M187.558,78.885c0,0.036-0.037,0.055-0.11,0.055h-0.11v-0.055l0.055-0.056c0.036,0,0.074,0,0.11,0S187.558,78.848,187.558,78.885z
 M187.723,76.016c0-0.036-0.009-0.055-0.027-0.055c-0.02,0-0.047,0-0.083,0l-0.055-0.055l-0.056,0.055
c-0.036,0-0.055,0.02-0.055,0.055H187.723z M188.109,58.97c-0.221,0.147-0.368,0.24-0.441,0.276h-0.166
c0-0.036,0.036-0.091,0.11-0.166c0.074-0.074,0.185-0.146,0.331-0.221c0.185-0.146,0.35-0.239,0.497-0.276
c0.073-0.036,0.128-0.055,0.165-0.055l-0.055,0.055C188.551,58.621,188.403,58.75,188.109,58.97z M187.889,84.896l0.275-0.275
c0.036-0.036,0.056-0.074,0.056-0.11v0.056l-0.11,0.055l-0.552,0.552L187.889,84.896z M187.889,83.463h-0.056
c0-0.036,0.046-0.102,0.138-0.193c0.092-0.091,0.239-0.211,0.441-0.358c0.202-0.146,0.405-0.284,0.607-0.414
c0.201-0.128,0.377-0.229,0.523-0.304l0.11-0.055v0.055l-0.055,0.056c-0.037,0.036-0.202,0.185-0.497,0.441
c-0.257,0.147-0.44,0.257-0.551,0.331c-0.11,0.037-0.213,0.082-0.304,0.138C188.155,83.215,188.034,83.316,187.889,83.463z
 M188.495,86.496c0.036,0.037,0.056,0.075,0.056,0.111s-0.037,0.064-0.11,0.082c-0.075,0.02-0.147,0.027-0.221,0.027h-0.056v-0.055
c0-0.036,0.019-0.074,0.056-0.11c0.035-0.036,0.073-0.056,0.11-0.056C188.365,86.496,188.421,86.496,188.495,86.496z
 M189.322,84.511c0.036,0,0.092-0.028,0.166-0.083c0.073-0.055,0.165-0.129,0.275-0.221c0.11-0.091,0.201-0.193,0.276-0.303
c0.073-0.111,0.11-0.184,0.11-0.221c0-0.036-0.02-0.056-0.056-0.056h-0.055c-0.075,0.075-0.221,0.185-0.441,0.331
c-0.185,0.146-0.378,0.295-0.579,0.441c-0.202,0.146-0.358,0.275-0.469,0.387c-0.075,0.074-0.11,0.129-0.11,0.165h0.11
c0.035,0,0.082-0.019,0.138-0.056c0.055-0.035,0.118-0.091,0.192-0.165c0.074-0.036,0.146-0.083,0.221-0.138
C189.176,84.538,189.248,84.511,189.322,84.511z M188.495,75.795c0.073,0,0.156-0.008,0.248-0.027
c0.091-0.019,0.174-0.046,0.248-0.083l0.056-0.055c0-0.036-0.027-0.046-0.083-0.027c-0.055,0.019-0.11,0.027-0.165,0.027
c-0.056,0-0.12,0.02-0.193,0.055l-0.11,0.055V75.795z M189.102,58.308c-0.221,0.037-0.386,0.055-0.496,0.055
c0-0.036,0.019-0.063,0.056-0.083c0.035-0.019,0.11-0.055,0.22-0.11c0.11-0.055,0.212-0.091,0.304-0.11
c0.091-0.019,0.174-0.027,0.248-0.027v0.055C189.396,58.125,189.286,58.198,189.102,58.308z M189.212,77.008
c0-0.035,0.019-0.063,0.056-0.082c0.036-0.019,0.073-0.027,0.11-0.027c0.036,0,0.055,0.02,0.055,0.055l-0.055,0.055
c0,0.037-0.02,0.056-0.056,0.056h-0.055C189.23,77.063,189.212,77.045,189.212,77.008z M189.709,57.867
c-0.074,0-0.11-0.019-0.11-0.055c0-0.036,0.019-0.074,0.055-0.11c0.036-0.036,0.074-0.074,0.11-0.11l0.11-0.055
c0.036,0,0.056,0.037,0.056,0.11c0,0.074-0.02,0.129-0.056,0.166C189.838,57.849,189.782,57.867,189.709,57.867z M191.143,76.844
c-0.11-0.037-0.192-0.056-0.248-0.056c-0.055,0-0.092,0.009-0.11,0.027c-0.019,0.02-0.019,0.028,0,0.028s0.046,0,0.083,0
c0,0.036,0.019,0.055,0.056,0.055v0.055c0,0.037-0.037,0.074-0.11,0.11c-0.075,0-0.13,0-0.166,0c-0.037,0-0.064-0.008-0.083-0.027
c-0.019-0.019-0.027-0.046-0.027-0.083c0-0.035-0.009-0.063-0.027-0.082c-0.02-0.02-0.056-0.027-0.11-0.027
c-0.056,0-0.138,0.019-0.248,0.055h-0.276c0-0.036,0.074-0.074,0.221-0.11s0.322-0.083,0.524-0.138
c0.201-0.056,0.377-0.102,0.523-0.139l0.221-0.055c0.074,0,0.139,0.01,0.193,0.027c0.056,0.02,0.11,0.055,0.166,0.11
c0.055,0.056,0.082,0.103,0.082,0.138l0.056,0.056h-0.056c-0.036,0-0.092-0.019-0.165-0.056c-0.037,0-0.074-0.018-0.11-0.055v0.055
c0,0.037-0.02,0.064-0.056,0.083c-0.036,0.02-0.082,0.028-0.138,0.028C191.281,76.844,191.217,76.844,191.143,76.844z
 M190.261,56.212c-0.147,0.11-0.24,0.185-0.276,0.221v0.055h0.056c0.035,0,0.101-0.018,0.192-0.055
c0.092-0.036,0.185-0.073,0.276-0.11c0.091-0.036,0.156-0.073,0.193-0.11v-0.055h-0.056
C190.609,56.157,190.481,56.176,190.261,56.212z M190.371,57.426v0.055h-0.166c-0.074,0-0.11-0.008-0.11-0.028
c0-0.018,0.009-0.027,0.027-0.027s0.047-0.008,0.083-0.028c0.036-0.018,0.073-0.027,0.11-0.027
C190.352,57.371,190.371,57.39,190.371,57.426z M190.813,82.967l0.055-0.056c0-0.036-0.02-0.056-0.055-0.056
c-0.037,0-0.083,0.01-0.139,0.027c-0.055,0.02-0.102,0.064-0.138,0.139c-0.037,0.037-0.083,0.064-0.138,0.082
c-0.056,0.02-0.103,0.047-0.138,0.084c-0.075,0-0.13,0.019-0.166,0.055c0,0.037,0.019,0.074,0.056,0.109
c0.11,0.075,0.201,0.111,0.275,0.111h0.056c0.035-0.036,0.073-0.111,0.11-0.221c0.035-0.074,0.073-0.139,0.11-0.193
C190.737,82.994,190.775,82.967,190.813,82.967z M190.315,84.787c0,0.036-0.02,0.064-0.055,0.082
c-0.037,0.02-0.075,0.027-0.11,0.027c-0.037,0-0.056-0.018-0.056-0.055l0.056-0.11c0.035-0.036,0.073-0.056,0.11-0.056h0.11
C190.371,84.713,190.352,84.75,190.315,84.787z M190.205,75.409h0.056c0.035,0,0.11,0,0.221,0s0.248-0.027,0.413-0.083
c0.166-0.055,0.312-0.101,0.441-0.138c0.129-0.036,0.193-0.073,0.193-0.11c-0.037,0-0.138,0.009-0.304,0.028
c-0.165,0.019-0.34,0.037-0.523,0.055c-0.186,0.019-0.331,0.064-0.441,0.138l-0.11,0.055
C190.15,75.391,190.169,75.409,190.205,75.409z M190.371,78.388c0.035-0.073,0.101-0.118,0.192-0.138
c0.092-0.019,0.174-0.027,0.249-0.027c0.073,0,0.138-0.009,0.192-0.028c0.056-0.019,0.102-0.046,0.138-0.083
c0.221,0,0.331,0.02,0.331,0.056s-0.047,0.074-0.138,0.11c-0.092,0.037-0.212,0.082-0.358,0.138
c-0.147,0.056-0.295,0.093-0.441,0.11c-0.147,0.02-0.24,0.027-0.275,0.027v-0.055C190.261,78.462,190.296,78.424,190.371,78.388z
 M190.646,58.639c0.073-0.036,0.11-0.073,0.11-0.11c0-0.036-0.037-0.055-0.11-0.055c-0.037,0-0.064,0.009-0.083,0.028
c-0.019,0.019-0.027,0.047-0.027,0.083v0.055H190.646z M190.867,81.422c0.036-0.036,0.073-0.074,0.11-0.111
c0.036-0.035,0.091-0.072,0.165-0.109L190.867,81.422z M191.363,56.874c-0.074,0.037-0.146,0.055-0.221,0.055s-0.129,0-0.165,0
l-0.055-0.055c0-0.036,0.027-0.063,0.082-0.083c0.056-0.019,0.129-0.036,0.221-0.055c0.091-0.019,0.174-0.036,0.248-0.055
c0.074-0.019,0.11-0.008,0.11,0.027C191.584,56.745,191.51,56.8,191.363,56.874z M193.239,81.808
c0.184-0.11,0.275-0.201,0.275-0.276h-0.386c-0.221,0.186-0.405,0.323-0.552,0.414c-0.147,0.092-0.258,0.158-0.331,0.193h-0.11
c-0.036,0-0.055-0.035-0.055-0.11c0-0.036,0.019-0.073,0.055-0.11v-0.166l-0.331,0.221c-0.074,0-0.146,0.028-0.221,0.084
c-0.074,0.055-0.146,0.119-0.221,0.192c-0.074,0.075-0.165,0.13-0.275,0.165l-0.165,0.111c0,0.036,0.035,0.055,0.109,0.055
s0.157-0.008,0.249-0.027c0.091-0.019,0.156-0.027,0.192-0.027l0.056-0.056v-0.11c0.073,0,0.184,0.037,0.331,0.11
c0.073,0.036,0.146,0.056,0.221,0.056c0.073,0,0.165-0.037,0.275-0.111c0.11-0.073,0.239-0.165,0.387-0.275
C192.927,81.955,193.092,81.845,193.239,81.808z M191.529,55.495c-0.074,0.074-0.11,0.129-0.11,0.166h0.11
c0.073,0,0.201-0.046,0.386-0.138c0.185-0.091,0.367-0.201,0.552-0.331c0.184-0.128,0.331-0.248,0.441-0.358
c0.036-0.036,0.056-0.074,0.056-0.11h-0.056c-0.11,0.037-0.258,0.11-0.441,0.221c-0.185,0.11-0.367,0.212-0.552,0.303
C191.73,55.339,191.603,55.421,191.529,55.495z M191.971,77.395c-0.147-0.035-0.221-0.073-0.221-0.11
c0-0.036,0.063-0.083,0.193-0.138c0.128-0.055,0.256-0.091,0.386-0.11c0.129-0.019,0.229-0.009,0.304,0.027
c0.035,0,0.055,0.02,0.055,0.056l-0.055,0.109c-0.075,0.111-0.147,0.166-0.221,0.166H191.971z M192.081,78.111l-0.056-0.055
c0-0.035-0.009-0.074-0.027-0.11c-0.02-0.036-0.027-0.091-0.027-0.165l0.055-0.056c0.036,0,0.063,0.02,0.083,0.056
c0.019,0.036,0.036,0.082,0.055,0.138s0.028,0.103,0.028,0.138C192.191,78.094,192.154,78.111,192.081,78.111z M193.404,74.747
c-0.11,0.037-0.239,0.064-0.386,0.083c-0.147,0.02-0.304,0.037-0.469,0.055c-0.166,0.02-0.304,0.028-0.414,0.028
c-0.074,0-0.11-0.019-0.11-0.055c0-0.036,0.063-0.063,0.193-0.083c0.129-0.019,0.284-0.036,0.469-0.055
c0.184-0.019,0.358-0.036,0.524-0.055c0.165-0.019,0.284-0.008,0.358,0.028L193.404,74.747z M192.467,82.801l0.11-0.11v-0.056
l-0.165,0.056c-0.075,0-0.11,0.019-0.11,0.056v0.055H192.467z M192.577,76.457c0,0.037-0.02,0.074-0.055,0.11h-0.056
c-0.037-0.036-0.055-0.073-0.055-0.11c0-0.036,0.008-0.063,0.027-0.083c0.019-0.019,0.036-0.036,0.055-0.056
c0.019-0.018,0.036-0.018,0.056,0C192.568,76.338,192.577,76.383,192.577,76.457z M192.743,55.605l-0.11-0.055
c-0.037,0.037-0.056,0.074-0.056,0.11c0,0.037,0.019,0.064,0.056,0.083c0.035,0.02,0.073,0.028,0.11,0.028h0.055
c0-0.036,0-0.063,0-0.083C192.798,55.669,192.778,55.642,192.743,55.605z M192.633,56.874c0.035,0,0.073-0.019,0.11-0.055
c0.073-0.036,0.174-0.083,0.303-0.138s0.248-0.11,0.358-0.166s0.166-0.101,0.166-0.138c0-0.073-0.074-0.11-0.221-0.11
c-0.037,0-0.092,0.028-0.166,0.083s-0.157,0.12-0.248,0.193c-0.092,0.075-0.165,0.138-0.221,0.193
C192.66,56.792,192.633,56.838,192.633,56.874z M192.908,80.98c0.146-0.074,0.239-0.146,0.275-0.221v-0.055
c0-0.037-0.055-0.056-0.165-0.056c-0.037,0-0.102,0.027-0.193,0.083c-0.092,0.055-0.157,0.119-0.192,0.193v0.109h0.055
C192.724,81.035,192.798,81.018,192.908,80.98z M193.294,77.615c-0.036,0-0.055,0.027-0.055,0.083s0,0.103,0,0.138v0.11h-0.056
c-0.036,0-0.092-0.036-0.165-0.11l-0.055-0.11c0-0.036,0.027-0.073,0.082-0.11c0.056-0.035,0.119-0.073,0.193-0.11l0.11-0.056
v0.056C193.35,77.542,193.33,77.58,193.294,77.615z M194.012,80.926c0.073-0.037,0.11-0.074,0.11-0.111l-0.11,0.056
c-0.11,0-0.23,0.009-0.358,0.027c-0.13,0.02-0.249,0.047-0.359,0.083c-0.11,0.037-0.165,0.074-0.165,0.11
c0.11,0,0.193,0.019,0.248,0.056c0.056,0.036,0.102,0.092,0.138,0.164c0.036,0.037,0.074,0.056,0.11,0.056l0.056-0.11
C193.754,81.221,193.864,81.109,194.012,80.926z M193.294,82.635c-0.036,0.037-0.074,0.056-0.11,0.056s-0.055-0.019-0.055-0.056
c0-0.035,0.036-0.073,0.11-0.109l0.11-0.056c0.036,0,0.063,0.019,0.083,0.056C193.45,82.562,193.404,82.6,193.294,82.635z
 M193.46,55.495c-0.11,0.037-0.185,0.055-0.221,0.055v0.055c0.036,0.037,0.083,0.064,0.138,0.083
c0.056,0.02,0.102,0.028,0.138,0.028h0.166c0-0.036-0.01-0.063-0.027-0.083C193.634,55.614,193.57,55.569,193.46,55.495z
 M194.012,80.152c0.184-0.146,0.294-0.238,0.331-0.275v-0.055c0-0.036-0.02-0.056-0.056-0.056c-0.074,0-0.165,0.028-0.275,0.083
s-0.213,0.129-0.304,0.221c-0.092,0.092-0.138,0.213-0.138,0.358L194.012,80.152z M193.956,79.436l-0.055-0.055
c-0.037,0-0.064,0-0.083,0c-0.02,0-0.047,0.02-0.083,0.055l-0.055,0.11v0.056c0.036,0,0.073,0,0.11,0
c0.036,0,0.073-0.019,0.11-0.056C193.938,79.51,193.956,79.473,193.956,79.436z M194.397,76.898
c-0.146,0.037-0.257,0.055-0.331,0.055c-0.036,0-0.055-0.018-0.055-0.055c0-0.036,0.027-0.063,0.083-0.083
c0.055-0.019,0.118-0.027,0.192-0.027l0.11,0.056V76.898z M194.232,79.16c0.073-0.074,0.11-0.129,0.11-0.166
c-0.037,0-0.11,0.02-0.221,0.056c-0.075,0.036-0.11,0.074-0.11,0.11c0,0.037,0.019,0.055,0.055,0.055h0.056
C194.157,79.215,194.195,79.197,194.232,79.16z M194.397,53.895l-0.165,0.166h0.11c0.035,0,0.073-0.018,0.11-0.055
c0.035-0.036,0.073-0.073,0.11-0.11c0.035-0.036,0.063-0.063,0.082-0.083c0.019-0.019,0.009-0.027-0.027-0.027
C194.581,53.785,194.508,53.822,194.397,53.895z M194.287,54.723l-0.055,0.055h0.055c0.036,0.037,0.074,0.055,0.11,0.055
s0.063-0.008,0.083-0.027c0.019-0.019,0.027-0.046,0.027-0.083H194.287z M194.397,83.463c0-0.036,0.019-0.083,0.056-0.139
c0.035-0.055,0.091-0.082,0.165-0.082l0.056-0.055l0.055,0.055l-0.055,0.055c0,0.037-0.028,0.083-0.083,0.139
c-0.056,0.055-0.103,0.082-0.138,0.082C194.416,83.518,194.397,83.5,194.397,83.463z M195.114,54.226c-0.074,0-0.146,0-0.22,0
c-0.075,0-0.147,0.02-0.221,0.055c-0.11,0.037-0.166,0.075-0.166,0.11h0.056c0.073,0,0.156,0,0.248,0
c0.091,0,0.174-0.008,0.248-0.027c0.073-0.019,0.11-0.046,0.11-0.083L195.114,54.226z M195.114,78.002l-0.109,0.055
c-0.037,0.037-0.083,0.082-0.139,0.138c-0.055,0.056-0.11,0.11-0.165,0.165c-0.056,0.056-0.083,0.12-0.083,0.193L195.114,78.002z
 M195.225,53.454l-0.165,0.055v0.055c0.036,0.037,0.073,0.055,0.11,0.055c0.036,0,0.083-0.008,0.138-0.028
c0.056-0.019,0.083-0.046,0.083-0.083c0-0.036-0.02-0.055-0.056-0.055l-0.055-0.055L195.225,53.454z M195.28,55.55
c0.036,0,0.063-0.019,0.083-0.055c0.019-0.036,0.027-0.074,0.027-0.11c0-0.036-0.02-0.055-0.056-0.055s-0.063,0.009-0.082,0.027
c-0.02,0.02-0.047,0.047-0.083,0.083v0.055C195.17,55.532,195.206,55.55,195.28,55.55z M195.445,74.471
c0.036-0.074,0.074-0.11,0.11-0.11s0.056,0.037,0.056,0.11c0,0.037-0.01,0.064-0.027,0.083c-0.02,0.02-0.047,0.028-0.083,0.028
h-0.056V74.471z M195.942,80.484c0.11-0.074,0.201-0.146,0.275-0.221s0.11-0.146,0.11-0.221v-0.056c0-0.036-0.036-0.056-0.11-0.056
c-0.036,0.037-0.11,0.084-0.221,0.139s-0.212,0.119-0.303,0.193c-0.093,0.074-0.157,0.129-0.193,0.165v0.11h0.11
C195.685,80.539,195.795,80.521,195.942,80.484z M195.942,53.068c-0.11,0.037-0.166,0.074-0.166,0.11v0.055
c0.036,0,0.083,0,0.139,0c0.055,0,0.101-0.008,0.138-0.028c0.036-0.018,0.055-0.046,0.055-0.083c0-0.036-0.019-0.055-0.055-0.055
C196.016,53.068,195.979,53.068,195.942,53.068z M196.494,77.67l0.275-0.275c-0.037,0-0.074,0.02-0.11,0.055
c-0.037,0.037-0.092,0.084-0.165,0.139c-0.075,0.055-0.139,0.11-0.193,0.166c-0.056,0.055-0.103,0.102-0.138,0.137L196.494,77.67z
 M196.604,78.167c0.073-0.036,0.11-0.073,0.11-0.11c0-0.035-0.028-0.055-0.083-0.055c-0.056,0-0.103,0-0.138,0
c-0.037,0-0.075,0.02-0.11,0.055c-0.037,0.037-0.047,0.064-0.028,0.082c0.019,0.02,0.047,0.028,0.083,0.028H196.604z
 M196.99,51.579l-0.165,0.055l0.11-0.11c0.035-0.036,0.073-0.055,0.11-0.055l0.055-0.055l0.056,0.055l-0.056,0.055
C197.101,51.56,197.063,51.579,196.99,51.579z M197.267,77.726c0.184-0.184,0.275-0.294,0.275-0.331
c-0.037,0-0.11,0.037-0.221,0.11c-0.074,0.037-0.13,0.083-0.165,0.138c-0.037,0.056-0.075,0.103-0.11,0.139
c-0.037,0.036-0.056,0.074-0.056,0.109h0.056C197.081,77.891,197.156,77.836,197.267,77.726z M198.811,77.174l0.11-0.055v-0.056
c0-0.036-0.02-0.056-0.055-0.056c-0.037,0-0.083,0.01-0.139,0.028c-0.055,0.02-0.082,0.047-0.082,0.083l-0.056,0.055H198.811z
 M199.087,77.395c0.073,0,0.138-0.027,0.192-0.082c0.056-0.056,0.102-0.102,0.138-0.139l0.056-0.11l-0.056-0.056l-0.055,0.056
c-0.037,0.037-0.083,0.075-0.138,0.11c-0.056,0.037-0.103,0.074-0.138,0.11V77.395z M200.852,75.022
c0.073-0.036,0.11-0.073,0.11-0.11c0-0.036-0.037-0.074-0.11-0.11c-0.037,0-0.064,0.019-0.083,0.055
c-0.019,0.037-0.027,0.074-0.027,0.11c0,0.037,0.009,0.055,0.027,0.055S200.814,75.022,200.852,75.022z M201.349,60.515
c0.073-0.11,0.109-0.184,0.109-0.221h-0.055l-0.165,0.11c-0.075,0.075-0.11,0.13-0.11,0.166c0,0.037,0.018,0.055,0.055,0.055
C201.219,60.625,201.273,60.589,201.349,60.515z M201.734,60.019c0.11-0.074,0.165-0.129,0.165-0.166
c-0.036-0.036-0.074-0.055-0.11-0.055s-0.074,0.019-0.11,0.055c-0.036,0.037-0.063,0.074-0.082,0.11
c-0.02,0.037-0.028,0.074-0.028,0.11c0,0.037,0.019,0.055,0.056,0.055C201.66,60.129,201.697,60.093,201.734,60.019z
 M202.617,59.191c0.11-0.146,0.239-0.303,0.386-0.469s0.276-0.322,0.387-0.469c0.11-0.146,0.184-0.256,0.221-0.331
c-0.037,0-0.083,0.027-0.139,0.083c-0.055,0.055-0.212,0.23-0.469,0.524c-0.516,0.552-0.772,0.864-0.772,0.938L202.617,59.191z
 M203.83,76.788h0.221v0.056c0,0.036-0.036,0.055-0.11,0.055l-0.165-0.055C203.775,76.807,203.794,76.788,203.83,76.788z
 M204.823,56.488c0.11-0.11,0.184-0.201,0.221-0.276l0.056-0.055c-0.037,0-0.075,0.02-0.11,0.055
c-0.037,0.037-0.092,0.083-0.166,0.138s-0.13,0.12-0.165,0.193c-0.037,0.075-0.075,0.13-0.11,0.166L204.823,56.488z
 M204.823,76.953c0.036-0.035,0.074-0.055,0.11-0.055s0.073,0.02,0.11,0.055v0.055h-0.275
C204.769,76.973,204.787,76.953,204.823,76.953z M205.541,76.347h-0.056c0,0.036-0.037,0.056-0.11,0.056
c-0.074,0-0.119-0.009-0.138-0.028c-0.02-0.019-0.01-0.027,0.027-0.027c0.036,0,0.073-0.009,0.11-0.028
c0.036-0.018,0.073-0.027,0.11-0.027C205.521,76.291,205.541,76.311,205.541,76.347z M205.32,77.063
c0-0.036,0.018-0.056,0.055-0.056h0.166l0.055,0.056v0.056h-0.331C205.265,77.083,205.283,77.063,205.32,77.063z" mask="url('#writing')"/>
  <path class="line8" d="M213.926,91.461c-0.552,0-1.085-0.515-1.601-1.545l-0.221-0.221c-0.074-0.366-0.109-0.752-0.109-1.158
c0-1.359,0.551-2.924,1.654-4.688l-0.055,0.056c-2.428,2.096-3.898,3.291-4.413,3.586c-0.296,0.516-0.92,1.02-1.876,1.516
c-0.957,0.497-1.784,0.82-2.482,0.966c-0.184,0.037-0.552,0.056-1.104,0.056c-0.771-0.111-1.213-0.257-1.323-0.441
c-0.185-0.367-0.367-0.662-0.552-0.883c-0.404-0.552-0.606-1.104-0.606-1.654c0-2.354,0.957-4.652,2.868-6.896
c1.911-2.242,4.137-4.137,6.675-5.681c2.537-1.545,4.725-2.317,6.564-2.317c1.028,0,1.856,0.24,2.482,0.717
c0.625,0.479,1.084,0.965,1.379,1.462c0.294,0.497,0.441,0.819,0.441,0.965c0.146,0.441,0.221,0.737,0.221,0.883
s-0.056,0.221-0.166,0.221h-0.055c-0.368-0.111-0.689-0.442-0.966-0.994c-0.275-0.551-0.599-0.918-0.965-1.103
c-0.441-0.074-0.864-0.11-1.27-0.11c-0.919,0-1.895,0.24-2.923,0.717c-1.03,0.478-1.968,1.067-2.813,1.765
c-1.729,1.324-3.559,2.951-5.489,4.882s-3.282,4.036-4.054,6.315c1.839-0.587,3.612-1.544,5.323-2.867
c1.71-1.324,3.393-2.759,5.047-4.303c1.949-1.84,3.88-3.421,5.792-4.745c0.11-0.146,0.201-0.221,0.276-0.221
c0.221,0,0.587,0.13,1.103,0.386c0.516,0.368,0.772,0.772,0.772,1.214c-0.036,0.478-0.165,0.736-0.386,0.771l-1.269,1.766
c-1.141,1.398-1.766,2.096-1.876,2.096s-0.165,0.092-0.165,0.276s-0.11,0.386-0.331,0.606c0,0.074-0.075,0.221-0.221,0.441
c-0.552,0.772-0.855,1.186-0.91,1.241c-0.056,0.056-0.186,0.268-0.387,0.635c-0.202,0.368-0.441,0.81-0.717,1.323
c-0.275,0.516-0.635,1.214-1.076,2.097v0.056c0,0.036,0.019,0.055,0.056,0.055c0.257,0,1.249-0.68,2.979-2.041
c2.022-1.58,3.512-2.85,4.469-3.807c0.073-0.146,0.174-0.238,0.303-0.275c0.129-0.036,0.229-0.063,0.304-0.083
c0.073-0.019,0.129-0.046,0.166-0.083l-0.056-0.109c0-0.074,0.091-0.221,0.275-0.441c0.478-0.332,1.333-1.057,2.565-2.18
c1.232-1.121,1.903-1.718,2.014-1.793c0.036,0,0.055,0.02,0.055,0.056l-0.055,0.11c-0.331,0.331-0.571,0.552-0.717,0.662
c-0.258,0.258-0.424,0.405-0.497,0.441c-0.074,0.037-0.119,0.074-0.138,0.109c-0.02,0.037-0.056,0.103-0.11,0.193
c-0.056,0.092-0.103,0.158-0.138,0.193c-0.037,0.037-0.11,0.074-0.221,0.11c-0.221,0.185-0.571,0.533-1.048,1.048
c-0.957,1.141-1.84,1.932-2.648,2.373v0.055c0,0.185-0.901,1.104-2.703,2.758l-1.213,1.104v0.055c0.035,0,0.146-0.073,0.331-0.221
c0.184-0.146,0.33-0.238,0.44-0.275c0.036,0,0.056,0.02,0.056,0.055c0,0.037-0.037,0.092-0.11,0.166v0.055
c0.073,0,0.772-0.587,2.096-1.765c2.207-1.949,4.101-3.714,5.683-5.296c0.404-0.404,0.625-0.662,0.661-0.771
c0.036-0.111,0.074-0.202,0.11-0.276c0-0.257,0.036-0.433,0.11-0.524c0.074-0.091,0.185-0.138,0.331-0.138
c0.11,0.074,0.166,0.165,0.166,0.276c0,0.33-0.24,0.846-0.718,1.544c-0.257,0.405-0.516,0.698-0.771,0.883l-0.276,0.276
c-1.471,1.323-2.537,2.316-3.199,2.979c-1.14,1.14-1.71,1.765-1.71,1.875l0.165,0.055l-0.496,0.607
c-0.037,0-0.083-0.036-0.138-0.11c-0.056-0.073-0.12-0.146-0.193-0.221c-0.147,0-0.221,0.092-0.221,0.276
c0,0.036,0.036,0.074,0.11,0.109v0.056c0,0.295-0.461,0.772-1.379,1.435c-0.552,0.367-0.938,0.717-1.158,1.048
c-0.406,0.589-0.985,1.103-1.738,1.544C215.185,91.24,214.513,91.461,213.926,91.461z M202.12,86.055
c0.479-1.066,1.049-2.076,1.71-3.033c0.11-0.11,0.166-0.201,0.166-0.275c0.295-0.441,0.606-0.863,0.938-1.27h0.056l0.055-0.055
c0.037-0.036,0.103-0.092,0.193-0.166c0.092-0.073,0.157-0.138,0.193-0.193c0.035-0.055,0.128-0.192,0.275-0.413h-0.11
c1.655-1.876,3.318-3.401,4.992-4.579c1.674-1.176,3.098-2.059,4.275-2.647l1.765-0.827c-0.295,0-0.957,0.24-1.985,0.717
c-2.538,1.178-4.781,2.629-6.73,4.357c-1.029,0.92-1.856,1.766-2.481,2.538c0-0.036-0.019-0.056-0.056-0.056
c-0.073,0.037-0.184,0.147-0.331,0.332c-0.146,0.184-0.221,0.295-0.221,0.33l0.276-0.221c-1.986,2.244-2.979,4.027-2.979,5.352
V86.055z M206.81,79.546l0.275-0.386c-0.036,0-0.102,0.037-0.193,0.109c-0.092,0.075-0.138,0.148-0.138,0.221
C206.754,79.527,206.772,79.546,206.81,79.546z M208.63,85.559l0.221-0.055c0.11-0.036,0.211-0.083,0.303-0.139
c0.092-0.055,0.156-0.082,0.193-0.082l0.056,0.055c0.846-0.367,1.765-1.066,2.758-2.096c0.698-0.625,1.359-1.178,1.985-1.655
c0.441-0.587,1.084-1.231,1.932-1.931c1.066-0.956,1.855-1.783,2.371-2.482L208.63,85.559z M210.395,85.834
c0.036,0,0.092-0.035,0.166-0.109c0.221-0.111,0.377-0.174,0.469-0.193c0.091-0.019,0.193-0.082,0.304-0.193
c0.11-0.109,0.165-0.221,0.165-0.33c0-0.037-0.02-0.056-0.055-0.056c-0.11,0-0.341,0.13-0.689,0.386
c-0.351,0.258-0.524,0.424-0.524,0.496H210.395z M211.664,84.842l0.275-0.331l-0.165,0.11c-0.11,0.11-0.166,0.185-0.166,0.221
H211.664z M212.877,88.703c0.809-1.766,1.746-3.475,2.813-5.131l0.331-0.605h-0.11l0.11-0.221l-0.11,0.055l-0.441,0.662
c-0.516,0.809-1.067,1.719-1.654,2.73c-0.589,1.012-0.902,1.793-0.938,2.344V88.703z M213.098,86.496
c0.074-0.073,0.257-0.35,0.552-0.827c2.207-3.565,3.731-5.958,4.579-7.171c0.146-0.11,0.275-0.239,0.386-0.387l0.331-0.496v-0.055
c0-0.036,0.019-0.102,0.055-0.193s0.092-0.221,0.166-0.387c0.073-0.165,0.146-0.303,0.221-0.413l-1.876,2.427l-1.269,1.711
c-0.516,0.698-1.168,1.783-1.958,3.254c-0.792,1.471-1.187,2.262-1.187,2.372L213.318,86L213.098,86.496z M216.959,81.477
l0.166-0.055c0.366-0.295,0.643-0.615,0.827-0.966c0.184-0.349,0.395-0.681,0.635-0.993c0.238-0.311,0.358-0.504,0.358-0.578
h-0.056l0.056-0.166h-0.056c-0.036,0-0.092,0.027-0.165,0.082c-0.074,0.056-0.157,0.139-0.248,0.249
c-0.092,0.11-0.147,0.212-0.166,0.304c-0.02,0.092-0.047,0.137-0.082,0.137c-0.037,0-0.083,0.01-0.139,0.028
c-0.055,0.019-0.102,0.036-0.138,0.056c-0.037,0.019-0.055,0.047-0.055,0.082l0.055,0.11l-0.055,0.11h0.055
c-0.367,0.552-0.662,0.957-0.883,1.214l-0.11,0.22l0.166-0.055C217.015,81.366,216.959,81.441,216.959,81.477z M217.235,88.813
c0.073,0,0.11-0.073,0.11-0.221h-0.056c-0.074,0.036-0.11,0.064-0.11,0.083c0,0.02,0,0.047,0,0.082
C217.18,88.795,217.198,88.813,217.235,88.813z M219.056,78.608c0.036-0.073,0.118-0.221,0.248-0.441
c0.129-0.221,0.275-0.469,0.441-0.745c0.165-0.275,0.321-0.504,0.469-0.689c-0.074,0-0.202,0.147-0.386,0.441
c-0.368,0.479-0.589,0.745-0.662,0.801c-0.074,0.055-0.11,0.174-0.11,0.357V78.608z M219.11,79.381l0.331-0.331l0.387-0.552
c0-0.036-0.02-0.055-0.056-0.055l-0.11,0.109c-0.074,0.074-0.185,0.221-0.331,0.441S219.11,79.345,219.11,79.381z M219.11,79.602
l0.331-0.387c0.11-0.109,0.193-0.221,0.248-0.33c0.056-0.111,0.102-0.185,0.139-0.221v-0.056c-0.075,0.036-0.213,0.202-0.414,0.497
C219.212,79.4,219.11,79.565,219.11,79.602z M219.883,78.388c0.036,0,0.073-0.019,0.11-0.056c0.036-0.035,0.102-0.118,0.193-0.248
c0.091-0.129,0.193-0.256,0.303-0.386c0.11-0.129,0.185-0.229,0.221-0.304l-0.44,0.221c-0.11,0.146-0.203,0.268-0.276,0.359
c-0.074,0.092-0.11,0.156-0.11,0.192l0.166-0.056L219.883,78.388z M222.586,82.249l0.11-0.056c0.073,0,0.275-0.146,0.606-0.441
c0.146-0.109,0.221-0.201,0.221-0.275h-0.055l-0.11,0.11c-0.11,0.075-0.221,0.157-0.331,0.249s-0.213,0.174-0.304,0.248
C222.632,82.158,222.586,82.213,222.586,82.249z" mask="url('#writing')"/>
  <path class="line9" d="M221.648,92.289c-0.257,0-0.515-0.103-0.772-0.304c-0.257-0.202-0.441-0.433-0.552-0.689
c-0.552-0.772-0.827-1.618-0.827-2.538c0-1.249,0.919-3.107,2.758-5.57c1.288-1.691,2.667-3.393,4.138-5.104
c1.47-1.71,2.702-3.245,3.695-4.606c0.257-0.404,0.515-0.607,0.772-0.607c0.404,0,0.745,0.268,1.021,0.8
c0.275,0.533,0.414,1.021,0.414,1.461c0,0.331-0.075,0.607-0.221,0.828c-0.516,0.625-1.288,1.454-2.317,2.483
s-1.802,1.875-2.316,2.537c-0.295,0.405-0.644,0.847-1.048,1.324c-3.015,3.641-4.726,6.324-5.131,8.053
c0.552-0.587,1.178-1.332,1.876-2.233s1.232-1.683,1.6-2.345c1.618-2.059,3.751-4.311,6.398-6.758
c2.648-2.445,5.296-4.476,7.944-6.095c0.035,0,0.082,0.028,0.138,0.083c0.055,0.055,0.138,0.138,0.248,0.248
s0.248,0.166,0.414,0.166c0.165,0,0.248,0.037,0.248,0.11l-0.11,0.276c0,0.075,0.055,0.13,0.165,0.166
c0.257,0.257,0.387,0.552,0.387,0.883c0,0.626-0.331,1.324-0.993,2.096c0,0.037-0.533,0.717-1.6,2.041l-4.138,5.186
c1.802-1.949,3.944-4.027,6.427-6.233c2.482-2.207,4.845-3.897,7.089-5.075c1.028-0.477,1.69-0.717,1.985-0.717
c0.146,0,0.294,0.074,0.441,0.221c0.698,0.405,1.048,0.975,1.048,1.71c0,0.809-0.313,1.857-0.938,3.144l-2.206,2.869
c-0.993,1.25-1.545,1.875-1.655,1.875h-0.056c-0.074,0-0.119,0.084-0.138,0.249c-0.02,0.165-0.036,0.295-0.055,0.386
c-0.02,0.092-0.064,0.139-0.138,0.139l-0.883,1.323c-0.662,1.03-1.178,1.913-1.545,2.647c-0.588,0.92-0.883,1.51-0.883,1.766
c0,0.074,0.036,0.11,0.11,0.11c0.331,0,1.012-0.404,2.041-1.214c1.471-1.139,3.917-3.273,7.337-6.398
c1.876-1.729,3.034-2.758,3.476-3.09l0.055,0.056c0,0.036-0.009,0.064-0.027,0.083c-0.02,0.02-0.248,0.248-0.689,0.689
c-0.258,0.258-0.441,0.387-0.552,0.387l-0.221,0.33c-0.147,0.221-0.24,0.331-0.275,0.331h-0.056
c-0.257,0.185-0.599,0.517-1.021,0.993c-0.423,0.479-0.864,0.938-1.324,1.379s-0.91,0.791-1.351,1.049v0.055
c0,0.146-0.92,1.067-2.759,2.758c-0.331,0.295-0.599,0.543-0.8,0.744c-0.202,0.203-0.322,0.323-0.358,0.359h-0.056v0.055
c0.036,0,0.146-0.073,0.331-0.221c0.185-0.146,0.331-0.238,0.441-0.275c0.036,0,0.056,0.02,0.056,0.055l-0.11,0.221
c0.036,0,0.221-0.129,0.552-0.386s1.452-1.269,3.364-3.034c0.478-0.404,1.435-1.305,2.869-2.703
c0.772-0.735,1.259-1.213,1.462-1.434c0.201-0.221,0.303-0.404,0.303-0.552c0-0.441,0.146-0.662,0.441-0.662
c0.146,0.11,0.221,0.257,0.221,0.441c0,0.405-0.165,0.882-0.496,1.435c-0.258,0.404-0.533,0.698-0.828,0.883l-0.275,0.275
c-1.471,1.324-2.537,2.316-3.199,2.979c-1.141,1.141-1.71,1.766-1.71,1.875c0,0.037,0.019,0.056,0.055,0.056l0.11-0.056
l-0.496,0.662c-0.037,0-0.075-0.035-0.11-0.109c-0.037-0.074-0.11-0.146-0.221-0.221c-0.074,0-0.13,0.036-0.166,0.109
c-0.036,0.075-0.055,0.13-0.055,0.166c0,0.037,0.019,0.074,0.055,0.11l0.056,0.056c0,0.295-0.461,0.771-1.379,1.434
c-0.699,0.479-1.085,0.828-1.159,1.049c-0.405,0.588-1.012,1.122-1.82,1.6c-0.698,0.404-1.343,0.606-1.931,0.606
c-0.11,0-0.239-0.009-0.386-0.028c-0.147-0.019-0.304-0.137-0.469-0.357c-0.166-0.221-0.313-0.433-0.441-0.635
c-0.13-0.201-0.24-0.367-0.331-0.496c-0.092-0.129-0.175-0.212-0.248-0.249c-0.074-0.366-0.11-0.735-0.11-1.103
c0-2.097,1.783-5.387,5.351-9.875c1.066-1.359,1.894-2.5,2.482-3.42l0.496-0.662c0.11-0.184,0.221-0.331,0.331-0.441
c0.185-0.257,0.276-0.46,0.276-0.607c0-0.074-0.075-0.11-0.221-0.11c-0.331,0.037-1.141,0.441-2.428,1.214
c-1.544,0.919-3.787,2.833-6.729,5.737c-2.392,2.316-4.284,4.339-5.682,6.067c-0.075,0.331-0.24,0.497-0.497,0.497
c-0.295,0-0.661-0.202-1.103-0.607c-0.662-0.552-0.993-1.104-0.993-1.654c0-0.185,0.027-0.332,0.083-0.441
c0.055-0.111,0.763-1.232,2.123-3.365c1.839-2.942,2.794-4.449,2.869-4.523c0.294-0.367,0.441-0.587,0.441-0.662h-0.056
c-0.074,0-0.313,0.147-0.717,0.441c-0.92,0.699-1.489,1.213-1.71,1.545c-1.986,1.434-4.119,3.401-6.399,5.902
c-2.281,2.502-4.008,4.708-5.186,6.619c0,0.221-0.118,0.516-0.358,0.883c-0.239,0.367-0.449,0.643-0.634,0.827
C221.905,92.197,221.795,92.289,221.648,92.289z M221.097,88.428c0.993-1.876,2.115-3.66,3.365-5.352l0.386-0.662l-0.11,0.056
l-0.552,0.718C222.274,85.762,221.243,87.509,221.097,88.428z M221.482,86.055c0.11-0.109,1.489-2.021,4.138-5.736
c0.735-1.029,1.359-1.875,1.875-2.537c0.185-0.111,0.35-0.239,0.497-0.387c0.221-0.221,0.331-0.387,0.331-0.496v-0.055l0.055-0.166
c0.036-0.074,0.201-0.275,0.496-0.607c0.11-0.184,0.202-0.275,0.276-0.275l0.11,0.11c0.036,0,0.055-0.063,0.055-0.193
c0-0.129,0.202-0.359,0.607-0.69c0.331-0.367,0.606-0.698,0.827-0.993l0.056-0.331l-0.11,0.166
c-1.141,1.178-2.299,2.501-3.476,3.972l-1.986,2.372c-0.477,0.589-1.6,2.298-3.364,5.13L221.482,86.055z M225.951,80.926
l0.221-0.056c0.366-0.294,0.661-0.626,0.882-0.993s0.478-0.68,0.772-0.938l0.441-0.662l-0.055-0.055
c-0.037,0-0.103,0.036-0.193,0.109c-0.092,0.074-0.193,0.166-0.304,0.276s-0.175,0.212-0.192,0.304
c-0.02,0.092-0.047,0.138-0.083,0.138c-0.037,0-0.083,0.009-0.138,0.027c-0.056,0.02-0.11,0.036-0.166,0.056
c-0.055,0.02-0.083,0.047-0.083,0.082c0.036,0.037,0.056,0.092,0.056,0.166s-0.11,0.258-0.331,0.551
c-0.221,0.296-0.441,0.553-0.662,0.773C226.006,80.814,225.951,80.889,225.951,80.926z M228.434,77.946l1.103-1.158
c0.552-0.588,0.828-0.974,0.828-1.159v-0.055c-0.075,0-0.387,0.313-0.938,0.938l-0.882,0.993c-0.11,0.11-0.166,0.24-0.166,0.386
C228.378,77.928,228.396,77.946,228.434,77.946z M228.378,78.994l0.387-0.33l0.44-0.607v-0.055l-0.055,0.055
c-0.185,0.146-0.358,0.331-0.524,0.552C228.461,78.829,228.378,78.959,228.378,78.994z M229.315,77.781
c0.11,0,0.367-0.276,0.772-0.828l0.441-0.551c-0.037,0-0.11,0.055-0.221,0.165s-0.24,0.239-0.386,0.386
c-0.147,0.147-0.276,0.304-0.387,0.469c-0.11,0.166-0.175,0.258-0.192,0.276C229.324,77.717,229.315,77.745,229.315,77.781z
 M235.438,80.76l0.662-0.772c1.949-2.206,3.218-4.027,3.807-5.461c-1.581,2.317-2.961,4.212-4.138,5.682
C235.549,80.539,235.438,80.724,235.438,80.76z M241.176,88.648c0.846-1.729,1.784-3.384,2.813-4.965l0.331-0.607l-0.11,0.056
l-0.441,0.661C242.149,86.221,241.286,87.84,241.176,88.648z M241.507,86.441c0.073-0.035,0.625-0.863,1.655-2.482
c1.654-2.573,2.794-4.283,3.42-5.13c0.146-0.11,0.294-0.249,0.441-0.414c0.146-0.165,0.221-0.304,0.221-0.413l0.055-0.166
c0.294-0.552,0.515-0.846,0.662-0.883c0.036,0,0.055,0.02,0.055,0.055c0,0.037,0.019,0.056,0.056,0.056v-0.11
c0-0.146,0.165-0.386,0.496-0.717c0.516-0.587,0.772-0.993,0.772-1.214l-0.11,0.166c-0.957,1.068-1.931,2.298-2.924,3.697
l-1.654,2.15c-0.185,0.258-0.451,0.682-0.8,1.27c-0.351,0.588-0.718,1.205-1.104,1.848c-0.386,0.644-0.689,1.178-0.91,1.6
C241.617,86.175,241.507,86.406,241.507,86.441z M245.258,88.813l0.11-0.056c0.073-0.035,0.11-0.073,0.11-0.109h-0.055
c0-0.036-0.01-0.056-0.028-0.056s-0.082,0.036-0.192,0.11C245.203,88.777,245.221,88.813,245.258,88.813z M245.258,81.697
l0.166-0.055c0.331-0.257,0.606-0.588,0.827-0.993c0.146-0.257,0.339-0.524,0.579-0.8c0.239-0.275,0.358-0.487,0.358-0.635
c-0.037,0-0.119,0.055-0.248,0.166c-0.13,0.109-0.268,0.295-0.414,0.551c0,0.037-0.055,0.056-0.165,0.056s-0.166,0.056-0.166,0.165
l0.056,0.056c0,0.11-0.202,0.441-0.606,0.993L245.258,81.697z M247.299,79.05c0.036,0,0.239-0.239,0.606-0.718l0.11-0.221
c-0.074,0-0.129,0.056-0.165,0.166C247.445,78.756,247.262,79.014,247.299,79.05z M248.071,77.449l0.11-0.055
c0.073-0.035,0.156-0.129,0.248-0.275c0.091-0.146,0.193-0.284,0.304-0.414c0.11-0.129,0.201-0.248,0.275-0.358l0.056-0.165
l-0.441,0.496C248.328,77.045,248.145,77.304,248.071,77.449z M250.719,82.249l0.11-0.056c0.074,0,0.166-0.045,0.276-0.137
s0.239-0.193,0.386-0.305c0.146-0.109,0.221-0.201,0.221-0.275h-0.055c-0.037,0-0.203,0.11-0.497,0.331L250.719,82.249z" mask="url('#writing')"/>
  <path class="line10" d="M253.422,91.461c-0.515,0-0.993-0.129-1.434-0.386l-0.441-0.662c-1.029-1.36-1.545-2.904-1.545-4.634
c0-1.25,0.433-2.528,1.296-3.834c0.864-1.305,2.144-2.73,3.834-4.275c1.618-1.617,3.494-3.144,5.627-4.578
c1.729-1.177,3.31-1.765,4.743-1.765c0.294,0,0.57,0.02,0.827,0.055c0.846,0.662,1.269,1.471,1.269,2.427
c0,0.295-0.11,0.875-0.331,1.738c-0.221,0.864-1,1.857-2.342,2.979c-1.345,1.123-2.861,1.683-4.552,1.683
c-0.81,0-1.692-0.276-2.648-0.827l-0.441,0.386c-0.883,0.883-1.747,1.784-2.593,2.703c-1.103,1.214-1.875,2.206-2.316,2.979
c-0.146,0.295-0.275,0.726-0.386,1.295c-0.11,0.572-0.166,1.003-0.166,1.297c0,0.479,0.258,0.717,0.772,0.717
c0.957,0,1.949-0.221,2.979-0.662l3.641-1.654c0.185-0.11,0.295-0.221,0.331-0.331s0.166-0.257,0.387-0.441
c1.618-0.956,3.861-2.573,6.729-4.854c-0.184,0.295-0.644,0.745-1.379,1.352c-0.735,0.607-1.535,1.223-2.398,1.848
c-0.864,0.627-1.536,1.104-2.014,1.436c-0.221,0.074-0.405,0.174-0.552,0.303c-0.147,0.129-0.221,0.213-0.221,0.248
c0,0.037,0.019,0.055,0.056,0.055l0.551-0.221c0.074,0,0.11,0.02,0.11,0.056c0,0.075-0.047,0.147-0.138,0.221
c-0.092,0.075-0.138,0.13-0.138,0.165l0.827-0.386c1.14-0.661,2.097-1.379,2.869-2.151c0.477-0.441,1.01-0.883,1.599-1.324
c0.846-0.662,1.581-1.323,2.206-1.985c0.479-0.515,0.718-0.846,0.718-0.993l-0.056-0.055l-0.165,0.055
c0-0.073,0.092-0.193,0.275-0.358s0.379-0.312,0.581-0.441c0.202-0.128,0.32-0.192,0.356-0.192h0.056l-0.056,0.109
c0,0.074,0.037,0.158,0.11,0.248c0.074,0.092,0.11,0.176,0.11,0.249c0,0.405-0.827,1.343-2.482,2.813
c-0.184,0.146-0.275,0.24-0.275,0.275c-1.618,1.324-3.116,2.475-4.495,3.447c-1.379,0.975-2.547,1.537-3.503,1.684
c-0.036,0.074-0.221,0.201-0.552,0.385c-0.698,0.441-1.434,0.81-2.206,1.104c-0.295,0.074-0.441,0.147-0.441,0.221
c0,0.074,0.091,0.111,0.275,0.111c0.367,0,0.708-0.102,1.021-0.305c0.312-0.201,0.597-0.303,0.854-0.303l0.221,0.055
c0.073,0,0.193-0.035,0.358-0.109c0.166-0.074,0.358-0.146,0.579-0.221s0.469-0.174,0.745-0.304
c0.275-0.129,0.652-0.358,1.131-0.689c0.662-0.515,1.231-0.919,1.71-1.214c0.478-0.294,0.735-0.441,0.772-0.441l0.055,0.055
c0,0.037-0.027,0.075-0.082,0.111c-0.056,0.037-0.268,0.202-0.635,0.496c-0.147,0.146-0.496,0.387-1.048,0.717l-0.772,0.497
c-0.479,0.295-0.754,0.533-0.827,0.717c-0.331,0.221-0.497,0.368-0.497,0.441c-0.037,0.11-0.275,0.331-0.717,0.662l-0.552,0.331
h0.165c0.11,0,0.166,0.02,0.166,0.055c0,0.074-0.276,0.221-0.827,0.441c-0.552,0.221-0.92,0.367-1.104,0.441
c-0.626,0.331-1.526,0.607-2.703,0.828C254.011,91.424,253.717,91.461,253.422,91.461z M250.829,85.725
c1.545-3.163,3.448-5.737,5.71-7.723c2.262-1.986,4.293-3.439,6.095-4.358l2.702-1.379c-0.294,0-0.956,0.24-1.984,0.717
c-2.574,1.178-4.818,2.629-6.73,4.358c-1.029,0.919-1.856,1.766-2.481,2.537v-0.055c-0.036,0-0.063,0.009-0.083,0.027
c-0.019,0.02-0.11,0.119-0.276,0.303c-0.165,0.186-0.248,0.295-0.248,0.332l0.276-0.276c-0.883,1.067-1.601,2.061-2.151,2.979
c-0.552,0.918-0.828,1.729-0.828,2.426V85.725z M254.249,79.656l0.056-0.055c0.036-0.036,0.102-0.102,0.193-0.193
c0.091-0.092,0.174-0.193,0.248-0.303L254.249,79.656z M259.766,78.885c0.588,0,1.297-0.212,2.124-0.635
c0.827-0.422,1.607-1.049,2.345-1.876c0.734-0.827,1.157-1.498,1.268-2.013c-0.515,0.147-1.029,0.35-1.544,0.606
c-1.544,0.737-3.329,1.968-5.351,3.697C258.975,78.811,259.36,78.885,259.766,78.885z M264.179,85.229h-0.055
c-0.037,0-0.056-0.02-0.056-0.056s0.036-0.091,0.11-0.165c0.073-0.074,0.11-0.166,0.11-0.276l-0.055-0.11
c0.073,0,0.128,0.092,0.165,0.275l0.551-0.441c0.147-0.109,0.313-0.238,0.496-0.386c0.185-0.146,0.295-0.221,0.331-0.221h0.056
c-0.074,0.075-0.184,0.165-0.331,0.276c-0.147,0.109-0.221,0.185-0.221,0.221h0.055l0.497-0.497l0.662-0.331h0.055
c0,0.037-0.081,0.13-0.246,0.275c-0.166,0.148-0.397,0.323-0.691,0.525c-0.294,0.201-0.57,0.395-0.827,0.578
C264.528,85.082,264.325,85.191,264.179,85.229z" mask="url('#writing')"/>
  <path class="line11" d="M266.274,92.289c-0.405,0-0.874-0.13-1.407-0.387c-0.532-0.258-0.965-0.68-1.296-1.269
c-0.146-0.294-0.221-0.587-0.221-0.883c0-0.331,0.092-0.681,0.275-1.048c0.147-0.184,0.469-0.496,0.966-0.938
c0.496-0.441,1.058-0.919,1.683-1.435c0.625-0.515,1.204-0.993,1.737-1.435s0.929-0.808,1.187-1.104
c0.257-0.293,0.386-0.477,0.386-0.551l-0.056-0.11c-0.184-0.036-0.367-0.174-0.551-0.414c-0.185-0.239-0.331-0.496-0.441-0.772
c-0.11-0.275-0.166-0.486-0.166-0.635c-0.331-0.477-0.496-0.955-0.496-1.434c0-1.139,1.158-2.573,3.475-4.303
c0.405-0.294,0.736-0.57,0.993-0.827l1.104-0.772c0.846-0.662,1.802-1.324,2.868-1.986c1.251-0.735,2.372-1.104,3.365-1.104
c0.846,0,1.526,0.441,2.041,1.324c0.184,0.221,0.275,0.423,0.275,0.606c0,0.295-0.184,0.589-0.551,0.883
c0,0.037-0.037,0.083-0.11,0.138c-0.074,0.055-0.129,0.12-0.166,0.193c-0.036,0.074-0.064,0.157-0.083,0.248
c-0.018,0.092-0.174,0.212-0.469,0.359c-0.515,0.368-1.011,0.882-1.489,1.545c-0.478,0.662-0.956,1.178-1.435,1.544v0.165
c0.074,0,0.239-0.09,0.497-0.275c0.478-0.294,0.79-0.441,0.938-0.441c-0.147,0.147-0.588,0.441-1.324,0.883
c-0.367,0.185-0.551,0.313-0.551,0.387v-0.056l0.496-0.165l0.331-0.221c0.073,0,0.11,0.02,0.11,0.055
c-0.037,0.074-0.175,0.185-0.414,0.331c-0.239,0.147-0.506,0.221-0.8,0.221v-0.056l-0.055,0.111v0.055
c0,0.037-0.046,0.092-0.139,0.166c-0.092,0.074-0.238,0.174-0.44,0.303c-0.203,0.129-0.414,0.248-0.635,0.358
c0.037,0,0.083-0.027,0.138-0.083c0.056-0.055,0.102-0.091,0.138-0.109c0.037-0.02,0.185-0.156,0.441-0.414l-0.221,0.11
c-0.367,0.185-0.671,0.368-0.91,0.552c-0.238,0.185-0.358,0.295-0.358,0.33c0,0.037-0.037,0.111-0.11,0.221
c-0.036,0-0.055-0.035-0.055-0.109l-0.166,0.055c-0.367,0.186-0.662,0.35-0.883,0.496l-0.386,0.111v-0.056l3.145-2.097l0.606-0.496
l-0.165,0.11c-0.11,0.074-0.239,0.11-0.387,0.11c-0.331,0-0.533-0.092-0.606-0.275c0-0.185,0.101-0.35,0.304-0.497
c0.202-0.146,0.45-0.404,0.744-0.772c0.92-1.324,1.526-2.151,1.82-2.482s0.46-0.532,0.497-0.607v-0.055
c0-0.036-0.019-0.055-0.056-0.055h-0.055c-0.956,0.406-2.032,1.03-3.228,1.875c-1.195,0.847-2.05,1.544-2.565,2.096
c-0.294,0.331-0.661,0.698-1.103,1.104c-0.772,0.589-1.416,1.306-1.931,2.151c-0.11,0.146-0.166,0.258-0.166,0.331
c0,0.11,0.083,0.202,0.248,0.275c0.166,0.075,0.524,0.11,1.076,0.11c0.331,0,0.652-0.035,0.965-0.11
c0.313-0.073,0.506-0.201,0.58-0.386l0.275-0.166v0.056c0,0.074-0.037,0.175-0.11,0.304s-0.11,0.229-0.11,0.303
c0,0.11,0.056,0.166,0.166,0.166c0.294,0,1.452-0.643,3.475-1.932c1.141-0.771,2.133-1.379,2.979-1.82
c1.766-0.955,2.648-1.525,2.648-1.709c0-0.037-0.056-0.056-0.166-0.056c-0.184,0-0.515,0.056-0.992,0.165l1.323-0.606
c0.479-0.184,0.864-0.276,1.159-0.276c0.11,0,0.202,0.037,0.275,0.11v0.055c0,0.257-0.313,0.516-0.938,0.772
c-0.368,0.147-0.689,0.323-0.966,0.524c-0.275,0.201-0.708,0.469-1.296,0.799c-1.324,0.736-2.464,1.436-3.42,2.097l-1.711,1.104
c0,0.036-0.073,0.092-0.22,0.165c-0.147,0.074-0.322,0.186-0.524,0.331c-0.202,0.147-0.304,0.296-0.304,0.441
c0,0.11,0.037,0.221,0.11,0.331c0.074,0.11,0.11,0.186,0.11,0.221c0,0.074-0.036,0.11-0.11,0.11c-0.073,0-0.11,0.019-0.11,0.056
v0.109c0,0.037,0.056,0.103,0.166,0.193c0.11,0.092,0.229,0.157,0.358,0.193c0.129,0.037,0.229,0.064,0.304,0.082
c0.073,0.02,0.11,0.047,0.11,0.084h0.055l-0.055,0.109c-0.037,0-0.056,0.02-0.056,0.055v0.056c0,0.037,0.046,0.093,0.138,0.165
c0.092,0.075,0.166,0.176,0.221,0.305c0.056,0.129,0.11,0.257,0.166,0.386c0.055,0.13,0.082,0.229,0.082,0.304
s-0.018,0.109-0.055,0.109h-0.11l-0.166,0.387c-0.367,1.14-0.864,1.895-1.489,2.262c-0.515,0.551-1.177,1.15-1.985,1.793
c-0.81,0.643-1.508,1.131-2.097,1.461l-2.427,1.27C267.249,92.142,266.752,92.289,266.274,92.289z M265.777,90.027
c0.185,0,0.579-0.129,1.187-0.387c0.606-0.256,1.075-0.422,1.406-0.496l0.056,0.055l-0.056-0.109c0-0.074,0.019-0.119,0.056-0.139
c0.036-0.018,0.073-0.027,0.11-0.027l0.11,0.055l0.992-0.883c0.993-0.771,1.867-1.553,2.621-2.344
c0.753-0.791,1.186-1.314,1.296-1.572c-0.073-0.074-0.322-0.146-0.745-0.221c-0.423-0.073-0.689-0.11-0.8-0.11
c-0.073,0-0.165,0.056-0.275,0.165v0.111h0.11l0.055-0.056c0,0.036-0.036,0.11-0.11,0.221c-0.073,0.11-0.138,0.192-0.192,0.248
c-0.056,0.056-0.102,0.083-0.139,0.083c-0.036,0-0.055-0.055-0.055-0.166c0-0.109-0.037-0.165-0.11-0.165
c-0.147,0.075-0.221,0.185-0.221,0.331c-0.11,0.221-0.202,0.367-0.275,0.441c-0.074,0.074-0.129,0.11-0.166,0.11
s-0.055-0.036-0.055-0.11s-0.019-0.11-0.056-0.11h-0.055c-0.037,0.036-0.056,0.11-0.056,0.221c0.037,0,0.056,0.019,0.056,0.056
h-0.166c0-0.037-0.019-0.056-0.055-0.056h-0.11c-0.074,0.036-0.11,0.13-0.11,0.276c0,0.036,0.036,0.055,0.11,0.055
c0,0.146-0.92,1.048-2.759,2.703c-0.367,0.367-0.606,0.625-0.717,0.771h-0.11c-0.146,0-0.331,0.111-0.552,0.332l-0.331,0.385
l0.056,0.221l-0.056,0.111H265.777z M266.66,90.578c0.147,0,0.377-0.073,0.689-0.221c0.313-0.146,0.625-0.311,0.938-0.496
c0.313-0.184,0.524-0.33,0.635-0.441l0.166-0.109c-0.037,0-0.083,0.008-0.139,0.027c-0.055,0.02-0.202,0.082-0.441,0.193
c-0.238,0.109-0.496,0.248-0.771,0.413c-0.276,0.165-0.516,0.304-0.718,0.413C266.816,90.469,266.697,90.543,266.66,90.578z
 M267.267,86.387l0.331-0.056c0.81-0.294,1.968-1.122,3.476-2.482c-0.147-0.073-0.275-0.11-0.386-0.11
c-0.221,0-0.607,0.258-1.159,0.772c-0.846,0.698-1.269,1.067-1.269,1.103c0,0.037,0.019,0.056,0.056,0.056l0.055-0.056h0.11
l-0.165,0.056c-0.074,0.075-0.166,0.12-0.276,0.138c-0.11,0.02-0.221,0.083-0.331,0.193
C267.414,86.186,267.267,86.313,267.267,86.387z M268.867,80.318c0.073-0.588,0.744-1.471,2.013-2.648
c1.27-1.176,2.73-2.352,4.386-3.53c1.655-1.177,3.089-2.06,4.303-2.648c-0.625,0-1.839,0.571-3.641,1.71
c-1.802,1.14-3.43,2.392-4.882,3.751c-1.453,1.361-2.179,2.465-2.179,3.311V80.318z M269.584,88.979
c0.037,0,0.092-0.018,0.165-0.055c0.074-0.036,0.11-0.092,0.11-0.166h-0.055c-0.147,0-0.221,0.02-0.221,0.056s0,0.074,0,0.11
V88.979z" mask="url('#writing')"/>
</svg>

  </div>
</template>

<script>
export default {
  name: "SignUp"
}
</script>

<style scoped>
@-webkit-keyframes drawIn {
  0% {
    stroke: transparent;
    stroke-dashoffset: 10000;
  }
  75% {
    stroke: white;
  }
}
@keyframes drawIn {
  0% {
    stroke: transparent;
    stroke-dashoffset: 10000;
  }
  75% {
    stroke: white;
  }
}

svg {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 50vw;
  margin-bottom: 5vh;
}
.mask-path {
  stroke: white;
  stroke-dasharray: 10000;
  -webkit-animation: drawIn 2.5s 1s ease-in both;
  animation: drawIn 2.5s 1s ease-in both;
}
</style>